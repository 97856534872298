/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { configCompanyProps, formPropsPage } from '../../../types';
import { useFetchPartners } from '../../../hooks';
import { ApiCompany } from '../../../api';
import { Button, Input, SelectComponent } from '../../../../../../components';
import { dictionaryError } from '../../../../../../helpers/utils.helper';
import { useFetchPlanUsersActive } from '../../../../planLimitUsers/hooks';
import ModalLimitUsers from '../../../../../users/components/register/modal/limitUsers';
import { UserProps } from '../../../../../users/types';
import maskHelper from '../../../../../../helpers/mask.helper';

interface generalProps extends formPropsPage {
  UsersList: UserProps[];
  setIsLoadingUser: (e: boolean) => void;
  setKey: (e: string) => void;
  isLoadingUser: boolean;
  franchisorCompanies: { id: number; nome: string }[];
}

const Configuration: React.FC<generalProps> = ({
  nextPage,
  notify,
  defaultValue,
  UsersList,
  setIsLoadingUser,
  isLoadingUser,
  setKey,
  franchisorCompanies,
}) => {
  const [configValue, setConfigData] = useState<configCompanyProps>({
    partner_id: defaultValue?.partner_id,
    token_vm: defaultValue?.token_vm ?? '',
    is_vm: defaultValue?.is_vm ?? false,
    is_wireframe: defaultValue?.is_wireframe ?? false,
    is_split_franchisor: defaultValue?.is_split_franchisor ?? false,
    companie_id_split_franchisor:
      defaultValue?.companie_id_split_franchisor ?? null,
    split_franchisor_type: defaultValue?.split_franchisor_type ?? null,
    split_franchisor_value: defaultValue?.split_franchisor_value ?? '0',
    is_generate_contract_opp: defaultValue?.is_generate_contract_opp ?? false,
    autentique_companie_id: defaultValue?.autentique_companie_id,
    // autentique_companie_token: defaultValue?.autentique_companie_token,
    companie_limit_user_id: defaultValue?.companie_limit_user_id ?? null,
    companie_limit_user_qty: defaultValue?.companie_limit_user_qty ?? null,
    split_boleto_type: defaultValue?.split_boleto_type,
    split_boleto_value: defaultValue
      ? defaultValue.split_boleto_value
      : undefined,
    split_pix_type: defaultValue?.split_pix_type,
    split_pix_value: defaultValue?.split_pix_value
      ? defaultValue.split_pix_value
      : undefined,
    split_creditcard_type: defaultValue?.split_creditcard_type,
    split_creditcard_value: defaultValue?.split_creditcard_value
      ? defaultValue.split_creditcard_value
      : undefined,
    id_vm: defaultValue ? defaultValue.id_vm : undefined,
  });

  const [autentiqueCompanies, setAutentiqueCompanies] = useState([] as any[]);

  const { PlanUsersActiveList } = useFetchPlanUsersActive('');
  const optionsUsersActive = PlanUsersActiveList.map((item) => ({
    label: item.name,
    value: item.id,
    qty: item.qty,
  }));
  const { PartnersList } = useFetchPartners();
  const partners = PartnersList.filter((item) => item.is_active === true).map(
    (item) => ({ value: item.id, label: item.name })
  );
  const [modalLimitUsers, setModalLimitUsers] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, setValue, formState, setError, clearErrors } =
    useForm<configCompanyProps>({
      defaultValues: {
        partner_id: defaultValue?.partner_id,
        token_vm: defaultValue?.token_vm ?? '',
        is_vm: defaultValue?.is_vm ?? false,
        is_wireframe: defaultValue?.is_wireframe ?? false,
        is_split_franchisor: defaultValue?.is_split_franchisor ?? false,
        companie_id_split_franchisor:
          defaultValue?.companie_id_split_franchisor ?? null,
        split_franchisor_type: defaultValue?.split_franchisor_type ?? null,
        split_franchisor_value: defaultValue?.split_franchisor_value ?? '0',
        is_generate_contract_opp:
          defaultValue?.is_generate_contract_opp ?? false,
        autentique_companie_id: defaultValue?.autentique_companie_id,
        // autentique_companie_token: defaultValue?.autentique_companie_token,
        companie_limit_user_id: defaultValue?.companie_limit_user_id ?? null,
        companie_limit_user_qty: defaultValue?.companie_limit_user_qty ?? null,
        split_boleto_type: defaultValue?.split_boleto_type ?? '',
        split_boleto_value: defaultValue ? defaultValue.split_boleto_value : '',
        split_pix_type: defaultValue?.split_pix_type ?? '',
        split_pix_value: defaultValue ? defaultValue.split_pix_value : '',
        split_creditcard_type: defaultValue?.split_creditcard_type ?? '',
        split_creditcard_value: defaultValue
          ? defaultValue.split_creditcard_value
          : '',
        id_vm: defaultValue ? defaultValue.id_vm : undefined,
      },
    });

  const Submit = async (values: configCompanyProps) => {
    const splitCreditCardValues = values.split_creditcard_value;

    const splitBoletoValues = values.split_boleto_value;

    const splitPixValues = values.split_pix_value;

    let payload: any = {
      companie_limit_user_id: values.companie_limit_user_id,
      companie_limit_user_qty: values.companie_limit_user_qty,
      is_vm: values.is_vm,
      token_vm: values.token_vm,
      autentique_companie_id: values.autentique_companie_id,
      is_wireframe: values.is_wireframe,
      is_generate_contract_opp: values.is_generate_contract_opp,
      partner_id: values.partner_id,
      is_split_franchisor: values.is_split_franchisor,
      companie_id_split_franchisor: values.companie_id_split_franchisor,
      split_franchisor_type: values.split_franchisor_type,
      split_franchisor_value: values.split_franchisor_value,
      id_vm: values.id_vm,
    };

    if (configValue.split_creditcard_type) {
      payload = {
        ...payload,
        split_creditcard_type: configValue.split_creditcard_type,
        split_creditcard_value: splitCreditCardValues,
      };
    }

    if (!configValue.is_vm) {
      payload = {
        ...payload,
        id_vm: null,
      };
    }

    if (configValue.split_boleto_type) {
      payload = {
        ...payload,
        split_boleto_type: configValue.split_boleto_type,
        split_boleto_value: splitBoletoValues,
      };
    }

    if (configValue.split_pix_type) {
      payload = {
        ...payload,
        split_pix_type: configValue.split_pix_type,
        split_pix_value: splitPixValues,
      };
    }

    if (
      payload.split_creditcard_type === 'percent' &&
      Number(payload.split_creditcard_value) / 100 > 100
    ) {
      setError('split_creditcard_value', {
        message: 'Valor deve ser menor ou igual a 100',
      });
      return;
    }

    if (
      payload.split_boleto_type === 'percent' &&
      Number(payload.split_boleto_value) / 100 > 100
    ) {
      setError('split_boleto_value', {
        message: 'Valor deve ser menor ou igual a 100',
      });
      return;
    }

    if (
      payload.split_pix_type === 'percent' &&
      Number(payload.split_pix_value) / 100 > 100
    ) {
      setError('split_pix_value', {
        message: 'Valor deve ser menor ou igual a 100',
      });
      return;
    }

    if (!values.partner_id) {
      setError('partner_id', {
        message: 'Campo obrigatório',
      });
      return;
    }

    if (
      configValue.companie_limit_user_qty &&
      configValue.companie_limit_user_qty <
        UsersList.filter((item) => item.is_active).length
    ) {
      setModalLimitUsers(true);
      return;
    }
    setIsLoading(true);

    const response = await ApiCompany.updateConfig(defaultValue!.id, payload);
    // console.log('RESPOSTA: ', response);

    if (response.id) {
      notify({
        message: 'Configurações atualizados com sucesso',
        type: 'Success',
      });
      nextPage(response);
      setKey(String(Math.random()));
    } else {
      notify({ message: dictionaryError(response), type: 'Error' });
      setIsLoading(false);
    }
  };

  const optionsYesNo = [
    {
      label: 'Sim',
      value: true,
    },
    { label: 'Não', value: false },
  ];

  const franchisorOptions = franchisorCompanies.map((company) => ({
    label: company.nome,
    value: company.id,
  }));

  const optionsTypeSplit = [
    { label: 'Fixo', value: 'fixed' },
    { label: 'Percentual (%)', value: 'percent' },
  ];

  useEffect(() => {
    ApiCompany.getAutentiqueCompanies().then(
      (companies) =>
        typeof companies !== 'string' &&
        setAutentiqueCompanies(
          companies.map((company: any) => ({
            label: company.name,
            value: company.id,
            token: company.tokens?.[0].token,
          }))
        )
    );
  }, []);
  return (
    <form
      className="flex flex-col sm:grid sm:grid-cols-3 lg:grid-cols-4 gap-4 mt-8 text-sm"
      onSubmit={handleSubmit(Submit)}
    >
      <ModalLimitUsers
        currentPlanLimit={configValue.companie_limit_user_qty ?? 0}
        ModalChangeState={modalLimitUsers}
        setIsLoading={setIsLoadingUser}
        setModalChangeState={setModalLimitUsers}
        userList={UsersList}
        isLoading={isLoadingUser}
        reload={setKey}
      />

      <SelectComponent
        value={optionsYesNo.find((item) => item.value === configValue.is_vm)}
        title="Integração com VM"
        closeMenuOnSelect
        isMulti={false}
        options={optionsYesNo}
        onChange={(e: any) => {
          const isVm = e.value;
          const tokenVm = isVm ? 'f2abb662-435e-43c0-9fd5-5305f4d34754' : '';
          setConfigData({
            ...configValue,
            is_vm: isVm,
            token_vm: tokenVm,
          });
          setValue('is_vm', isVm);
          setValue('token_vm', tokenVm);
        }}
      />
      <Input
        title="ID na VM"
        variant="outline-orange"
        disabled={!configValue.is_vm}
        value={configValue.id_vm}
        onChange={(e: any) => {
          setConfigData({
            ...configValue,
            id_vm: e.target.value,
          });
          setValue('id_vm', e.target.value);
        }}
      />
      <Input
        variant="outline-orange"
        value={configValue.token_vm}
        disabled
        title="Token VM"
        errorMensage={formState.errors.token_vm?.message}
      />

      <SelectComponent
        value={autentiqueCompanies.find(
          (item) => item.value === configValue.autentique_companie_id
        )}
        title="Conta Autentique"
        closeMenuOnSelect
        isMulti={false}
        options={autentiqueCompanies}
        onChange={(e: any) => {
          setConfigData({
            ...configValue,
            autentique_companie_id: e.value,
            // autentique_companie_token: e.token,
          });
          setValue('autentique_companie_id', e.value);
          // setValue('autentique_companie_token', e.token);
        }}
      />
      <div className="grid sm:grid-cols-2 gap-3 col-span-4 lg:col-span-2">
        <SelectComponent
          value={optionsYesNo.find(
            (item) => item.value === configValue.is_wireframe
          )}
          title="Utiliza wireframe"
          closeMenuOnSelect
          isMulti={false}
          options={optionsYesNo}
          onChange={(e: any) => {
            setConfigData({ ...configValue, is_wireframe: e.value });
            setValue('is_wireframe', e.value);
          }}
        />
        <SelectComponent
          defaultValue={optionsYesNo.find(
            (item) => item.value === defaultValue?.is_generate_contract_opp
          )}
          title="Gerar contrato na oportunidade"
          closeMenuOnSelect
          isMulti={false}
          options={optionsYesNo}
          onChange={(e: any) => setValue('is_generate_contract_opp', e.value)}
        />
      </div>
      <div className="flex flex-col md:grid gap-3 md:grid-cols-2 col-span-4 lg:col-span-2">
        <SelectComponent
          value={partners.find((item) => item.value === configValue.partner_id)}
          title="Parceiros"
          closeMenuOnSelect
          isMulti={false}
          options={partners}
          onChange={(e: any) => {
            setConfigData({
              ...configValue,
              partner_id: e.value,
            });
            setValue('partner_id', e.value);
            clearErrors('partner_id');
          }}
          errorMensage={formState.errors.partner_id?.message}
        />
        <SelectComponent
          value={optionsUsersActive.find(
            (item) => item.value === configValue.companie_limit_user_id
          )}
          title="Plano de Venda LeadSim"
          closeMenuOnSelect
          isMulti={false}
          options={optionsUsersActive}
          onChange={(e: any) => {
            setConfigData({
              ...configValue,
              companie_limit_user_id: e.value,
              companie_limit_user_qty: e.qty,
            });
            setValue('companie_limit_user_id', e.value);
            setValue('companie_limit_user_qty', e.qty);
          }}
        />
      </div>
      <SelectComponent
        value={optionsYesNo.find(
          (item) => item.value === configValue.is_split_franchisor
        )}
        title="Gerar lançamento de SPLIT?"
        closeMenuOnSelect
        isMulti={false}
        options={optionsYesNo}
        onChange={(e: any) => {
          setConfigData({ ...configValue, is_split_franchisor: e.value });
          setValue('is_split_franchisor', e.value);
          if (!e.value) {
            setConfigData({
              ...configValue,
              is_split_franchisor: e.value,
              companie_id_split_franchisor: null,
              split_franchisor_type: null,
              split_franchisor_value: '0',
            });
            setValue('companie_id_split_franchisor', null);
            setValue('split_franchisor_type', null);
            setValue('split_franchisor_value', '0');
          }
        }}
      />
      <SelectComponent
        value={
          configValue.is_split_franchisor
            ? franchisorOptions.find(
                (item) =>
                  item.value === configValue.companie_id_split_franchisor
              )
            : { label: ' ', value: ' ' }
        }
        title="Franqueadora"
        closeMenuOnSelect
        isMulti={false}
        options={franchisorOptions}
        onChange={(e: any) => {
          setConfigData({
            ...configValue,
            companie_id_split_franchisor: e.value,
          });
          setValue('companie_id_split_franchisor', e.value);
        }}
        isDisabled={!configValue.is_split_franchisor}
      />
      <SelectComponent
        value={
          configValue.is_split_franchisor
            ? optionsTypeSplit.find(
                (item) => item.value === configValue.split_franchisor_type
              )
            : { label: ' ', value: ' ' }
        }
        title="Tipo"
        closeMenuOnSelect
        isMulti={false}
        options={optionsTypeSplit}
        onChange={(e: any) => {
          setConfigData({
            ...configValue,
            split_franchisor_type: e.value,
            split_franchisor_value: '',
          });
          setValue('split_franchisor_type', e.value);
        }}
        isDisabled={!configValue.is_split_franchisor}
      />
      <Input
        value={
          configValue.split_franchisor_type === 'percent'
            ? maskHelper.formatDecimal(configValue.split_franchisor_value ?? '')
            : maskHelper.formatCurrencyInput(
                configValue.split_franchisor_value ?? ''
              )
        }
        classNameDiv="col-span-2 md:col-span-1"
        disabled={!configValue.split_franchisor_type}
        variant="outline-orange"
        title="Valor"
        placeholder={
          configValue.split_franchisor_type === 'percent' ? '0%' : 'R$ 0,00'
        }
        onChange={(e) => {
          setConfigData({
            ...configValue,
            split_franchisor_value: e.target.value,
          });
        }}
        onBlur={(e) => {
          setValue('split_franchisor_value', maskHelper.number(e.target.value));
        }}
        errorMensage={formState.errors.split_franchisor_value?.message}
      />
      <strong className="col-span-4 lg:col-span-2 -mb-2">Taxa Cartão</strong>
      <strong className="hidden lg:flex col-span-4 lg:col-span-2 -mb-2">
        Taxa Boleto
      </strong>
      <SelectComponent
        classNameDiv="col-span-2 md:col-span-1"
        title="Tipo"
        defaultValue={optionsTypeSplit.find(
          (item) => item.value === configValue.split_creditcard_type
        )}
        options={optionsTypeSplit}
        onChange={(e: any) => {
          setConfigData({
            ...configValue,
            split_creditcard_type: e.value,
            split_creditcard_value: '',
          });
        }}
      />
      <Input
        value={
          configValue.split_creditcard_type === 'percent'
            ? maskHelper.formatDecimal(configValue.split_creditcard_value ?? '')
            : maskHelper.formatCurrencyInput(
                configValue.split_creditcard_value ?? ''
              )
        }
        classNameDiv="col-span-2 md:col-span-1"
        disabled={!configValue.split_creditcard_type}
        variant="outline-orange"
        title="Valor"
        placeholder={
          configValue.split_creditcard_type === 'percent' ? '0%' : 'R$ 0,00'
        }
        onChange={(e) => {
          setConfigData({
            ...configValue,
            split_creditcard_value: e.target.value,
          });
        }}
        onBlur={(e) => {
          setValue('split_creditcard_value', maskHelper.number(e.target.value));
        }}
        errorMensage={formState.errors.split_creditcard_value?.message}
      />
      <strong className="flex md:hidden col-span-4">Split Boleto</strong>

      <SelectComponent
        title="Tipo"
        defaultValue={optionsTypeSplit.find(
          (item) => item.value === configValue.split_boleto_type
        )}
        classNameDiv="col-span-2 md:col-span-1"
        options={optionsTypeSplit}
        onChange={(e: any) => {
          setConfigData({
            ...configValue,
            split_boleto_type: e.value,
            split_boleto_value: '',
          });
        }}
      />

      <Input
        value={
          configValue.split_boleto_type === 'percent'
            ? maskHelper.formatDecimal(configValue.split_boleto_value ?? '')
            : maskHelper.formatCurrencyInput(
                configValue.split_boleto_value ?? ''
              )
        }
        classNameDiv="col-span-2 md:col-span-1"
        disabled={!configValue.split_boleto_type}
        variant="outline-orange"
        title="Valor"
        placeholder={
          configValue.split_boleto_type === 'percent' ? '0%' : 'R$ 0,00'
        }
        onChange={(e) => {
          setConfigData({
            ...configValue,
            split_boleto_value: e.target.value,
          });
        }}
        onBlur={(e) => {
          setValue('split_boleto_value', maskHelper.number(e.target.value));
        }}
        errorMensage={formState.errors.split_boleto_value?.message}
      />
      <strong className="col-span-4 -mb-2">Taxa PIX</strong>

      <SelectComponent
        title="Tipo"
        defaultValue={optionsTypeSplit.find(
          (item) => item.value === configValue.split_pix_type
        )}
        classNameDiv="col-span-2 md:col-span-1"
        onChange={(e: any) => {
          setConfigData({
            ...configValue,
            split_pix_type: e.value,
            split_pix_value: '',
          });
        }}
        options={optionsTypeSplit}
      />

      <Input
        value={
          configValue.split_pix_type === 'percent'
            ? maskHelper.formatDecimal(configValue.split_pix_value ?? '')
            : maskHelper.formatCurrencyInput(configValue.split_pix_value ?? '')
        }
        disabled={!configValue.split_pix_type}
        variant="outline-orange"
        title="Valor"
        placeholder={
          configValue.split_pix_type === 'percent' ? '0%' : 'R$ 0,00'
        }
        classNameDiv="col-span-2 md:col-span-1"
        onChange={(e) => {
          setConfigData({
            ...configValue,
            split_pix_value: e.target.value,
          });
        }}
        onBlur={(e) => {
          setValue('split_pix_value', maskHelper.number(e.target.value));
        }}
        errorMensage={formState.errors.split_pix_value?.message}
      />

      <div className="col-span-4 flex justify-center mt-6">
        <Button
          type="submit"
          actionType="button-loading"
          isLoading={isLoading}
          disabled={isLoading}
        >
          Salvar e continuar
        </Button>
      </div>
    </form>
  );
};

export default Configuration;
