import { baseApiPrivate } from '../../../api/baseApi';
import { fileToBase64 } from '../../../helpers/utils.helper';
import { EconomicActivity } from '../../../types';
import { ClientProps } from '../../clients/types';
import {
  filterFinancials,
  downloadExpensesProps,
  attachment,
  FinancialProps,
} from '../types';

async function getFinancials(
  filters: filterFinancials,
  period: string
): Promise<any | string> {
  return baseApiPrivate({
    method: 'POST',
    body: filters,
    url: `companie/financial/filterAdvanced/${period}`,
  });
}

async function seachFinancial(
  key: string,
  skip: number,
  take: number
): Promise<{ data: FinancialProps[]; total: number } | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/financial/autocomplete/find?q=${key}&skip=${skip}&take=${take}`,
  });
}

async function getFiles(id: number): Promise<attachment[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/financial/attachment/${id}`,
  });
}

async function createFiles(files: any): Promise<any[] | string> {
  return baseApiPrivate({
    method: 'POST',
    body: files,
    url: `companie/financial/attachment`,
  });
}

async function deleteFiles(id: number): Promise<boolean> {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/financial/attachment/${id}`,
  });
}

async function createFinancial(information: any) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: `companie/financial`,
  });
}

async function updateFinancial(information: any, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/financial`,
  });
}

async function revertPayedFinancial(id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/financial/revertPayed/${id}`,
  });
}

async function downloadFinancial(
  information: downloadExpensesProps,
  id: number
) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/financial/payed/${id}`,
  });
}

async function deleteExpense(id: number, deleteAll: boolean) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/financial/${id}/${deleteAll}`,
  });
}

async function createServiceInvoiceConfig(serviceInvoice: Record<string, any>) {
  return baseApiPrivate({
    body: serviceInvoice,
    method: 'POST',
    url: `companie/financial/serviceInvoiceCompany`,
  });
}

async function updateServiceInvoiceConfig(serviceInvoice: Record<string, any>) {
  return baseApiPrivate({
    body: serviceInvoice,
    method: 'PUT',
    url: `companie/financial/serviceInvoiceConfig`,
  });
}

async function getServiceInvoiceConfig() {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/financial/invoiceConfig/NFS-e`,
  });
}

async function uploadServiceInvoiceCompanyCertificate(
  password: string,
  file: File
) {
  const payload: any = {
    password,
    title: file.name,
    file: {
      originalFileName: file.name,
      base64: (await fileToBase64(file)).split(',')[1],
    },
  };

  return baseApiPrivate({
    body: payload,
    method: 'POST',
    url: `companie/financial/serviceInvoiceCompanyCertificate`,
  });
}
async function getPossibleCompanyEconomicActivities() {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/financial/serviceInvoiceCompany/possibleEconomicActivities`,
  }) as Promise<{ data: EconomicActivity[] }>;
}

async function updateCompanyInvoiceServices(
  companyInvoiceServices: Partial<EconomicActivity>
) {
  return baseApiPrivate({
    method: 'POST',
    url: `companie/financial/serviceInvoiceCompany/companyEconomicActivities`,
    body: companyInvoiceServices,
  }) as Promise<{ data: EconomicActivity[] }>;
}

async function getCustomCompanyEconomicActivity() {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/financial/serviceInvoiceCompany/customCompanyEconomicActivity`,
  }) as Promise<{ data: EconomicActivity[] }>;
}

async function saveCustomCompanyEconomicActivity(
  customCompanyEconomicActivity: any
) {
  return baseApiPrivate({
    method: 'POST',
    url: `companie/financial/serviceInvoiceCompany/customCompanyEconomicActivity`,
    body: customCompanyEconomicActivity,
  }) as Promise<{ data: EconomicActivity[] }>;
}

async function getCompanyEconomicActivities() {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/financial/serviceInvoiceCompany/companyEconomicActivities`,
  }) as Promise<{ data: EconomicActivity[] }>;
}

async function updateClientInvoiceServices(
  companyInvoiceServices: Partial<EconomicActivity>
) {
  return baseApiPrivate({
    method: 'POST',
    url: `companie/financial/serviceInvoiceCompany/clientEconomicActivity`,
    body: companyInvoiceServices,
  }) as Promise<{ data: EconomicActivity[] }>;
}

async function getClientEconomicActivities(client: ClientProps) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/financial/serviceInvoiceCompany/clientEconomicActivity?client_id=${client.id}`,
  }) as Promise<{ data: EconomicActivity[] }>;
}

async function requestServiceInvoice(financialId: number, serviceInvoice: any) {
  return baseApiPrivate({
    method: 'POST',
    url: `companie/financial/${financialId}/serviceInvoiceCompany/requestServiceInvoice`,
    body: serviceInvoice,
  });
}

async function getInvoiceCreditBalance() {
  return baseApiPrivate({
    method: 'GET',
    url: 'companie/user/invoiceCreditBalance',
  });
}

export const ApiExpenses = {
  getFiles,
  createFiles,
  revertPayedFinancial,
  deleteFiles,
  updateFinancial,
  createFinancial,
  seachFinancial,
  downloadFinancial,
  getFinancials,
  deleteExpense,
  createServiceInvoiceConfig,
  getServiceInvoiceConfig,
  updateServiceInvoiceConfig,
  uploadServiceInvoiceCompanyCertificate,
  getPossibleCompanyEconomicActivities,
  updateCompanyInvoiceServices,
  getCustomCompanyEconomicActivity,
  saveCustomCompanyEconomicActivity,
  getCompanyEconomicActivities,
  updateClientInvoiceServices,
  getClientEconomicActivities,
  requestServiceInvoice,
  getInvoiceCreditBalance,
};
