import React, { useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/button';
import AlertCard from '../Card/alertCard';

interface props {
  title: string;
  description: string;
  timeDisplay: number;
  titleButton: string;
  actionButton: () => void;
  allowLeave?: boolean;
  titleButton2: string;
  isDisabled?: boolean;
}

const AlertPopUpWithTimer: React.FC<props> = ({
  actionButton,
  timeDisplay,
  title,
  titleButton,
  titleButton2,
  allowLeave,
  description,
  isDisabled = false,
}) => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    const interval = setInterval(() => {
      setShow(true);
      if (isDisabled) {
        clearInterval(interval);
      }
    }, timeDisplay);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isDisabled) {
      setShow(false);
    }
  }, [isDisabled]);

  return (
    <Modal
      title="Atenção!"
      header={false}
      isOpen={show && !isDisabled}
      setIsOpen={() => setShow(false)}
      minHeight="min-h-[200px]"
    >
      <div className="flex flex-col gap-3">
        <strong className="text-lg font-medium leading-6 text-gray-900 mb-2">
          {title}
        </strong>
        <AlertCard message={<p>{description}</p>} />
        {allowLeave ? (
          <div className="w-full flex gap-2 mt-8">
            <Button
              onClick={() => setShow(false)}
              className="w-full"
              variant="outline-primary"
            >
              {titleButton2}
            </Button>

            <Button
              onClick={actionButton}
              className="w-full"
              variant="primary-strong"
            >
              {titleButton}
            </Button>
          </div>
        ) : (
          <Button onClick={actionButton} className="w-full">
            {titleButton}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default AlertPopUpWithTimer;
