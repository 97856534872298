import { baseApiPrivate } from '../../../api/baseApi';
import {
  addressData,
  generalData,
  ProviderProps,
  attachment,
  contacts,
} from '../types';

async function createProviderGeneralData(information: generalData) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: 'companie/provider',
  });
}
async function updateGeneralData(information: any, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/provider/${id}`,
  });
}
async function updateAddress(information: addressData, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/provider/address/${id}`,
  });
}

async function getProviderByCompanie(
  companyId: number
): Promise<ProviderProps[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/provider/${companyId}`,
  });
}
function getAllProducts(): Promise<any[]> {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/product`,
  });
}
function getAllCategories(): Promise<any[]> {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/category`,
  });
}

async function deleteProvider(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/provider/${id}`,
  });
}
function getProviderContacts(id: number): Promise<contacts[]> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/provider/contacts/${id}`,
  });
}
function createContact(contact: contacts) {
  return baseApiPrivate({
    method: 'POST',
    body: contact,
    url: `companie/provider/contacts`,
  });
}
function updateContact(contact: contacts, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    body: contact,
    url: `companie/provider/contacts/${id}`,
  });
}
function deleteContact(id: number): Promise<boolean> {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/provider/contacts/${id}`,
  });
}
function getProviderAttachments(id: number): Promise<attachment[]> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/provider/attachment/${id}`,
  });
}
function createProviderAttachments(attachments: any) {
  return baseApiPrivate({
    method: 'POST',
    body: attachments,
    url: `companie/provider/attachment`,
  });
}
function deleteAttachments(id: number): Promise<boolean> {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/provider/attachment/${id}`,
  });
}

export const ApiProvider = {
  getProviderAttachments,
  createProviderAttachments,
  deleteAttachments,
  getProviderByCompanie,
  getProviderContacts,
  getAllProducts,
  getAllCategories,
  createContact,
  updateContact,
  deleteContact,
  createProviderGeneralData,
  deleteProvider,
  updateAddress,
  updateGeneralData,
};
