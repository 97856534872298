export const monthsOptions = [
  { label: 'Janeiro', value: 1 },
  { label: 'Fevereiro', value: 2 },
  { label: 'Março', value: 3 },
  { label: 'Abril', value: 4 },
  { label: 'Maio', value: 5 },
  { label: 'Junho', value: 6 },
  { label: 'Julho', value: 7 },
  { label: 'Agosto', value: 8 },
  { label: 'Setembro', value: 9 },
  { label: 'Outubro', value: 10 },
  { label: 'Novembro', value: 11 },
  { label: 'Dezembro', value: 12 },
];

export const imagesExtentions = [
  'ase',
  'art',
  'bmp',
  'blp',
  'cd5',
  'cit',
  'cpt',
  'cr2',
  'cut',
  'dds',
  'dib',
  'djvu',
  'egt',
  'exif',
  'gif',
  'gpl',
  'grf',
  'icns',
  'ico',
  'iff',
  'jng',
  'jpeg',
  'jpg',
  'jfif',
  'jp2',
  'jps',
  'lbm',
  'max',
  'miff',
  'mng',
  'msp',
  'nef',
  'nitf',
  'ota',
  'pbm',
  'pc1',
  'pc2',
  'pc3',
  'pcf',
  'pcx',
  'pdn',
  'pgm',
  'PI1',
  'PI2',
  'PI3',
  'pict',
  'pct',
  'pnm',
  'pns',
  'ppm',
  'psb',
  'psd',
  'pdd',
  'psp',
  'px',
  'pxm',
  'pxr',
  'qfx',
  'raw',
  'rle',
  'sct',
  'sgi',
  'rgb',
  'int',
  'bw',
  'tga',
  'tiff',
  'tif',
  'vtf',
  'xbm',
  'xcf',
  'xpm',
  '3dv',
  'amf',
  'ai',
  'awg',
  'cgm',
  'cdr',
  'cmx',
  'dxf',
  'e2d',
  'egt',
  'eps',
  'fs',
  'gbr',
  'odg',
  'svg',
  'stl',
  'vrml',
  'x3d',
  'sxd',
  'v2d',
  'vnd',
  'wmf',
  'emf',
  'art',
  'xar',
  'png',
  'webp',
  'jxr',
  'hdp',
  'wdp',
  'cur',
  'ecw',
  'iff',
  'lbm',
  'liff',
  'nrrd',
  'pam',
  'pcx',
  'pgf',
  'sgi',
  'rgb',
  'rgba',
  'bw',
  'int',
  'inta',
  'sid',
  'ras',
  'sun',
  'tga',
  'heic',
  'heif',
];

export const clientOptions = [
  {
    label: 'CNPJ do Cliente',
    value: '{{document}}',
  },
  { label: 'Nome do Cliente', value: '{{name}}' },
  {
    label: 'Endereço do Cliente',
    value:
      '{{address}}, {{number}}, {{complement}}, {{province}}, {{city}}, {{state}} - {{zipcode}}',
  },
  {
    label: 'Whatsapp do cliente',
    value: '{{whatsapp}}',
  },
  {
    label: 'Telefone fixo do cliente',
    value: '{{phone}}',
  },
  {
    label: 'Email do cliente',
    value: '{{email}}',
  },
  {
    label: 'RG do Cliente',
    value: '{{rg}}',
  },
  {
    label: 'Estado Civil do Cliente',
    value: '{{civil_status}}',
  },
  {
    label: 'Data de nascimento',
    value: '{{birth}}',
  },
  {
    label: 'Produtos do Contrato',
    value: '{{items_contract}}',
  },
  {
    label: 'Início do Contato',
    value: '{{date_start}}',
  },
  {
    label: 'Fim do Contato',
    value: '{{date_end}}',
  },
  {
    label: 'Período do Contrato',
    value: '{{contract_period}}',
  },
  {
    label: 'Valor do Contrato',
    value: '{{contract_value}}',
  },
  {
    label: 'Forma de Pagamento Contrato',
    value: '{{contract_payment_form}}',
  },
  {
    label: 'Área do Contrato',
    value: '{{contract_area}}',
  },
  {
    label: 'Índice de Reajuste',
    value: '{{readjustment_index}}',
  },
  {
    label: 'Multa',
    value: '{{fine}}',
  },
  {
    label: 'Diária de Viagem',
    value: '{{travel_alowance}}',
  },
  {
    label: 'Êxito',
    value: '{{gain}}',
  },
  {
    label: 'Ato de Serviço Não Local',
    value: '{{non_local_service}}',
  },
  {
    label: 'Ato de Serviço Local',
    value: '{{local_service}}',
  },
  {
    label: 'KM rodado comarca',
    value: '{{km_comarca}}',
  },
  {
    label: 'KM rodado fora',
    value: '{{km_out}}',
  },
  {
    label: 'Representante Legal',
    value: '{{client_agent_legal}}',
  },
  {
    label: 'Email Representante Legal',
    value: '{{client_agent_email}}',
  },
  {
    label: 'Cpf Representante Legal',
    value: '{{client_agent_cpf}}',
  },
  {
    label: 'Telefone Representante Legal',
    value: '{{client_agent_whatsapp}}',
  },
  {
    label: 'Observação',
    value: '{{obs}}',
  },
  {
    label: 'Nova página',
    value: '{{new_page}}',
  },
];
