import { baseApiPrivate } from '../../../../api/baseApi';
import { InvoiceProps } from '../types';

export const ApiInvoice = {
  get: async () =>
    baseApiPrivate({ method: 'GET', url: 'admin/invoiceCredit' }),

  active: async () =>
    baseApiPrivate({
      method: 'GET',
      url: 'admin/invoiceCredit/active',
    }),

  create: async (body: Omit<InvoiceProps, 'id'>) =>
    baseApiPrivate({
      method: 'POST',
      url: 'admin/invoiceCredit',
      body,
    }),

  put: async (body: Omit<InvoiceProps, 'id'>, id: number) =>
    baseApiPrivate({
      method: 'PUT',
      url: `admin/invoiceCredit/${id}`,
      body,
    }),

  delete: async (id: number) =>
    baseApiPrivate({
      method: 'DELETE',
      url: `admin/invoiceCredit/${id}`,
    }),
};
