import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { GrFormClose } from 'react-icons/gr';
import { getItem, setItem } from '../../helpers/storage.helper';
import { getTokenAccountInformation } from '../../helpers/token.helper';
import Modal from '../Modal/Modal';
import Button from '../Button/button';
import AlertCard from '../Card/alertCard';
import { PublicRoutes } from '../../helpers/utils.helper';
import { logout } from '../../modules/login/api';

const AlertTrial: React.FC = () => {
  const [[modal, block], setShow] = useState([
    { show: false, daysLeft: 0 },
    false,
  ]);
  useEffect(() => {
    const lastDateStr: null | moment.Moment = getItem('last_trial');
    const token = getTokenAccountInformation();
    const today = moment();

    const isPublic = PublicRoutes.find((item) =>
      window.location.pathname.startsWith(item)
    );

    if (isPublic) return;

    if (token && token.companie?.companie_limit_user?.is_trial) {
      if (lastDateStr) {
        const lastDate = moment(lastDateStr);
        const creatAt = moment(token.companie.created_at);

        const daysLeft = 30 - today.diff(creatAt, 'days');

        if (daysLeft < 0) {
          setShow([{ daysLeft: 0, show: true }, true]);
          return;
        }
        if (daysLeft > 0 && !lastDate.isSame(today, 'day')) {
          setShow([{ show: true, daysLeft }, false]);
        }
      } else {
        setItem('last_trial', today);
        setShow([{ show: true, daysLeft: 30 }, false]);
      }
    }
  }, []);

  return (
    <Modal
      title="Atenção!"
      header={!block}
      isOpen={modal.show || block}
      setIsOpen={() => setShow([{ daysLeft: 0, show: false }, false])}
      minHeight="min-h-[200px]"
    >
      {modal.daysLeft === 0 ? (
        <>
          <div className="flex justify-end">
            <GrFormClose
              size={25}
              style={{ cursor: 'pointer' }}
              onClick={async () => {
                await logout();
                window.location.reload();
              }}
            />
          </div>
          <div className="flex flex-col gap-3">
            <strong className="text-lg font-medium leading-6 text-gray-900 mb-2">
              Plano Trial Expirado
            </strong>
            <AlertCard message={<p>O seu período de testes acabou!</p>} />
            <p className="mt-3 mb-5 text-sm">
              Não fique sem o acesso aos nossos serviços, contrate agora um dos
              nossos planos e tenha total acesso a plataforma.
            </p>
            <a
              className="w-full"
              target="_blank"
              href="https://wa.me/5531971411125?text=Ol%C3%A1%21+Gostaria+de+falar+com+o+atendimento+do+LeadSim"
              rel="noreferrer"
            >
              <Button className="w-full">Contratar Plano</Button>
            </a>
          </div>
        </>
      ) : (
        <div className="flex flex-col gap-3">
          <AlertCard
            message={
              <p>
                Restam <strong>{modal.daysLeft} dia(s)</strong> para o seu plano
                trial acabar!
              </p>
            }
          />

          <p className="mt-3 mb-5 text-sm">
            Não fique sem o acesso aos nossos serviços, contrate agora um dos
            nossos planos e tenha total acesso a plataforma.
          </p>
          <a
            className="w-full"
            target="_blank"
            href="https://wa.me/5531971411125?text=Ol%C3%A1%21+Gostaria+de+falar+com+o+atendimento+do+LeadSim"
            rel="noreferrer"
          >
            <Button actionType="button-full-width">Contratar Plano</Button>
          </a>
        </div>
      )}
    </Modal>
  );
};

export default AlertTrial;
