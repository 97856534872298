import { Link } from 'react-router-dom';
import React from 'react';
import { Modal } from '../../../../components';
import ButtonRounded from '../button';

interface props {
  show: boolean;
  setShow: (e: boolean) => void;
  login: () => void;
}
const ModalPaymentSuccess: React.FC<props> = ({ setShow, show, login }) => (
  <Modal
    minHeight="min-h-[100px]"
    size="medium"
    header={false}
    title=""
    setIsOpen={setShow}
    isOpen={show}
  >
    <div className="flex flex-col text-center">
      <strong className="text-lg">Parabéns!</strong>
      <br />
      <p className="text-sm">
        Você agora tem acesso à plataforma Leadsim.
        <br /> Enviamos um e-mail com as orientações para iniciar seu uso.
      </p>

      <ButtonRounded onClick={login}>Fazer login</ButtonRounded>
    </div>
  </Modal>
);

export default ModalPaymentSuccess;
