import React from 'react';

import { useWppContext } from '../../context';
import { ModalConnectInstance } from '../../../../components/Modal/ModalConnectInstance';

export const ConnectModal: React.FC = () => {
  const { modal, setModal, setReload } = useWppContext();

  return (
    <ModalConnectInstance
      show={modal.show === 'connect'}
      close={() => setModal({ ...modal, show: 'undefined' })}
      closeOnConnect={() => {
        setReload(String(Math.random()));
      }}
      instance={modal.data}
    />
  );
};
