import React, { useState } from 'react';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import MainPage from '../../../main/components/mainPage';

import { ToastNotify } from '../../../../components/Toast/toast';
import { DeleteModal, Switch } from '../../../../components';
import RegisterGroup from '../components/register';

import { useFetchGroup } from '../hooks';
import PartnerApi from '../api';

import { Partner } from '../types';

const Partners: React.FC = () => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalRegister, setModalRegister] = useState(false);
  const [input, setInput] = useState('');
  const [partner, setPartner] = useState<Partner | null>(null);
  const [key, setKey] = useState('key');

  const { GroupList, isLoadingGroup } = useFetchGroup(key);
  const { notify } = ToastNotify();

  const columns = [
    {
      name: 'Nome',
      key: 'name',
      selector: (row: Partner) => row.name,
    },
    {
      name: 'Status',
      selector: (row: any) => (
        <Switch onChange={() => {}} state={row.is_active} />
      ),
    },
    {
      name: '',
      cell: (row: Partner) => {
        const disabled =
          row.administrator_user?.length > 0 && row.companie.length > 0;

        return (
          <CloseOutline
            size={20}
            className="cursor-pointer"
            color={disabled ? '#DDD' : '#DA3C3C'}
            onClick={() => {
              if (disabled) return;

              setPartner(row);
              setShowModalDelete(true);
            }}
          />
        );
      },
    },
  ];

  const filterTable = () => {
    if (input === '') {
      return GroupList;
    }
    return GroupList.filter(
      (item) => item.name.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
  };

  return (
    <>
      <MainPage
        pagination={input === ''}
        isLoading={isLoadingGroup}
        column={columns}
        title="Parceiros"
        filterTable={filterTable}
        setInput={setInput}
        setModalCreateUpdate={setModalRegister}
        setRegisterPage={() => {
          setPartner(null);
        }}
        onRowClick={(e: Partner) => {
          setPartner(e);
          setModalRegister(true);
        }}
      />
      <RegisterGroup
        partner={partner}
        reloadTable={setKey}
        setShow={setModalRegister}
        show={modalRegister}
      />
      {partner && (
        <DeleteModal
          apiFunction={PartnerApi.delete}
          notify={notify}
          type="Parceiros"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={partner.id}
          name={partner.name}
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default Partners;
