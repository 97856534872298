import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Modal,
  SelectComponent,
  Spinner,
} from '../../../../components';
import { filterOpportunityProps } from '../../types';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setCardsOrder: (e: any) => void;
  funnelId: number;
  period: string;
  companyId: number;
  responseOptions: { label: string; value: number }[];
  userOptions: { label: string; value: number }[];
  salesChannelOptions: { label: string; value: number }[];
  opportunityList: { label: string; value: number }[];
  setFilter: (e: filterOpportunityProps) => void;
  filters: filterOpportunityProps;
  setInputOpportunity: (e: string) => void;
  isLoadingDropdown: boolean;
}

const SearchOpportunity: React.FC<registerModalProps> = ({
  show,
  setShow,
  setCardsOrder,
  opportunityList,
  salesChannelOptions,
  responseOptions,
  userOptions,
  companyId,
  funnelId,
  period,
  filters,
  setFilter,
  isLoadingDropdown,
  setInputOpportunity,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingClear, setIsLoadingClear] = useState(false);
  const schema = yup.object().shape({
    order: yup.string().required('Selecione uma opção'),
    status: yup.string().required('Selecione uma opção'),
  });

  const accountProps = getTokenAccountInformation();
  const userId = accountProps.id;

  const { formState, handleSubmit, setValue, reset, control } =
    useForm<filterOpportunityProps>({
      resolver: yupResolver(schema),
      defaultValues: {
        opportunityId: null,
        productId: [],
        salesChannelId: [],
        responses_win_lose_id: [],
        companieUserId: [],
        order: 'title_asc',
        status: 'Aberta',
      },
    });
  const loadFiltersFromLocalStorage = () => {
    const savedFilters = localStorage.getItem(`filters_${userId}`);
    if (savedFilters) {
      return JSON.parse(savedFilters);
    }
    return null;
  };

  const Submit = async (e: filterOpportunityProps) => {
    setIsLoading(true);
    setFilter(e);

    localStorage.setItem(`filters_${userId}`, JSON.stringify(e));

    setShow(false);
    setIsLoading(false);
  };

  const orderOptions = [
    { value: 'title_asc', label: 'Alfabética crescente' },
    { value: 'title_desc', label: 'Alfabética decrescente' },
    { value: 'created_at_asc', label: 'Data crescente' },
    { value: 'created_at_desc', label: 'Data decrescente' },
  ];

  const statusOptions = [
    { value: 'Aberta', label: 'Aberta' },
    { value: 'Ganha', label: 'Ganha' },
    { value: 'Perdida', label: 'Perdida' },
    { value: 'Todos', label: 'Todos' },
  ];

  const ClearData = async () => {
    setIsLoadingClear(true);
    const defaultFilter: filterOpportunityProps = {
      opportunityId: null,
      productId: [],
      salesChannelId: [],
      responses_win_lose_id: [],
      companieUserId: [],
      order: 'title_asc',
      status: 'Aberta',
      skip: 0,
      take: 30,
    };
    await Submit(defaultFilter);
    reset(defaultFilter);
    localStorage.removeItem(`filters_${userId}`);
    setShow(false);

    setIsLoadingClear(false);
  };

  useEffect(() => {
    const savedFilters = loadFiltersFromLocalStorage();
    if (savedFilters) {
      reset(savedFilters);
      setFilter(savedFilters);
    } else {
      reset(filters);
    }
  }, [show]);
  return (
    <Modal
      title="Procurar oportunidade"
      isOpen={show}
      setIsOpen={setShow}
      size="2xlarge"
      minHeight="min-h-[540px]"
    >
      <form
        className="flex flex-col sm:grid sm:grid-cols-2 gap-3"
        onSubmit={handleSubmit(Submit)}
      >
        <Controller
          control={control}
          name="order"
          render={({ field }) => (
            <SelectComponent
              title="Ordenar"
              options={orderOptions}
              value={orderOptions.filter((item) => field.value === item.value)}
              defaultValue={orderOptions[0]}
              closeMenuOnSelect
              onChange={(e: any) => {
                setValue('order', e.value);
              }}
              errorMensage={formState.errors.order?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="status"
          render={({ field }) => (
            <SelectComponent
              title="Status"
              options={statusOptions}
              closeMenuOnSelect
              value={statusOptions.filter((item) =>
                field.value.includes(item.value)
              )}
              defaultValue={statusOptions[0]}
              maxOptionsHeight="300px"
              onChange={(e: any) => {
                setValue('status', e.value);
              }}
              errorMensage={formState.errors.status?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="responses_win_lose_id"
          render={({ field }) => (
            <SelectComponent
              value={responseOptions.filter((item) =>
                field.value.includes(item.value)
              )}
              hasDivision
              title="Respostas"
              options={responseOptions}
              isMulti
              closeMenuOnSelect={false}
              onChange={(e: any[]) => {
                setValue(
                  'responses_win_lose_id',
                  e.map((res) => res.value)
                );
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="companieUserId"
          render={({ field }) => (
            <SelectComponent
              title="Usuários"
              options={userOptions}
              value={userOptions.filter((item) =>
                field.value?.includes(item.value)
              )}
              isMulti
              isDisabled={accountProps.profile.id === 5}
              closeMenuOnSelect={false}
              onChange={(e: any[]) => {
                setValue(
                  'companieUserId',
                  e.map((user) => user.value)
                );
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="opportunityId"
          render={({ field }) => (
            <SelectComponent
              value={field.value}
              title="Oportunidade"
              isLoading={isLoadingDropdown}
              onInputChange={setInputOpportunity}
              autoComplete
              isClearable
              maxOptionsHeight="120px"
              options={opportunityList}
              closeMenuOnSelect
              onChange={(e: any) => {
                setValue('opportunityId', e);
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="salesChannelId"
          render={({ field }) => (
            <SelectComponent
              title="Canal de venda"
              maxOptionsHeight="120px"
              value={salesChannelOptions.filter((item) =>
                field.value?.includes(item.value)
              )}
              options={salesChannelOptions}
              closeMenuOnSelect
              isMulti
              onChange={(e: any[]) => {
                setValue(
                  'salesChannelId',
                  e.map((sale) => sale.value)
                );
              }}
            />
          )}
        />
        <div className="col-span-2 w-full flex gap-3 justify-center flex-col sm:flex-row">
          <Button
            disabled={isLoadingClear}
            variant="outline-primary"
            onClick={ClearData}
            actionType="button-loading"
            className="font-bold relative flex items-center justify-center mt-4 w-64"
          >
            {isLoadingClear && (
              <div className="absolute right-0">
                <Spinner />
              </div>
            )}
            Limpar
          </Button>
          <Button
            disabled={isLoading}
            isLoading={!isLoadingClear && isLoading}
            variant="primary-strong"
            type="submit"
            actionType="button-loading"
            className="font-bold relative flex items-center justify-center mt-4 w-64"
          >
            Filtrar
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default SearchOpportunity;
