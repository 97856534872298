import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ClientContactData } from '../../../types';
import { ApiClient } from '../../../api';
import { Button, Input, InputDate, Modal } from '../../../../../components';
import { notify } from '../../../../../components/Toast/toast';
import maskHelper from '../../../../../helpers/mask.helper';
import cpfValidation from '../../../../../helpers/validations/cpf.validation';
import cnpjValidation from '../../../../../helpers/validations/cnpj.validation';
import phoneValidate from '../../../../../helpers/validations/phone.validate';

const headerOutsideCreate = [
  {
    id: 0,
    title: 'Dados Pessoais',
    page: 0,
  },
];

interface FormData {
  name: string;
  organization: string;
  document: string;
  birth?: Date;
  phone: string;
  whatsapp: string;
  email: string;
  position: string;
}

interface RegisterContactProps {
  show: boolean;
  // eslint-disable-next-line no-unused-vars
  setShow: (show: boolean) => void;
  edit?: boolean;
  contactData?: ClientContactData;
  onSuccess?: () => void;
  clientId: number;
}

const schema = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
  organization: yup.string(),
  document: yup
    .string()
    .test('is-valid-document', 'CPF/CNPJ inválido', (value) => {
      if (!value) return true;
      const numericValue = maskHelper.number(value);
      return (
        cpfValidation.isValid(numericValue) ||
        cnpjValidation.isValid(numericValue)
      );
    }),
  birth: yup
    .date()
    .typeError('Data inválida')
    .nullable()
    .max(new Date(), 'Data deve ser menor ou igual a hoje'),
  phone: yup.string().test('is-valid-phone', 'Telefone inválido', (value) => {
    if (!value) return true;
    return phoneValidate.isValid(maskHelper.number(value || ''));
  }),
  whatsapp: yup
    .string()
    .required('WhatsApp é obrigatório')
    .test('is-valid-whatsapp', 'WhatsApp inválido', (value) =>
      phoneValidate.isValid(maskHelper.number(value || ''))
    ),
  email: yup.string().email('E-mail inválido'),
  position: yup.string(),
});

const RegisterContact: React.FC<RegisterContactProps> = ({
  show,
  setShow,
  edit = false,
  contactData,
  onSuccess,
  clientId,
}) => {
  const [registerPage, setRegisterPage] = useState({
    title: headerOutsideCreate[0].title,
    page: headerOutsideCreate[0].page,
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues:
      edit && contactData
        ? {
            name: contactData.name,
            organization: contactData.organization || '',
            document: contactData.document || '',
            birth: contactData.birth ? new Date(contactData.birth) : undefined,
            phone: contactData.phone || '',
            whatsapp: contactData.whatsapp || '',
            email: contactData.email || '',
            position: contactData.position || '',
          }
        : {
            name: '',
            organization: '',
            document: '',
            birth: undefined,
            phone: '',
            whatsapp: '',
            email: '',
            position: '',
          },
  });

  useEffect(() => {
    if (edit && contactData) {
      reset({
        name: contactData.name,
        organization: contactData.organization || '',
        document: contactData.document || '',
        birth: contactData.birth ? new Date(contactData.birth) : undefined,
        phone: contactData.phone || '',
        whatsapp: contactData.whatsapp || '',
        email: contactData.email || '',
        position: contactData.position || '',
      });
    } else {
      reset({
        name: '',
        organization: '',
        document: '',
        birth: undefined,
        phone: '',
        whatsapp: '',
        email: '',
        position: '',
      });
    }
  }, [edit, contactData, reset]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const transformEmptyToNull = (value: any) =>
        value === '' ? null : value;
      const formattedData: ClientContactData = {
        name: data.name,
        organization: transformEmptyToNull(data.organization),
        document: transformEmptyToNull(maskHelper.number(data.document)),
        birth: data.birth
          ? maskHelper.formatDateYMD(data.birth.toISOString())
          : undefined,
        phone: transformEmptyToNull(maskHelper.number(data.phone)),
        whatsapp: maskHelper.number(data.whatsapp),
        email: transformEmptyToNull(data.email),
        position: transformEmptyToNull(data.position),
        client_id: clientId,
      };
      if (edit && contactData && contactData.id) {
        const { id } = contactData;
        // eslint-disable-next-line camelcase
        const { client_id, ...dataToUpdate } = formattedData;

        try {
          const res = await ApiClient.updateClientContact(id, dataToUpdate);

          if (res.id) {
            notify({
              message: 'Contato atualizado com sucesso!',
              type: 'Success',
            });
          } else if (res.success) {
            notify({
              message: 'Contato atualizado com sucesso!',
              type: 'Success',
            });
          } else {
            notify({ message: 'Erro ao atualizar o contato.', type: 'Error' });
          }
        } catch (error) {
          console.error('Erro ao atualizar o contato:', error);
          notify({ message: 'Erro ao atualizar o contato.', type: 'Error' });
        }
      } else {
        const res = await ApiClient.createClientContact(formattedData);

        if (res.id) {
          notify({ message: 'Contato criado com sucesso!', type: 'Success' });
          reset({
            name: '',
            organization: '',
            document: '',
            birth: undefined,
            phone: '',
            whatsapp: '',
            email: '',
            position: '',
          });
        } else {
          notify({ message: 'Erro ao criar o contato.', type: 'Error' });
        }
      }

      setShow(false);
      if (onSuccess) onSuccess();
    } catch (error) {
      console.error('Erro ao salvar o contato:', error);
      notify({ message: 'Erro ao salvar o contato.', type: 'Error' });
    }
  };

  return (
    <Modal
      title={edit ? 'Editar Contato' : 'Cadastrar Contato'}
      isOpen={show}
      setIsOpen={() => setShow(false)}
      size="4xlarge"
      minHeight="min-h-[690px]"
    >
      <div className="flex flex-col">
        <div className="grid grid-cols-2 sm:flex gap-2 mt-9">
          {headerOutsideCreate.map((item) => (
            <Button
              key={item.id}
              variant={
                registerPage.page === item.page ? 'primary-strong' : 'gray'
              }
              actionType="button-full-width"
              className="font-medium md:w-32 cursor-pointer"
              onClick={() => setRegisterPage(item)}
            >
              {item.title}
            </Button>
          ))}
        </div>
        <hr className="w-full h-[1.5px] bg-primary mt-3" />

        {registerPage.page === 0 && (
          <form
            className="flex flex-col sm:grid sm:grid-cols-2 gap-3 mt-8"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              variant="outline-orange"
              title="Nome"
              {...register('name')}
              errorMensage={errors.name?.message}
            />
            <Input
              variant="outline-orange"
              title="Organização"
              {...register('organization')}
              errorMensage={errors.organization?.message}
            />
            <Input
              title="CPF/CNPJ"
              variant="outline-orange"
              type="text"
              placeholder="000.000.000-00"
              {...register('document')}
              errorMensage={errors.document?.message}
              onChange={(e) => {
                const formattedValue = maskHelper.cnpjCpf(e.target.value);
                setValue('document', maskHelper.numberCPFCNPJ(formattedValue));
              }}
            />
            <InputDate
              className="col-span-2 sm:col-span-1"
              title="Data de Nascimento"
              {...register('birth')}
              errorMensage={errors.birth?.message}
              onChange={(date: Date | null) => {
                setValue('birth', date || undefined);
              }}
            />

            <Input
              title="Telefone"
              variant="outline-orange"
              placeholder="(00) 00000-0000"
              {...register('phone')}
              errorMensage={errors.phone?.message}
              onChange={(e) => {
                const formattedPhone = maskHelper.phone(e.target.value);
                setValue('phone', maskHelper.number(formattedPhone));
              }}
            />
            <Input
              title="WhatsApp"
              variant="outline-orange"
              placeholder="(00) 00000-0000"
              {...register('whatsapp')}
              errorMensage={errors.whatsapp?.message}
              onChange={(e) => {
                const formattedWhatsapp = maskHelper.phone(e.target.value);
                setValue('whatsapp', maskHelper.number(formattedWhatsapp));
              }}
            />
            <Input
              title="E-mail"
              variant="outline-orange"
              {...register('email')}
              errorMensage={errors.email?.message}
            />
            <Input
              title="Cargo"
              variant="outline-orange"
              {...register('position')}
              errorMensage={errors.position?.message}
            />

            <div className="col-span-2 flex justify-center mt-6">
              <Button
                type="submit"
                actionType="button-loading"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                Salvar
              </Button>
            </div>
          </form>
        )}

        <div className="flex w-full gap-2 justify-center mt-9">
          {headerOutsideCreate.map((item) =>
            item.page === registerPage.page ? (
              <div className="h-3 w-3 rounded-full bg-primary" key={item.id} />
            ) : (
              <div className="h-3 w-3 rounded-full bg-gray-400" key={item.id} />
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RegisterContact;
