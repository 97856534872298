import React, {
  useContext,
  createContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { getInvoiceCreditBalance } from './api';

const InvoiceCreditContext = createContext({
  invoiceCreditBalance: 0,
  loadingInvoiceCreditBalance: false,
  loadInvoiceCreditBalance: () => {},
});

export const useInvoicedCreditContext = () => useContext(InvoiceCreditContext);

const InvoiceCreditContextProvider: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  const [invoiceCreditBalance, setInvoiceCreditBalance] = useState(0);
  const [loadingInvoiceCreditBalance, setLoadingInvoiceCreditBalance] =
    useState(false);

  const loadInvoiceCreditBalance = useCallback(() => {
    setLoadingInvoiceCreditBalance(true);
    getInvoiceCreditBalance()
      .then(
        ({ invoice_credit_balance: digitalInvoiceCreditBalanceResponse }) => {
          setInvoiceCreditBalance(digitalInvoiceCreditBalanceResponse);
        }
      )
      .finally(() => setLoadingInvoiceCreditBalance(false));
  }, []);
  useEffect(() => {
    loadInvoiceCreditBalance();
  }, [loadInvoiceCreditBalance]);

  const contextProviderValue = useMemo(
    () => ({
      invoiceCreditBalance,
      loadingInvoiceCreditBalance,
      loadInvoiceCreditBalance,
    }),
    [
      invoiceCreditBalance,
      loadingInvoiceCreditBalance,
      loadInvoiceCreditBalance,
    ]
  );

  return (
    <InvoiceCreditContext.Provider value={contextProviderValue}>
      {children}
    </InvoiceCreditContext.Provider>
  );
};
export default InvoiceCreditContextProvider;
