import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Modal,
  Spinner,
  Switch,
  SelectComponent,
} from '../../../../components';
import { InvoiceProps } from '../types';
import { ApiInvoice } from '../api';
import { ToastNotify } from '../../../../components/Toast/toast';
import { dictionaryError } from '../../../../helpers/utils.helper';
import maskHelper from '../../../../helpers/mask.helper';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  data: InvoiceProps | null;
}

const RegisterInvoice: React.FC<registerModalProps> = ({
  data,
  reloadTable,
  setShow,
  show,
}) => {
  const { notify } = ToastNotify();
  const [values, setValues] = useState<Omit<InvoiceProps, 'id'>>({
    name: data ? data?.name : '',
    description: data ? data?.description : '',
    value: data ? data?.value : 0,
    qty: data ? data?.qty : 0,
    is_active: data ? data?.is_active : false,
    is_trial: data ? data?.is_trial : undefined,
  });
  const [isLoading, setIsLoading] = useState(false);

  const trialOptions = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ];
  const getDefaultTrialOption = () =>
    trialOptions.find((option) => option.value === values.is_trial);

  const Submit = async () => {
    setIsLoading(true);
    if (data !== null) {
      const res = await ApiInvoice.put(values, data.id);
      if (res.id) {
        setIsLoading(false);
        notify({
          message: 'Nota fiscal atualizada com sucesso',
          type: 'Success',
        });
        reloadTable(`${Math.random()} update`);
        setShow(false);
      } else {
        setIsLoading(false);
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    } else {
      const res = await ApiInvoice.create(values);
      if (res.id) {
        setIsLoading(false);
        notify({ message: 'Plano criado com sucesso', type: 'Success' });
        reloadTable(`${Math.random()} create`);
        setShow(false);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValues({
      name: data?.name ?? '',
      description: data?.description ?? '',
      value: data?.value ?? 0,
      qty: data?.qty ?? 0,
      is_active: data?.is_active ?? false,
      is_trial: data?.is_trial,
    });
  }, [data, show]);
  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title={data ? 'Atualizar Planos' : 'Criar Planos'}
      size="medium"
      minHeight="min-h-[300px]"
    >
      <div className="flex flex-col">
        <Input
          variant="outline-orange"
          title="Nome"
          value={values.name}
          onChange={(e) => setValues({ ...values, name: e.target.value })}
        />
        <Input
          variant="outline-orange"
          title="Descrição"
          value={values.description}
          onChange={(e) =>
            setValues({ ...values, description: e.target.value })
          }
        />
        <Input
          variant="outline-orange"
          title="Valor"
          defaultValue={maskHelper.formatMoeda(values.value)}
          onChange={(e) => {
            e.target.value = maskHelper.formatCurrencyInput(e.target.value);
            setValues({
              ...values,
              value: Number(maskHelper.numberCurrencyDecimal(e.target.value)),
            });
          }}
        />
        <Input
          variant="outline-orange"
          title="Quantidade"
          type="number"
          value={values.qty}
          onChange={(e) =>
            Number(e.target.value) <= 0
              ? 0
              : setValues({ ...values, qty: Number(e.target.value) })
          }
        />
        <SelectComponent
          title="É Trial?"
          options={trialOptions}
          defaultValue={getDefaultTrialOption()}
          onChange={(e: any) => setValues({ ...values, is_trial: e.value })}
        />
        <p className="text-sm font-normal mb-2">Status</p>
        <Switch
          onChange={() =>
            setValues({ ...values, is_active: !values.is_active })
          }
          state={values.is_active}
        />

        <Button
          disabled={values.name.length === 0 || isLoading}
          variant="primary-strong"
          onClick={Submit}
          className="font-bold relative flex items-center justify-center mt-5 w-64 mx-auto"
        >
          Salvar
          {isLoading && (
            <div className="absolute right-0">
              <Spinner />
            </div>
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default RegisterInvoice;
