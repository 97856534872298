import { notify } from '../../../../components/Toast/toast';
import { setItem } from '../../../../helpers/storage.helper';
import { saveToken } from '../../../../helpers/token.helper';
import { dictionaryError } from '../../../../helpers/utils.helper';
import { ApiCompany } from '../api';

export const Matrix = [
  {
    id: 0,
    title: 'Dados Gerais',
    page: 0,
  },
  {
    id: 1,
    title: 'Endereço',
    page: 1,
  },
  {
    id: 2,
    title: 'Anexos',
    page: 2,
  },
  {
    id: 3,
    title: 'Redes Sociais',
    page: 3,
  },
  {
    id: 4,
    title: 'Liberar Menu',
    page: 4,
  },
  {
    id: 5,
    title: 'Usuário',
    page: 5,
  },
  {
    id: 6,
    title: 'Faturamento',
    page: 6,
  },
  {
    id: 7,
    title: 'Configurações',
    page: 7,
  },
  {
    id: 8,
    title: 'Empresas do grupo',
    page: 8,
  },
  {
    id: 9,
    title: 'Importar Clientes VM',
    page: 9,
  },
];

export const CreateUpdateBranch = [
  {
    id: 0,
    title: 'Dados Gerais',
    page: 0,
  },
  {
    id: 1,
    title: 'Endereço',
    page: 1,
  },
  {
    id: 2,
    title: 'Anexos',
    page: 2,
  },
  {
    id: 3,
    title: 'Redes Sociais',
    page: 3,
  },
  {
    id: 4,
    title: 'Usuário',
    page: 4,
  },
  {
    id: 5,
    title: 'Liberar Menu',
    page: 5,
  },
  {
    id: 6,
    title: 'Faturamento',
    page: 6,
  },
  {
    id: 7,
    title: 'Configurações',
    page: 7,
  },
  {
    id: 8,
    title: 'Importar Clientes VM',
    page: 8,
  },
];

export const accessCompanie = async (
  email: string,
  // eslint-disable-next-line no-unused-vars
  setState: (e: boolean) => void
) => {
  setState(true);
  const response = await ApiCompany.loginCompanies({
    email,
    password: 'IHthn4KY4jELxt9JPjOL',
  });
  if (response.token) {
    saveToken(response.token);
    setItem(
      'menu',
      response.menus.filter((item: any) => item !== null)
    );
    if (response.companie !== undefined)
      setItem(
        'generate_contract_opp',
        response.companie.is_generate_contract_opp
      );
    setState(false);
    window.location.href = '/';
  } else {
    notify({ message: dictionaryError(response), type: 'Error' });
    setState(false);
  }
  setState(false);
};
