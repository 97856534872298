import { baseApi, baseApiPrivate } from '../../../api/baseApi';
import { attachment } from '../../../components/Register/attachments';
import {
  Document,
  addressClientContract,
  adicionalDataContract,
  filterContract,
  financialFilterProps,
  payloadBackContracts,
  productContract,
  updateProductContractProps,
  updateServicePayment,
} from '../types';

async function getFinacialByContract(
  period: string,
  filters: financialFilterProps
): Promise<{ data: any[]; total: number }> {
  return baseApiPrivate({
    method: 'POST',
    body: filters,
    url: `companie/financial/filterAdvanced/${period}`,
  });
}

async function getContractHtml(id: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/contract/html/${id}`,
  });
}

async function getContractProducts(id: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/contract/product/${id}`,
  });
}

async function getContractInformation(id: number) {
  return baseApi({
    method: 'GET',
    url: `companie/contract/${id}`,
  });
}

async function getContractServices(id: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/contract/services/${id}`,
  });
}

async function getContract() {
  return baseApiPrivate({
    method: 'GET',
    url: 'companie/contract-custom',
  });
}

async function getContractById(id: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/contract/${id}`,
  });
}

async function sendEmail(companieId: number, contractId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/contract/sendMail/${companieId}/${contractId}`,
  });
}

async function getPDFContract(contractId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/contract/pdf/${contractId}`,
  });
}

async function reopenContract(contractID: number, galaxPayIdSubs: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/contract/subscription/reativate/${contractID}/${galaxPayIdSubs}`,
  });
}

async function changeDueDate(contractId: number, info: { payday: string }) {
  return baseApiPrivate({
    body: info,
    method: 'PUT',
    url: `companie/contract/subscription/${contractId}`,
  });
}

async function saveAsPDFContract(contractId: number) {
  return baseApiPrivate({
    responseFile: true,
    method: 'GET',
    url: `companie/contract/pdf/${contractId}`,
  });
}

async function filterContracts(
  filters: filterContract
): Promise<payloadBackContracts | string> {
  return baseApiPrivate({
    method: 'POST',
    body: filters,
    url: 'companie/contract/filterAdvanced',
  });
}

async function invoiceContract(contractId: number, firstDayPayment: string) {
  return baseApiPrivate({
    method: 'POST',
    body: { contract_id: contractId, first_pay_day_date: firstDayPayment },
    url: 'companie/contract/invoice',
  });
}

async function createContract(contract: { html: string }) {
  return baseApiPrivate({
    body: contract,
    method: 'POST',
    url: 'companie/contract-custom',
  });
}

async function createClientContract(information: any) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: 'companie/contract',
  });
}

async function updateClientContract(information: any, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/contract/personal/${id}`,
  });
}

async function updatePaymentResContract(information: any, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/contract/payment/${id}`,
  });
}

async function updateAdressContract(
  information: addressClientContract,
  id: number
) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/contract/address/${id}`,
  });
}

async function updateAdicionalInfoContract(
  information: adicionalDataContract,
  id: number
) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/contract/infos/${id}`,
  });
}

async function createProductServiceContract(information: productContract) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: `companie/contract/product`,
  });
}

async function updatePaymentServiceContract(
  information: updateServicePayment,
  id: number
) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/contract/services/${id}`,
  });
}

async function updateContract(contract: { html: string }, id: number) {
  return baseApiPrivate({
    body: contract,
    method: 'PUT',
    url: `companie/contract-custom/${id}`,
  });
}

async function cancelContract(id: number) {
  return baseApiPrivate({
    body: { status: 'Cancelado' },
    method: 'PUT',
    url: `companie/contract/status/${id}`,
  });
}

async function getPetContract(contractId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/contract/pets/${contractId}`,
  });
}

async function createPetContract(information: any) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: 'companie/contract/pets',
  });
}

async function updateProductContract(
  information: updateProductContractProps,
  id: number
) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/contract/product/${id}`,
  });
}

async function searchContract(
  skip: number,
  take: number,
  key: string
): Promise<payloadBackContracts | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/contract/autocomplete/find?q=${key}&skip=${skip}&take=${take}`,
  });
}

async function deleteProductContract(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/contract/product/${id}`,
  });
}

async function getContractAttachments(id: number): Promise<attachment[]> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/contract/attachment/${id}`,
  });
}

async function createContractAttachments(attachments: any) {
  return baseApiPrivate({
    method: 'POST',
    body: attachments,
    url: `companie/contract/attachment`,
  });
}

async function getPaymentMethodsContract(): Promise<
  | {
      id: string;
      displayName: string;
    }[]
  | string
> {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/companie/payment/methods`,
  });
}

async function createChargesContract(information: any) {
  return baseApiPrivate({
    method: 'POST',
    body: information,
    url: `companie/contract/charge`,
  });
}

async function deleteAttachments(id: number): Promise<boolean> {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/contract/attachment/${id}`,
  });
}

async function updateContractPromoter(contractId: number, information: any) {
  return baseApiPrivate({
    method: 'PUT',
    body: information,
    url: `companie/contract/promoter/${contractId}`,
  });
}

async function acceptContract(id: number, information: any) {
  return baseApi({
    body: information,
    method: 'POST',
    url: `companie/contract/accept/${id}`,
  });
}

async function requestSignatures(contractId: number, signers: any[]) {
  return baseApiPrivate({
    body: { signers },
    method: 'POST',
    url: `companie/contract/${contractId}/signatures/request`,
  });
}

async function getSignedContractDetails(contractId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/contract/${contractId}/signatures/request`,
  }) as Promise<Document>;
}

async function resendSignatures(contractId: number, publicId: string) {
  return baseApiPrivate({
    method: 'POST',
    url: `companie/contract/${contractId}/signatures/${publicId}/resendSignature`,
  });
}

async function createDependent(newDependent: any) {
  return baseApiPrivate({
    body: newDependent,
    method: 'POST',
    url: 'companie/contract/dependents',
  });
}

async function updateDependent(upDependent: any, id: number) {
  return baseApiPrivate({
    body: upDependent,
    method: 'PUT',
    url: `companie/contract/dependents/${id}`,
  });
}

async function deleteDependent(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/contract/dependents/${id}`,
  });
}

async function getDependents(id: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/contract/dependents/${id}`,
  });
}

async function createAnnotation(annotation: any) {
  return baseApiPrivate({
    body: annotation,
    method: 'POST',
    url: 'companie/contract/dependents/annotations',
  });
}

async function updateAnnotation(upAnnotation: any, id: number) {
  return baseApiPrivate({
    body: upAnnotation,
    method: 'PUT',
    url: `companie/contract/dependents/annotations/${id}`,
  });
}

async function getAnnotation(id: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/contract/dependents/annotations/${id}`,
  });
}

async function getTags(id: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/contract/dependents/tags/${id}`,
  });
}

async function createTags(body: any) {
  return baseApiPrivate({
    method: 'POST',
    url: `companie/contract/dependents/tags`,
    body,
  });
}

async function deleteTags(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/contract/dependents/tags/${id}`,
  });
}

async function getWhatsappTagByCompany() {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/tag`,
  });
}

export const ApiContract = {
  getContract,
  getPDFContract,
  getPetContract,
  getContractById,
  searchContract,
  getPaymentMethodsContract,
  getContractProducts,
  getContractInformation,
  getFinacialByContract,
  getContractServices,
  getContractAttachments,
  createContractAttachments,
  createProductServiceContract,
  changeDueDate,
  createChargesContract,
  getContractHtml,
  deleteAttachments,
  createPetContract,
  updateProductContract,
  acceptContract,
  deleteProductContract,
  sendEmail,
  saveAsPDFContract,
  invoiceContract,
  filterContracts,
  createContract,
  createClientContract,
  reopenContract,
  updateAdressContract,
  updateClientContract,
  updatePaymentResContract,
  updatePaymentServiceContract,
  updateAdicionalInfoContract,
  updateContractPromoter,
  updateContract,
  cancelContract,
  requestSignatures,
  getSignedContractDetails,
  resendSignatures,
  createAnnotation,
  updateAnnotation,
  createDependent,
  getDependents,
  deleteDependent,
  updateDependent,
  getAnnotation,
  getTags,
  createTags,
  deleteTags,
  getWhatsappTagByCompany,
};
