import React from 'react';
import { SelectComponent, TextArea } from '../../../../../../components';

// import { Container } from './styles';

const PaymentForm: React.FC = () => {
  const formPayment = [
    { label: 'À vista com 3% de desconto', value: 'in_sight' },
    { label: 'À vista com 5% de desconto', value: 'in_sight' },
    { label: 'Entrada de 20% + 9x sem juros no cartão', value: 'creditcard' },
    { label: '8x sem juros no cartão', value: 'creditcard' },
    { label: '9x sem juros no cartão', value: 'creditcard' },
  ];
  return (
    <div className="mt-5">
      <SelectComponent
        options={formPayment}
        title="Forma de pagamento"
        className="mb-2"
      />
      <TextArea title="Observação" />
      <hr className="w-full h-px bg-primary mt-20 mb-3" />
    </div>
  );
};

export default PaymentForm;
