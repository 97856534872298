export const mockupData = {
  type: 'Pacote nacional',
  orderNumber: 'ORD123456',
  hotels: [
    {
      name: 'Oceanview Resort',
      obs: 'Quarto com vista para o mar e café da manhã incluso',
    },
    {
      name: 'Mountain Lodge',
      obs: 'Próximo às trilhas de montanha, acesso gratuito ao spa',
    },
  ],
  formPayment: 'Cartão de Crédito',
  destiny: 'Paris, França',
  departureDate: '2024-11-10T10:30:00',
  arrivalDate: '2024-11-11T16:45:00',
  companieFlight: 'Air France',
  services: 'Translado, Guia turístico',
  serviceObs: 'Translado do aeroporto para o hotel incluso',
  ida: {
    date: '2024-11-10',
    flights: [
      {
        departureCity: 'São Paulo',
        departureDateTime: '2024-11-10T08:00:00',
        arrivalCity: 'Paris',
        arrivalDateTime: '2024-11-10T18:00:00',
      },
      {
        departureCity: 'São Paulo',
        departureDateTime: '2024-11-10T08:00:00',
        arrivalCity: 'Paris',
        arrivalDateTime: '2024-11-10T18:00:00',
      },
    ],
  },
  volta: {
    date: '2024-11-20',
    flights: [
      {
        departureCity: 'Paris',
        departureDateTime: '2024-11-20T12:00:00',
        arrivalCity: 'São Paulo',
        arrivalDateTime: '2024-11-20T22:00:00',
      },
    ],
  },
};

export const companieFlightsOption = [
  { label: 'LATAM Airlines', value: 'LA' },
  { label: 'Gol Linhas Aéreas', value: 'G3' },
  { label: 'Azul Linhas Aéreas', value: 'AD' },
  { label: 'Voepass Linhas Aéreas', value: '2Z' },
  { label: 'ITA Transportes Aéreos', value: 'IT' },
  { label: 'Avianca', value: 'AV' },
  { label: 'Copa Airlines', value: 'CM' },
  { label: 'Aerolineas Argentinas', value: 'AR' },
  { label: 'TAP Air Portugal', value: 'TP' },
  { label: 'Air France', value: 'AF' },
  { label: 'KLM', value: 'KL' },
  { label: 'Lufthansa', value: 'LH' },
  { label: 'Qatar Airways', value: 'QR' },
  { label: 'Emirates', value: 'EK' },
  { label: 'United Airlines', value: 'UA' },
  { label: 'Delta Air Lines', value: 'DL' },
];

export const apartmentTypesOptions = [
  { label: 'Apartamento Estúdio', value: 'studio' },
  { label: 'Apartamento de 1 Quarto', value: 'um-quarto' },
  { label: 'Apartamento de 2 Quartos', value: 'dois-quartos' },
  { label: 'Apartamento de Cobertura (Penthouse)', value: 'cobertura' },
  { label: 'Apartamento Garden', value: 'garden' },
  { label: 'Apartamento Duplex', value: 'duplex' },
  { label: 'Apartamento Triplex', value: 'triplex' },
  { label: 'Apartamento Loft', value: 'loft' },
  { label: 'Apartamento Flat', value: 'flat' },
  { label: 'Apartamento Kitnet', value: 'kitnet' },
  { label: 'Apartamento Studio', value: 'studio' },
  { label: 'Apartamento com Terraço', value: 'com-terraco' },
];
