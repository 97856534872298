import React from 'react';
import maskHelper from '../../../../../../helpers/mask.helper';

interface props {
  value: number;
  tax: number;
  variable: number;
  fix: number;
  type: 'total' | 'apartment';
}

const TotalTable: React.FC<props> = ({ fix, tax, value, variable, type }) => (
  <>
    {type === 'total' && (
      <tr className="flex flex-wrap md:grid grid-cols-5 w-full gap-2 md:gap-0 py-2 px-0 md:px-7 text-xs mt-3">
        <th className="flex gap-1 font-normal justify-end">
          Valor:{' '}
          <strong>{maskHelper.formatCurrencyInput(String(value))}</strong>
        </th>
        <th className="flex gap-1 font-normal justify-end">
          Taxas: <strong>{maskHelper.formatCurrencyInput(String(tax))}</strong>
        </th>
        <th className="flex gap-1 font-normal justify-end">
          Rav. variável:{' '}
          <strong>{maskHelper.formatCurrencyInput(String(variable))}</strong>
        </th>
        <th className="flex gap-1 font-normal justify-end">
          Rav. fixa:{' '}
          <strong>{maskHelper.formatCurrencyInput(String(fix))}</strong>
        </th>
        <th className="flex gap-1 font-normal justify-end">
          Total:{' '}
          <strong>
            {maskHelper.formatCurrencyInput(
              String(fix + tax + value + variable)
            )}
          </strong>
        </th>
      </tr>
    )}
    <tr className="grid grid-cols-5 w-full py-2 bg-gray-200 px-0 md:px-7 text-sm">
      <p />
      <p />
      <p />
      <p />
      <th className="text-center md:text-end font-semibold">
        Valor total{' '}
        {maskHelper.formatCurrencyInput(String(fix + tax + value + variable))}
      </th>
    </tr>
  </>
);
export default TotalTable;
