import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import moment from 'moment';
import { Card, Spinner } from '../../../../components';
import ArrowCard from '../../../../components/Card/arrowCard';
import { StrictModeDroppable } from '../../../../components/Droppable/StrictModeDroppable';
import IconDictionary from '../../../../components/Icons/icons';
import maskHelper from '../../../../helpers/mask.helper';
import { ApiOpportunity } from '../../api';
import { OpportunityProps, filterOpportunityProps } from '../../types';
import { getTextColorByOpacity } from '../../../../helpers/utils.helper';
import { useTheme } from '../../../main/hooks';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';

const onDragEnd = (result: any, columns: any, setColumns: any) => {
  if (!result.destination) return;
  const { source, destination } = result;
  if (result.source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.listOpportunity];
    const destItems = [...destColumn.listOpportunity];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        listOpportunity: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        listOpportunity: destItems,
      },
    });
    ApiOpportunity.updateOpportunityWorkflow(
      {
        funnel_step_id: Number(destination.droppableId),
      },
      Number(result.draggableId)
    );
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.listOpportunity];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        listOpportunity: copiedItems,
      },
    });
  }
};

interface plusCardProps {
  openWhatsApp: () => void;
  openActivity: () => void;
  openProducts: () => void;
  openClone: () => void;
  close: () => void;
  isLastCard: boolean;
}

function PlusOptionsCard({
  openActivity,
  openProducts,
  openWhatsApp,
  openClone,
  close,
  isLastCard,
  itemId,
}: plusCardProps & { itemId?: any }) {
  const openInNewTab = (id: any) => {
    const url = `${window.location.origin}/opportunity/update/${id}`;
    window.open(url, '_blank');
  };

  const plusOptions = [
    { icon: 'Tarefas', label: 'Tarefas', action: openActivity },
    {
      icon: 'Whatsapp',
      label: 'Enviar WhatsApp',
      action: openWhatsApp,
    },
    { icon: 'Serviços', label: 'Serviços', action: openProducts },
    { icon: 'Clonar', label: 'Clonar', action: openClone },
    {
      icon: 'SkipForwardTab',
      label: 'Abrir em Nova Aba',
      action: () => openInNewTab(itemId!),
    },
  ];
  return (
    <Card
      className={`z-40 px-4 absolute ${
        isLastCard ? 'bottom-8' : 'top-6'
      } right-0 w-48 h-32 border-solid border border-gray-200 drop-shadow-xl`}
    >
      <div className="flex flex-col justify-center gap-2 relative">
        <IconDictionary
          name="Excluir"
          className="absolute top-2 -right-6 cursor-pointer"
          onClick={close}
          size={16}
        />
        {plusOptions.map((options) => (
          <button
            key={options.label}
            type="button"
            className="flex gap-3"
            onClick={options.action}
          >
            <IconDictionary name={options.icon} size={16} />
            <p className="text-xs hover:scale-110">{options.label}</p>
          </button>
        ))}
      </div>
    </Card>
  );
}
/* eslint-disable no-unused-vars */
interface CardsProps {
  cardsOrder: any;
  showPlusOptions: number;
  filters: filterOpportunityProps;
  period: string;
  setStepInfo: (e: any) => void;
  setCardsOrder: React.Dispatch<React.SetStateAction<{}>>;
  setShowPlusOptions: (value: React.SetStateAction<number>) => void;
  setUpdateOpportunity: (
    value: React.SetStateAction<{
      open: boolean;
      opportunity: OpportunityProps | null;
    }>
  ) => void;
  setCardsAction: React.Dispatch<
    React.SetStateAction<{
      activity: {
        open: boolean;
        opportunityId: number;
      };
      product: {
        open: boolean;
        opportunity: OpportunityProps | null;
      };
      whatsapp: {
        open: boolean;
        opportunity: OpportunityProps | null;
      };
      clone: {
        open: boolean;
        opportunityId: number;
      };
    }>
  >;
}

const OpportunityStepCards: React.FC<CardsProps> = ({
  cardsOrder,
  showPlusOptions,
  filters,
  period,
  setShowPlusOptions,
  setCardsOrder,
  setUpdateOpportunity,
  setCardsAction,
  setStepInfo,
}) => {
  const accountProps = getTokenAccountInformation();
  const [isLoading, setIsLoading] = useState(-1);
  const getMonthDate = Intl.DateTimeFormat('br', { month: 'short' });
  const getDayDate = Intl.DateTimeFormat('br', { day: '2-digit' });
  const colorStatus = {
    circle: {
      Aberta: 'bg-gray-600  w-3 h-3 rounded-full',
      Perdida: 'bg-red w-3 h-3 rounded-full',
      Ganha: 'bg-green  w-3 h-3 rounded-full',
    },
    text: {
      Aberta: 'text-gray-600 text-xs font-medium',
      Perdida: 'text-red text-xs font-medium',
      Ganha: 'text-green text-xs font-medium',
    },
  };

  const getNewData = async (stepId: number, dataLength: number) => {
    setIsLoading(stepId);
    const res = await ApiOpportunity.getOpportunityByStep(period, {
      companieUserId: filters.companieUserId,
      opportunityId: filters.opportunityId?.value ?? null,
      order: filters.order,
      responses_win_lose_id: filters.responses_win_lose_id,
      salesChannelId: filters.salesChannelId,
      status: filters.status,
      funnelStepId: stepId,
      skip: dataLength,
      take: 30,
    });
    if (typeof res !== 'string') {
      cardsOrder[stepId] = {
        ...cardsOrder[stepId],
        listOpportunity: cardsOrder[stepId].listOpportunity.concat(res),
      };
    }
    setIsLoading(-1);
  };

  const divRefs = useRef<HTMLDivElement[]>([]);

  const handleScroll = (
    index: number,
    dataLength: number,
    total: number,
    stepId: number
  ) => {
    const div = divRefs.current[index];
    if (div.scrollTop + div.clientHeight === div.scrollHeight) {
      if (dataLength < total && isLoading === -1) {
        getNewData(stepId, dataLength);
      }
    }
  };

  const { theme } = useTheme(accountProps.companie_id);

  return (
    <DragDropContext
      onDragEnd={(result) => onDragEnd(result, cardsOrder, setCardsOrder)}
    >
      <div className="mb-5 lg:mb-0 w-full overflow-x-auto overflow-y-clip flex max-h-full min-h-[83vh] xl:min-h-[78vh]">
        {Object.entries(cardsOrder ?? {})
          .sort((a: any, b: any) => a[1].order - b[1].order)
          .map((step: any, index, allSteps) => (
            <div className="flex flex-col mt-2.5 w-[300px]" key={step.id}>
              {index + 1 === allSteps.length ? (
                <Card className="min-w-full p-4 min-h-[76px] rounded-lg flex-col text-sm relative">
                  <strong className="flex items-center gap-2 font-semibold truncate w-56">
                    {`${step[1].title.slice(0, 22)}${
                      step[1].title.length >= 22 ? '...' : ''
                    }`}
                    <IconDictionary
                      name="AlertCircle"
                      className="text-yellow cursor-pointer"
                      onClick={() => setStepInfo(step[1])}
                      size={20}
                    />
                  </strong>
                  <p className="text-xs">
                    {maskHelper.formatMoeda(step[1].total)}•{' '}
                    {step[1].totalPages} NEG(S)
                  </p>
                </Card>
              ) : (
                <ArrowCard className="min-w-full text-sm flex">
                  <strong className="flex items-center gap-2 font-semibold w-56">
                    {`${step[1].title.slice(0, 22)}${
                      step[1].title.length >= 22 ? '...' : ''
                    }`}
                    <IconDictionary
                      name="AlertCircle"
                      className="text-yellow cursor-pointer"
                      onClick={() => setStepInfo(step[1])}
                      size={20}
                    />
                  </strong>
                  <p className="text-xs">
                    {maskHelper.formatMoeda(String(step[1].total))} •{' '}
                    {step[1].totalPages} NEG(S)
                  </p>
                </ArrowCard>
              )}
              <StrictModeDroppable
                droppableId={String(step[1].id)}
                key={step[1].id}
              >
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="flex flex-col sm:max-h-full"
                  >
                    <div
                      className={classNames([
                        'max-h-full overflow-y-auto border-solid border-gray-300 px-0.5 pb-24',
                        index === 0 && 'pl-0 pr-0.5 border-r',
                        index !== 0 &&
                          index + 1 !== allSteps.length &&
                          'border-r',
                        index + 1 === allSteps.length && 'pr-0',
                      ])}
                      ref={(ref) => {
                        if (ref !== null) divRefs.current[index] = ref;
                      }}
                      onScroll={() =>
                        handleScroll(
                          index,
                          step[1].listOpportunity.length,
                          step[1].totalPages,
                          step[1].id
                        )
                      }
                    >
                      {step[1].listOpportunity.map(
                        (item: OpportunityProps, i: any) => (
                          <Draggable
                            key={item.id}
                            draggableId={String(item.id)}
                            index={i}
                          >
                            {/* eslint-disable-next-line no-shadow */}
                            {(dragBlocks) => (
                              <div
                                ref={dragBlocks.innerRef}
                                {...dragBlocks.draggableProps}
                                {...dragBlocks.dragHandleProps}
                                className="flex bg-white rounded p-2 mt-2 max-w-[298px]"
                              >
                                <div>
                                  <div
                                    className={`relative w-9 h-9 text-xs font-bold rounded flex flex-col items-center justify-center mb-px p-px ${
                                      item.whatsapp_total_message > 0
                                        ? 'bg-green'
                                        : 'bg-gray-400'
                                    }`}
                                  >
                                    {item.whatsapp_total_message > 0 && (
                                      <p className="absolute flex justify-center w-[15px] top-[-6px] right-[-6px] bg-gray rounded-lg border-4 border-black-500">
                                        {item.whatsapp_total_message}
                                      </p>
                                    )}
                                    {item.whatsapp_total_message > 0 ? (
                                      <IconDictionary
                                        name="Whatsapp"
                                        size={18}
                                        color="#fff"
                                      />
                                    ) : (
                                      item.title.slice(0, 2).toUpperCase()
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      color: getTextColorByOpacity(
                                        theme.color_hex
                                      ),
                                    }}
                                    className="w-9 h-9 bg-primary text-xs rounded flex flex-col items-center p-px"
                                  >
                                    <p className="font-medium">
                                      {getMonthDate
                                        .format(new Date(item.created_at))
                                        .toUpperCase()}
                                    </p>
                                    <strong className="text-sm">
                                      {getDayDate.format(
                                        new Date(item.created_at)
                                      )}
                                    </strong>
                                  </div>
                                </div>
                                <div className="mx-1.5 h-full w-full flex flex-col gap-1">
                                  <a href="#update" className="no-underline">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        setUpdateOpportunity({
                                          open: true,
                                          opportunity: item,
                                        })
                                      }
                                      className="text-sm w-fit hover:font-medium truncate max-w-[200px]"
                                    >
                                      {item.title}
                                    </button>
                                  </a>
                                  <div className="flex">
                                    <p className="text-xs text-gray-600 mr-1">
                                      Duração:{' '}
                                      {moment().diff(
                                        moment(item.created_at, 'YYYY/MM/DD'),
                                        'days'
                                      )}{' '}
                                      {moment().diff(
                                        moment(item.created_at, 'YYYY/MM/DD'),
                                        'days'
                                      ) === 1
                                        ? 'Dia'
                                        : 'Dias'}
                                    </p>

                                    <p className="text-xs text-gray-600">
                                      | {maskHelper.formatMoeda(item.total)}
                                    </p>
                                  </div>
                                  <div className="flex text-xs text-gray-600 gap-1">
                                    Status:{' '}
                                    <p
                                      className={colorStatus.text[item.status]}
                                    >
                                      {item.status}
                                    </p>
                                  </div>
                                  <div className="flex text-xs text-gray-600 gap-1">
                                    Resp:{' '}
                                    <p className="w-[165px] truncate font-medium">
                                      {item.companie_user.name}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex flex-col items-center justify-between gap-1.5">
                                  <div
                                    className={colorStatus.circle[item.status]}
                                  />
                                  <div className="relative">
                                    <div className="bg-primary w-5 h-5 flex items-center justify-center rounded-full text-white cursor-pointer">
                                      <IconDictionary
                                        name="Adicionar"
                                        size={16}
                                        onClick={() => {
                                          setShowPlusOptions(
                                            showPlusOptions === -1
                                              ? item.id
                                              : showPlusOptions === item.id
                                              ? -1
                                              : item.id
                                          );
                                          setCardsAction({
                                            activity: {
                                              open: false,
                                              opportunityId: item.id,
                                            },
                                            product: {
                                              open: false,
                                              opportunity: item,
                                            },
                                            whatsapp: {
                                              open: false,
                                              opportunity: item,
                                            },
                                            clone: {
                                              open: false,
                                              opportunityId: item.id,
                                            },
                                          });
                                        }}
                                      />
                                    </div>
                                    {showPlusOptions === item.id && (
                                      <PlusOptionsCard
                                        isLastCard={i >= 3}
                                        openClone={() =>
                                          setCardsAction((prev) => ({
                                            ...prev,
                                            clone: {
                                              open: true,
                                              opportunityId: item.id,
                                            },
                                          }))
                                        }
                                        close={() => setShowPlusOptions(-1)}
                                        openWhatsApp={() =>
                                          setCardsAction((prev) => ({
                                            ...prev,
                                            whatsapp: {
                                              open: true,
                                              opportunity: item,
                                            },
                                          }))
                                        }
                                        openActivity={() =>
                                          setCardsAction((prev) => ({
                                            ...prev,
                                            activity: {
                                              open: true,
                                              opportunityId:
                                                prev.activity.opportunityId,
                                            },
                                          }))
                                        }
                                        openProducts={() =>
                                          setCardsAction((prev) => ({
                                            ...prev,
                                            product: {
                                              open: true,
                                              opportunity:
                                                prev.product.opportunity,
                                            },
                                          }))
                                        }
                                        itemId={item.id}
                                      />
                                    )}
                                  </div>
                                  <div className="bg-gray-400 rounded-full w-5 h-5 cursor-pointer flex items-center justify-center">
                                    <IconDictionary
                                      name="Editar"
                                      size={14}
                                      onClick={() =>
                                        setUpdateOpportunity({
                                          open: true,
                                          opportunity: item,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      )}
                      {provided.placeholder}
                      {isLoading === step[1].id && (
                        <div className="flex justify-center my-2">
                          <Spinner />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </StrictModeDroppable>
            </div>
          ))}
      </div>
    </DragDropContext>
  );
};

export default OpportunityStepCards;
