/* eslint-disable no-unused-vars */
import React from 'react';
import maskHelper from '../../../../helpers/mask.helper';
import { paymentForm } from '../../utils';
import IconDictionary from '../../../../components/Icons/icons';

interface props {
  planInfo: {
    id: number;
    name: string;
    value: string;
    qty: number;
    is_trial: boolean;
  };
  isDisabled: boolean;
  done: boolean;

  selected: {
    name: string;
    value: string;
  };
  setValue: (key: any, e: any) => void;
  resetCreditCard: () => void;
  setSelected: (e: { name: string; value: string }) => void;
}

const SummaryPayment: React.FC<props> = ({
  planInfo,
  done,
  isDisabled,
  selected,
  setValue,
  resetCreditCard,
  setSelected,
}) => {
  const total = planInfo.value;

  return (
    <>
      <p className="text-xl text-green-400 mb-3 font-extrabold">
        Concluir Pedido
      </p>
      <div
        key={planInfo.id}
        className="w-full flex justify-between items-end text-xs text-gray-600 mb-2"
      >
        <div className="text-xs">
          <p>{planInfo.name}</p>
          <p>Quantidade de Usuários: {planInfo.qty}</p>
        </div>
        <p>{maskHelper.formatMoeda(planInfo.value)}</p>
      </div>
      <hr className="w-full h-px bg-gray-400 my-3" />
      <div className="w-full flex justify-between text-sm text-green-400 mb-3">
        <strong>Valor total</strong>
        <p>{maskHelper.formatMoeda(total)}</p>
      </div>
      {!planInfo.is_trial ? (
        <div className="flex gap-2">
          {paymentForm.map((plan) => (
            <button
              disabled={isDisabled || done}
              type="button"
              onClick={() => {
                if (plan.value === 'creditcard') {
                  setValue('card', {
                    cvv: '',
                    expiresAt: '',
                    holder: '',
                    number: '',
                  });
                  resetCreditCard();
                } else {
                  setValue('card', null);
                }
                setSelected(plan);
              }}
              key={plan.name}
              className={`disabled:bg-gray-200 disabled:border-gray-600 disabled:text-gray-600 disabled:opacity-80 border border-solid rounded transition-all duration-200  ${
                plan.value === selected.value
                  ? 'border-[#03a6c7] text-[#03a6c7]'
                  : 'border-gray-600'
              } ${
                isDisabled ? '' : 'hover:border-[#03a6c7] hover:text-[#03a6c7]'
              }  flex items-center gap-1 py-2.5 pl-3 pr-4 text-xs font-medium`}
            >
              <IconDictionary name={plan.name} />
              <p>{plan.name}</p>
            </button>
          ))}
        </div>
      ) : (
        <p className="text-xs">
          Você terá acesso a 30 dias gratuitos para testar a ferramenta Leadsim,
          não será pedido nenhuma forma de pagamento até o término do prazo.
          Após o cadastro você receberá um e-mail com as informações de login.
          Aproveite bem esta ferramenta fantástica que irá facilitar seu dia a
          dia, além de aumentar em muito seu faturamento e retenção de clientes.
        </p>
      )}
    </>
  );
};

export default SummaryPayment;
