import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { contact, contactCreateUpdate, updateformProps } from '../../../types';
import {
  Button,
  DeleteModal,
  Input,
  Modal,
  Table,
} from '../../../../../components';
import { useFetchContacts } from '../../../hooks';
import maskHelper from '../../../../../helpers/mask.helper';
import { ApiProvider } from '../../../api';
import { dictionaryError } from '../../../../../helpers/utils.helper';
import phoneValidate from '../../../../../helpers/validations/phone.validate';

/* eslint-disable no-unused-vars */
interface modalProps {
  setShowCreateEditContact: (e: boolean) => void;
  showCreateEditContact: boolean;
  contactDefault: null | contact;
  notify: (e: any) => void;
  setKey: (e: string) => void;
  providerId: number;
}

function ModalCreateUpdateContact({
  providerId,
  notify,
  contactDefault,
  setKey,
  setShowCreateEditContact,
  showCreateEditContact,
}: modalProps) {
  const defaultValue = {
    email: contactDefault?.email ?? '',
    id: contactDefault?.id ?? -1,
    name: contactDefault?.name ?? '',
    phone: contactDefault?.phone ?? '',
    provider_id: contactDefault?.provider_id ?? -1,
    sector: contactDefault?.sector ?? '',
    whatsapp: contactDefault?.whatsapp ?? '',
  };

  const schema = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    sector: yup.string(),
    phone: yup
      .string()
      .nullable()
      .min(10, 'Campo deve conter ao menos 10 dígitos')
      .max(11, 'Campo deve conter no máximo 11 dígitos')
      .test({
        message: 'Número inválido',
        test: (value) =>
          value ? phoneValidate.isValid(maskHelper.number(value)) : true,
      }),
    whatsapp: yup
      .string()
      .required('WhatsApp é obrigatório')
      .min(10, 'Campo deve conter entre 10 e 11 dígitos')
      .test({
        message: 'Número inválido',
        test: (value) =>
          value ? phoneValidate.isValid(maskHelper.number(value)) : false,
      }),
    email: yup
      .string()
      .email('E-mail inválido')
      .required('E-mail é obrigatório'),
  });
  const [contactData, setContactData] = useState<contact>(defaultValue);
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, setValue, formState } = useForm<contactCreateUpdate>({
    resolver: yupResolver(schema),
  });

  const Submit = async (e: contactCreateUpdate) => {
    if (contactDefault) {
      setIsLoading(true);
      const response = await ApiProvider.updateContact(e, contactDefault.id);
      if (response.id) {
        setIsLoading(false);
        notify({ message: 'Contato atualizado com sucesso!', type: 'Success' });
        setKey(`${Math.random()} update`);
        setShowCreateEditContact(false);
      } else {
        setIsLoading(false);
        notify({ message: dictionaryError(response), type: 'Error' });
      }
    } else {
      setIsLoading(true);
      const response = await ApiProvider.createContact({
        ...e,
        provider_id: providerId,
      });

      if (response.id) {
        setIsLoading(false);
        notify({ message: 'Contato criado com sucesso!', type: 'Success' });
        setKey(`${Math.random()} create`);
        setShowCreateEditContact(false);
      } else {
        setIsLoading(false);
        notify({ message: dictionaryError(response), type: 'Error' });
      }
    }
  };

  return (
    <Modal
      size="4xlarge"
      setIsOpen={setShowCreateEditContact}
      title={contactDefault ? 'Atualizar Contato' : 'Criar Contato'}
      isOpen={showCreateEditContact}
    >
      <form
        className="flex flex-col md:grid md:grid-cols-2 col-span-2 gap-3"
        onSubmit={handleSubmit(Submit)}
      >
        <Input
          id="name"
          title="Name / Razão Social"
          variant="outline-orange"
          value={contactData.name}
          onChange={(e) => {
            setValue('name', e.target.value);
            setContactData({ ...contactData, name: e.target.value });
          }}
          errorMensage={formState.errors.name?.message}
        />
        <Input
          id="sector"
          title="Setor / Ocupação"
          variant="outline-orange"
          value={contactData.sector}
          onChange={(e) => {
            setValue('sector', e.target.value);
            setContactData({ ...contactData, sector: e.target.value });
          }}
          errorMensage={formState.errors.sector?.message}
        />
        <Input
          title="Telefone"
          variant="outline-orange"
          max={15}
          type="text"
          value={contactData.phone}
          onChange={(e) => {
            setValue('phone', maskHelper.numberPhone(e.target.value));
            setContactData({
              ...contactData,
              phone: maskHelper.phone(e.target.value),
            });
          }}
          errorMensage={formState.errors.phone?.message}
        />
        <Input
          title="WhatsApp"
          variant="outline-orange"
          max={15}
          type="text"
          value={contactData.whatsapp}
          onChange={(e) => {
            setValue('whatsapp', maskHelper.numberPhone(e.target.value));
            setContactData({
              ...contactData,
              whatsapp: maskHelper.phone(e.target.value),
            });
          }}
          errorMensage={formState.errors.whatsapp?.message}
        />
        <Input
          id="email"
          title="E-mail"
          variant="outline-orange"
          value={contactData.email}
          onChange={(e) => {
            setValue('email', e.target.value);
            setContactData({ ...contactData, email: e.target.value });
          }}
          errorMensage={formState.errors.email?.message}
        />
        <div className="col-span-2 flex justify-center mt-6">
          <Button
            type="submit"
            actionType="button-loading"
            isLoading={isLoading}
            disabled={isLoading}
          >
            Salvar
          </Button>
        </div>
      </form>
    </Modal>
  );
}
const Contacts: React.FC<updateformProps> = ({ id, nextPage, notify }) => {
  const [contactData, setContactData] = useState<contact | null>(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [key, setKey] = useState('key');
  const [showCreateEditContacts, setShowCreateEditContacts] = useState(false);

  const { contactList, isLoadingContactList } = useFetchContacts(key, id);

  const columnTableConta = [
    {
      name: 'Nome',
      key: 'name',
      selector: (row: contact) => row.name,
    },
    {
      name: 'Setor',
      key: 'sector',
      selector: (row: contact) => row.sector,
    },
    {
      name: 'Telefone',
      key: 'phone',
      selector: (row: contact) => row.phone,
      cell: (row: contact) => row.phone && maskHelper.phone(row.phone),
    },
    {
      name: 'WhatsApp',
      key: 'whatsApp',
      selector: (row: contact) => row.whatsapp,
      cell: (row: contact) => row.whatsapp && maskHelper.phone(row.whatsapp),
    },
    {
      name: 'E-mail',
      key: 'email',
      selector: (row: contact) => row.email,
    },
    {
      name: '',
      cell: (row: contact) => (
        <div>
          <CloseOutline
            size={20}
            color="#DA3C3C"
            className="cursor-pointer"
            onClick={() => {
              setOpenDelete(true);
              setContactData(row);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <ModalCreateUpdateContact
        providerId={id}
        notify={notify}
        contactDefault={contactData}
        setKey={setKey}
        setShowCreateEditContact={setShowCreateEditContacts}
        showCreateEditContact={showCreateEditContacts}
      />
      {contactData && (
        <DeleteModal
          apiFunction={ApiProvider.deleteContact}
          notify={notify}
          type="Contato"
          article="O"
          close={() => setOpenDelete(false)}
          id={contactData.id}
          name={contactData.name}
          open={openDelete}
          updateTable={() => setKey(`${Math.random()}'delete'`)}
        />
      )}

      <div className="col-span-2 flex flex-col min-h-64">
        <Button
          className="mt-8"
          variant="primary-strong"
          onClick={() => {
            setShowCreateEditContacts(true);
            setContactData(null);
          }}
          actionType="button-add"
        >
          Adicionar Contato
        </Button>
        <div className="w-full mb-8">
          <Table
            columns={columnTableConta}
            data={contactList}
            isLoading={isLoadingContactList}
            onRowClick={(e: contact) => {
              setShowCreateEditContacts(true);
              setContactData(e);
            }}
          />
        </div>
        <div className="col-span-2 flex justify-center mt-6">
          <Button type="submit" actionType="button-loading" onClick={nextPage}>
            Continuar
          </Button>
        </div>
      </div>
    </>
  );
};

export default Contacts;
