import React from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import Modal from '../Modal/Modal';
import Button from '../Button/button';
import { modalAccountNotActive } from '../../hooks/globalAtom';
import AlertCard from '../Card/alertCard';

const AlertAccountNotActive: React.FC = () => {
  const [modal, setShowModal] = useAtom(modalAccountNotActive);
  const navigate = useNavigate();

  const navigateVerifyAccount = () => {
    navigate('bank-account');
    setShowModal(false);
  };

  return (
    <Modal
      title="Atenção!"
      isOpen={modal}
      setIsOpen={(e) => setShowModal(e)}
      minHeight="min-h-[200px]"
    >
      <div className="flex flex-col gap-3">
        <AlertCard message={<p>Sua conta bancária possui pendências</p>} />

        <p className="mt-3 mb-5 text-xs">
          Para utilizar esta funcionalidade, é necessário que sua conta no banco
          esteja ativa e possua os documentos aprovados. Verifique se todos os
          dados e documentos enviados estão corretos. Caso estejam, por favor,
          aguarde a aprovação do banco. Se precisar de assistência, entre em
          contato com nosso suporte.
        </p>
        <Button onClick={navigateVerifyAccount} className="w-full">
          Verificar conta no banco
        </Button>
      </div>
    </Modal>
  );
};

export default AlertAccountNotActive;
