import React, { useState } from 'react';
import { Button } from '../../../../../../components';
import InsertTour from './insertTour';

const CheckBoxTitle = ({ title }: { title: string }) => (
  <div className="flex gap-2">
    <input type="checkbox" className="accent-primary" />
    <p className="text-xs">{title}</p>
  </div>
);

const Inclusion: React.FC = () => {
  const [showInsertTour, setShowInsertTour] = useState(false);
  const [tour, setTour] = useState([
    'City tour + almoço e parques',
    'Jantar acompanhante',
  ]);

  const handleInsertTour = (name: string) => {
    const temp = tour;
    temp.push(name);
    setTour(temp);
  };

  const handleDeleteApartment = (elem: string) => {
    setTour(tour.filter((item) => item !== elem));
  };

  const inclusionOptions = [
    { id: 1, title: 'Transfer in/out' },
    { id: 2, title: 'Seguro viagem' },
    { id: 3, title: 'Bagagem despachada' },
    { id: 4, title: 'Transfer in' },
    { id: 5, title: 'Transfer out' },
    { id: 6, title: 'City tour' },
  ];
  return (
    <div className="flex flex-col gap-3 text-sm mt-5 pl-2">
      <InsertTour
        setShow={setShowInsertTour}
        show={showInsertTour}
        insert={handleInsertTour}
      />
      <p className="font-medium">Inclusões</p>
      <div className="grid grid-cols- 2 sm:grid-cols-3 w-full lg:w-1/2 gap-y-2">
        {inclusionOptions.map((item) => (
          <CheckBoxTitle title={item.title} />
        ))}
      </div>
      <div className="flex gap-2 items-center mt-3">
        <p className="font-medium">Passeios</p>
        <hr className="w-full h-px bg-gray-400" />
      </div>

      {tour.map((elem) => (
        <div className="flex gap-2">
          <p className="text-xs">{elem}</p>
          <button
            onClick={() => handleDeleteApartment(elem)}
            type="button"
            className="w-4 h-4 border border-solid border-gray-400 hover:scale-110 rounded-full flex items-center justify-center"
          >
            ⨯
          </button>
        </div>
      ))}

      <Button
        onClick={() => setShowInsertTour(true)}
        variant="primary-strong"
        className="w-48 mt-3"
      >
        Adicionar passeio
      </Button>
    </div>
  );
};

export default Inclusion;
