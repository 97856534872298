import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { date, number, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Loading from 'react-loading';
import {
  Button,
  Input,
  Modal,
  SelectComponent,
  TextArea,
} from '../../../components';
import { ModalProps } from '../../../components/Modal/Modal';
import { ApiExpenses } from '../api';
import { EconomicActivity } from '../../../types';
import maskHelper from '../../../helpers/mask.helper';
import { ToastNotify } from '../../contract/components/dependents/Toast/toastnotify';

export interface RequestServiceInvoiceModalProps
  extends Omit<ModalProps, 'title' | 'children'> {
  account: any;
}

const booleanOptions = [
  { label: 'Sim', value: 1 },
  { label: 'Não', value: 0 },
];

const checkPercentageType = (value: string | undefined | null) => {
  if (!value) return false;
  const valueAsNumber = Number(value);
  return (
    !Number.isNaN(valueAsNumber) && valueAsNumber >= 0 && valueAsNumber <= 100
  );
};
const schema = object().shape({
  emissionDate: date().required(),
  servicesAmount: string().matches(
    /^R\$ \d{1,3}(\.\d{3})*,\d{2}$/,
    'O valor da nota fiscal deve ser um valor válido'
  ),
  issAmountWithheldPercentage: string()
    .nullable()
    .test(
      'is-valid-percentage',
      'O valor deve ser uma porcentagem válida entre 1 e 100',
      checkPercentageType
    ),
  cofinsAmountWithheldPercentage: string().test(
    'is-valid-percentage',
    'O valor deve ser uma porcentagem válida entre 1 e 100',
    checkPercentageType
  ),
  inssAmountWithheldPercentage: string().test(
    'is-valid-percentage',
    'O valor deve ser uma porcentagem válida entre 1 e 100',
    checkPercentageType
  ),
  irAmountWithheldPercentage: string().test(
    'is-valid-percentage',
    'O valor deve ser uma porcentagem válida entre 1 e 100',
    checkPercentageType
  ),
  csllAmountWithheldPercentage: string().test(
    'is-valid-percentage',
    'O valor deve ser uma porcentagem válida entre 1 e 100',
    checkPercentageType
  ),
  pisAmountWithheldPercentage: string().test(
    'is-valid-percentage',
    'O valor deve ser uma porcentagem válida entre 1 e 100',
    checkPercentageType
  ),
  customerServiceId: number().required(),
});
const getPercentageValue = (totalValue: any, percentage: any) =>
  maskHelper.formatMoeda((+totalValue * +percentage) / 100);
const RequestServiceInvoiceModal: FC<RequestServiceInvoiceModalProps> = ({
  setIsOpen,
  ...props
}) => {
  const [loadingRequestServiceInvoice, setLoadingRequestServiceInvoice] =
    useState(false);
  const [economicActivities, setEconomicActivities] = useState<
    EconomicActivity[]
  >([]);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    // setError,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      emissionDate: new Date().toISOString().slice(0, 10),
      servicesAmount: maskHelper
        .formatMoeda(+props.account.value)
        ?.replace(/\s/, ' '),
      canRetainISS: false,
      issAmountWithheldPercentage: 0,
      cofinsAmountWithheldPercentage: 0,
      inssAmountWithheldPercentage: 0,
      irAmountWithheldPercentage: 0,
      csllAmountWithheldPercentage: 0,
      pisAmountWithheldPercentage: 0,
      customerServiceId: 0,
      additionalInformation: '',
    },
    resolver: yupResolver(schema),
  });
  const canRetainISS = watch('canRetainISS');
  // const issAmountWithheld = watch('issAmountWithheld');
  const cofinsAmountWithheldPercentage = watch(
    'cofinsAmountWithheldPercentage'
  );
  const inssAmountWithheldPercentage = watch('inssAmountWithheldPercentage');
  const irAmountWithheldPercentage = watch('irAmountWithheldPercentage');
  const csllAmountWithheldPercentage = watch('csllAmountWithheldPercentage');
  const pisAmountWithheldPercentage = watch('pisAmountWithheldPercentage');

  const servicesAmount = Number(
    watch('servicesAmount')
      ?.replaceAll('.', '')
      .replace(',', '.')
      .replace(/R\$\s/, '')
  );

  const getClientEconomicActivities = useCallback(() => {
    ApiExpenses.getClientEconomicActivities({
      id: props.account.client_id ?? props.account.contract?.client_id,
    } as any).then((response) => {
      setEconomicActivities(response.data);
      const mainService = response.data.find((e) => e.isDefault);
      if (mainService) {
        setValue('customerServiceId', mainService.id);
      }
    });
  }, []);

  useEffect(() => {
    getClientEconomicActivities();
  }, [getClientEconomicActivities]);

  const economicActivitiesOptions = useMemo(
    () =>
      economicActivities.map((economicActivity) => ({
        label: economicActivity.cityServiceDescription!,
        value: economicActivity.id,
      })),
    [economicActivities]
  );
  const { notify } = ToastNotify();
  const onSubmit = async (data: any) => {
    const serviceAmountAsNumber = Number(
      data.servicesAmount
        .replaceAll('.', '')
        .replace(',', '.')
        .replace(/R\$\s/, '')
    );
    const serviceInvoiceData = {
      customerId: props.account.client_id ?? props.account.contract?.client_id,
      cofinsAmountWithheld: Number(
        getPercentageValue(
          serviceAmountAsNumber,
          +data.cofinsAmountWithheldPercentage
        )
          .replaceAll('.', '')
          .replace(',', '.')
          .replace(/R\$\s/, '')
      ),
      cityServiceCode: economicActivities.find(
        (e) => e.id === +data.customerServiceId
      )?.cityServiceCodeId,
      customerServiceId: data.customerServiceId,
      inssAmountWithheld: Number(
        getPercentageValue(
          serviceAmountAsNumber,
          +data.inssAmountWithheldPercentage
        )
          .replaceAll('.', '')
          .replace(',', '.')
          .replace(/R\$\s/, '')
      ),
      irAmountWithheld: Number(
        getPercentageValue(
          serviceAmountAsNumber,
          +data.irAmountWithheldPercentage
        )
          .replaceAll('.', '')
          .replace(',', '.')
          .replace(/R\$\s/, '')
      ),
      csllAmountWithheld: Number(
        getPercentageValue(
          serviceAmountAsNumber,
          +data.csllAmountWithheldPercentage
        )
          .replaceAll('.', '')
          .replace(',', '.')
          .replace(/R\$\s/, '')
      ),
      pisAmountWithheld: Number(
        getPercentageValue(
          serviceAmountAsNumber,
          +data.pisAmountWithheldPercentage
        )
          .replaceAll('.', '')
          .replace(',', '.')
          .replace(/R\$\s/, '')
      ),
      servicesAmount: serviceAmountAsNumber,
      issRate: +data.issAmountWithheldPercentage,
      issTaxAmount: Number(
        getPercentageValue(
          data.servicesAmount ?? 0,
          data.issAmountWithheldPercentage ?? 0
        )
          .replaceAll('.', '')
          .replace(',', '.')
          .replace(/R\$\s/, '')
      ),
      additionalInformation: data.additionalInformation,
    };
    setLoadingRequestServiceInvoice(true);
    const response = await ApiExpenses.requestServiceInvoice(
      props.account.id,
      serviceInvoiceData
    );

    if (response?.ok) {
      notify({
        message: 'Nota fiscal emitida com sucesso',
        type: 'Success',
      });
      setIsOpen(false);
    } else {
      notify({
        message:
          typeof response === 'string' ? response : 'Houve um erro inesperado.',
        type: 'Error',
      });
    }

    setLoadingRequestServiceInvoice(false);
  };
  return (
    <Modal
      title="Emitir nota fiscal"
      size="4xlarge"
      minHeight="min-h-[735px]"
      setIsOpen={setIsOpen}
      {...props}
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('emissionDate')}
          variant="outline-orange"
          title="Data de emissão"
          errorMensage={errors.emissionDate?.message}
          type="date"
        />
        <div>
          <Controller
            name="customerServiceId"
            control={control}
            render={({ field }) => (
              <SelectComponent
                title="Serviço"
                {...field}
                // options={LEGAL_NATURE_OPTIONS}
                options={economicActivitiesOptions}
                value={economicActivitiesOptions.find(
                  (o) => o.value === field.value
                )}
                onChange={(option: { value: string }) =>
                  field.onChange(option.value)
                }
                errorMensage={errors.customerServiceId?.message as string}
              />
            )}
          />
        </div>
        <TextArea
          title="Descrição do serviço:"
          {...register('additionalInformation')}
        />

        <div className="flex gap-2 items-end relative">
          <Controller
            name="canRetainISS"
            control={control}
            render={({ field }) => (
              <SelectComponent
                title="Cliente deve reter ISS?"
                {...field}
                options={booleanOptions}
                value={booleanOptions.find((o) => o.value === +field.value)}
                onChange={(option: { value: string }) =>
                  field.onChange(option.value)
                }
              />
            )}
          />

          <Input
            {...register('issAmountWithheldPercentage')}
            errorMensage={errors.issAmountWithheldPercentage?.message as string}
            variant="outline-orange"
            title="Alíquota ISS"
            icon={<>%</>}
            min={0}
            max={100}
            type="string"
            step={0.01}
            disabled={!canRetainISS}
            className="mb-1.5"
          />
        </div>
        <Controller
          name="servicesAmount"
          control={control}
          render={({ field }) => (
            <Input
              value={field.value}
              variant="outline-orange"
              title="Valor da nota fiscal (R$)"
              onChange={(e) => {
                const formattedValue = maskHelper.formatCurrencyInput(
                  e.target.value
                );
                e.target.value = formattedValue;
                field.onChange(formattedValue);
              }}
              type="string"
              errorMensage={errors.servicesAmount?.message as string}
              step={0.01}
            />
          )}
        />
        <div className="grid grid-cols-2 gap-2">
          <Input
            {...register('cofinsAmountWithheldPercentage')}
            errorMensage={
              errors.cofinsAmountWithheldPercentage?.message as string
            }
            variant="outline-orange"
            title="COFINS"
            icon={<>%</>}
            min={0}
            max={100}
            type="string"
            step={0.01}
          />
          <Input
            variant="outline-orange"
            title="Valor COFINS"
            icon={<>R$</>}
            value={getPercentageValue(
              servicesAmount,
              cofinsAmountWithheldPercentage
            )}
            readOnly
            type="string"
            step={0.01}
          />
          <Input
            {...register('csllAmountWithheldPercentage')}
            errorMensage={
              errors.csllAmountWithheldPercentage?.message as string
            }
            variant="outline-orange"
            title="CSLL"
            icon={<>%</>}
            min={0}
            max={100}
            type="string"
            step={0.01}
          />
          <Input
            variant="outline-orange"
            title="Valor CSLL"
            value={getPercentageValue(
              servicesAmount,
              csllAmountWithheldPercentage
            )}
            icon={<>R$</>}
            type="string"
            step={0.01}
          />
          <Input
            {...register('inssAmountWithheldPercentage')}
            errorMensage={
              errors.inssAmountWithheldPercentage?.message as string
            }
            variant="outline-orange"
            title="INSS"
            icon={<>%</>}
            min={0}
            max={100}
            type="string"
            step={0.01}
          />
          <Input
            variant="outline-orange"
            value={getPercentageValue(
              servicesAmount,
              inssAmountWithheldPercentage
            )}
            title="Valor INSS"
            icon={<>R$</>}
            type="string"
            step={0.01}
          />
          <Input
            {...register('irAmountWithheldPercentage')}
            errorMensage={errors.irAmountWithheldPercentage?.message as string}
            variant="outline-orange"
            title="IR"
            icon={<>%</>}
            min={0}
            max={100}
            type="string"
            step={0.01}
          />
          <Input
            value={getPercentageValue(
              servicesAmount,
              irAmountWithheldPercentage
            )}
            variant="outline-orange"
            title="Valor IR"
            icon={<>R$</>}
            type="string"
            step={0.01}
          />
          <Input
            {...register('pisAmountWithheldPercentage')}
            errorMensage={errors.pisAmountWithheldPercentage?.message as string}
            variant="outline-orange"
            title="PIS"
            icon={<>%</>}
            min={0}
            max={100}
            type="string"
            step={0.01}
          />
          <Input
            value={getPercentageValue(
              servicesAmount,
              pisAmountWithheldPercentage
            )}
            variant="outline-orange"
            title="Valor PIS"
            icon={<>R$</>}
            type="string"
            step={0.01}
          />
        </div>
        <Button
          type="submit"
          actionType="button-loading"
          isLoading={loadingRequestServiceInvoice}
          disabled={loadingRequestServiceInvoice}
          className="flex items-center justify-center w-[200px] mx-auto"
        >
          Emitir nota fiscal
        </Button>
      </form>
    </Modal>
  );
};

export default RequestServiceInvoiceModal;
