/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import moment from 'moment';
import { useAtom } from 'jotai';
import { contractProps } from '../types';
import { Button, InputDate, Modal } from '../../../components';
import { ApiContract } from '../api';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';
import IconDictionary from '../../../components/Icons/icons';
import SummaryTable from './createUpdate/payment/sumaryTable';
import maskHelper from '../../../helpers/mask.helper';
import { modalAccountNotActive } from '../../../hooks/globalAtom';

interface props {
  show: boolean;
  setShow: (e: boolean) => void;
  contract: contractProps;
  reloadTable: () => void;
}
function ModalPaymentDay({
  setShow,
  reloadTable,
  closeModalMain,
  show,
  contractId,
}: {
  show: boolean;
  contractId: number;
  setShow: (e: boolean) => void;
  reloadTable: () => void;
  closeModalMain: () => void;
}) {
  const { notify } = ToastNotify();
  const [, setModalNotActive] = useAtom(modalAccountNotActive);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState('');

  const invoice = async () => {
    setIsLoading(true);
    const res = await ApiContract.invoiceContract(contractId, date);
    if (res.subscription) {
      notify({ message: 'Contrato faturado com sucesso!', type: 'Success' });
      reloadTable();
      setShow(false);
      closeModalMain();
    } else if (res.includes('Erros de validação.')) {
      // Conta no banco com problemas
      notify({ message: dictionaryError(res), type: 'Error' });
      setModalNotActive(true);
    } else {
      notify({ message: dictionaryError(res), type: 'Error' });
    }
    setIsLoading(false);
  };
  return (
    <Modal
      setIsOpen={setShow}
      isOpen={show}
      size="medium"
      minHeight="min-h-[350px]"
      title="Informe a data do primeiro faturamento!"
    >
      <InputDate
        minDate={moment().toDate()}
        className="mt-5"
        onChange={(e: Date) =>
          setDate(maskHelper.formatDateYMD(e.toLocaleString('pt-br')))
        }
        title="Data Pagamento"
      />
      <Button
        className="mx-auto mt-5 disabled:opacity-50"
        onClick={invoice}
        disabled={isLoading || date.length === 0}
        isLoading={isLoading}
        actionType="button-loading"
      >
        Confirmar
      </Button>
    </Modal>
  );
}

const InvoiceContract: React.FC<props> = ({
  contract,
  setShow,
  show,
  reloadTable,
}) => {
  const [showModaldate, setShowModaldate] = useState(false);
  const allowInvoice =
    contract.resp_financial_document === null ||
    contract.resp_financial_address === null ||
    contract.resp_financial_number === null ||
    contract.resp_financial_province === null ||
    contract.resp_financial_city === null ||
    contract.resp_financial_state === null ||
    contract.resp_financial_zipcode === null;
  return (
    <Modal
      title="Faturar Contrato"
      isOpen={show}
      setIsOpen={setShow}
      size="2xlarge"
      minHeight={allowInvoice ? 'min-h-[250px]' : 'min-h-[350px]'}
    >
      <ModalPaymentDay
        closeModalMain={() => setShow(false)}
        contractId={contract.id}
        reloadTable={reloadTable}
        setShow={setShowModaldate}
        show={showModaldate}
      />
      {allowInvoice ? (
        <div className="flex flex-col items-center">
          <div className="rounded px-3 py-5 flex items-center gap-3 w-full h-16 border border-dashed border-red mb-5">
            <IconDictionary
              size={28}
              name="AlertTriangle"
              className="text-red"
            />
            <p className="text-sm">
              Para faturar o contrato, favor completar as informações
              necessárias do cliente e do responsável financeiro.
            </p>
          </div>
          <Button className="w-64 mt-3" onClick={() => setShow(false)}>
            Ok, entendi!
          </Button>
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-3 text-sm border-top w-full border-primary border-solid">
            <div className="flex flex-col col-span-2 max-h-[300px] overflow-y-auto">
              <SummaryTable
                products={contract.contract_products}
                showDueDate={false}
              />
            </div>
          </div>
          <p className="mt-7 mb-4 text-sm font-semibold">
            Deseja realmente faturar este contrato?
          </p>
          <div className="flex w-full gap-3 justify-center">
            <Button
              variant="outline-primary"
              actionType="button-simple"
              className="w-64"
              onClick={() => setShow(false)}
            >
              Não
            </Button>
            <Button
              className="disabled:opacity-50"
              variant="primary-strong"
              actionType="button-loading"
              onClick={() => setShowModaldate(true)}
              disabled={allowInvoice}
            >
              Sim
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default InvoiceContract;
