import React from 'react';

import IconDictionary from '../../../../components/Icons/icons';
import maskHelper from '../../../../helpers/mask.helper';
import { Button } from '../../../../components';

interface Props {
  data: any[];
  onSelect: (data: any) => void;
}

export const Cards: React.FC<Props> = ({ data, onSelect }) => {
  const format = (phone: string) =>
    maskHelper.phone(
      `${phone.slice(2, 11).substring(0, 2)}9${phone.slice(4, 12)}`
    );

  return (
    <div className="flex justify-around gap-3 flex-wrap">
      {data.map((instance) => (
        <div className="flex flex-col items-center p-2 w-[230px] bg-gray-200 rounded-md">
          <div className="flex border-2">
            <div className="w-[50px]">
              <IconDictionary color="#ff6700" name="WhatsappSquare" size={30} />
            </div>
            <div className="flex flex-col">
              <p className="text-sm mt-[3px] mb-[8px] font-semibold">
                Número Whatsapp
              </p>
              <p className="mb-[10px] text-sm">
                {maskHelper.phone(instance.whatsapp_number)}
              </p>
              <p
                className={`mb-[10px] text-sm ${
                  instance.status === 'Conectada' && 'text-green'
                }`}
              >
                {instance.status}
              </p>
            </div>
          </div>
          <Button
            className="font-medium w-50"
            actionType="button-reconnect"
            onClick={() => onSelect(instance)}
          >
            Selecionar
          </Button>
        </div>
      ))}
    </div>
  );
};
