import classNames from 'classnames';
import React, { ComponentPropsWithRef, forwardRef, ReactNode } from 'react';

type TextAreaProps = ComponentPropsWithRef<'textarea'>;

interface StyleProps {
  title?: string;
  classNamediv?: string;
  errorMensage?: string;
  variant?: 'orange' | 'black';
  icon?: ReactNode;
}

type Props = TextAreaProps & StyleProps;
const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    { title, classNamediv, errorMensage, variant = 'orange', icon, ...props },
    ref
  ) => (
    <div className={`w-full ${classNamediv}`}>
      <p
        className={
          variant === 'orange' ? 'text-sm mb-1' : 'text-sm mb-1 font-semibold'
        }
      >
        {title}
      </p>
      <div
        className={classNames([
          `text-xs ${
            variant === 'orange' ? 'border-[#ddd]' : 'border-black'
          } focus:border-primary border border-solid w-full h-20 rounded-lg p-3 resize-none disabled:bg-gray-200 relative`,
          props.className,
        ])}
      >
        {icon}{' '}
        <textarea
          onKeyUp={props.onKeyUp}
          onMouseUp={props.onMouseUp}
          className="min-w-full h-full resize-none"
          placeholder={props.placeholder}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          value={props.value}
          ref={ref}
        />
      </div>

      <p className="text-red text-xs absolute">{errorMensage}</p>
    </div>
  )
);

export default TextArea;
