import React from 'react';
import { useDropzone } from 'react-dropzone';
import EmojiPicker from 'emoji-picker-react';

import IconDictionary from '../../../../components/Icons/icons';
import { Card, Input, Modal, Spinner } from '../../../../components';
import { ApiOpportunity } from '../../api';
import { notify } from '../../../../components/Toast/toast';
import { dictionaryError } from '../../../../helpers/utils.helper';

interface Props {
  contact: any;
  instanceId: number;
  disabled: boolean;
}

const Footer: React.FC<Props> = ({ contact, instanceId, disabled }) => {
  const [previewFiles, setPreviewFiles] = React.useState<Array<any>>([]);
  const [message, setMessage] = React.useState<string>('');
  const [openEmoji, setOpenEmoji] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*,.pdf': [],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/bmp': ['.bmp'],
      'image/tiff': ['.tiff'],
      'image/webp': ['.webp'],
      'video/avi': ['.avi'],
      'video/mkv': ['.mkv'],
      'video/mov': ['.mov'],
      'video/mp4': ['.mp4'],
      'application/pdf': ['.pdf'],
    },
    disabled: isSending,
    multiple: true,
    onDrop: (acceptedFiles: any) => {
      const fileCreateObj = acceptedFiles.map((f: any) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
          filename: f.name,
        })
      );
      setPreviewFiles((prev: any) => [...fileCreateObj, ...prev]);
    },
  });

  const onSendMessage = async (type: string) => {
    if (type === 'file') {
      setIsSending(true);
      const res = await ApiOpportunity.sendOpportunityImages({
        companieWhatsappInstanceId: instanceId,
        whatsapp: `55${contact.person.whatsapp}`,
        file: previewFiles,
        message,
      });

      if (typeof res === 'string' && res.includes('"exists":false')) {
        notify({
          message:
            'O número atual não possui WhatsApp vinculado. Por favor, tente novamente com um número válido.',
          type: 'Error',
        });
      }

      setMessage('');
      setPreviewFiles([]);
      setIsSending(false);
      return;
    }
    setIsSending(true);
    const res = ApiOpportunity.sendOpportunityMessage({
      companie_whatsapp_instance_id: instanceId,
      remote_j_id: `55${contact.person.whatsapp}`,
      text: message,
    });

    res.then((item) => {
      if (typeof item === 'string' && item.includes('"exists":false')) {
        notify({
          message:
            'O número atual não possui WhatsApp vinculado. Por favor, tente novamente com um número válido.',
          type: 'Error',
        });
      } else if (typeof item === 'string') {
        notify({ message: dictionaryError(item), type: 'Error' });
      }
    });

    setMessage('');
    setIsSending(false);
  };

  const onPickEmoji = (evt: { emoji: any }) => setMessage(message + evt.emoji);

  return (
    <>
      <div className="flex w-full">
        <div className="flex">
          {/* eslint-disable-next-line react/button-has-type */}
          <button {...getRootProps()} disabled={disabled} className="w-[30px]">
            <IconDictionary name="plus" color="gray" size={25} />
          </button>
        </div>

        <div className="relative w-full">
          <Input
            disabled={disabled}
            icon={
              <div className="relative flex">
                {openEmoji && (
                  <div className="flex absolute left-[-13px] bottom-[45px]">
                    <EmojiPicker onEmojiClick={onPickEmoji} />
                  </div>
                )}
                {/* eslint-disable-next-line react/button-has-type */}
                <button
                  disabled={disabled || isSending}
                  className="w-[30px]"
                  onClick={(evt) => {
                    evt.preventDefault();
                    setOpenEmoji(!openEmoji);
                  }}
                >
                  <IconDictionary name="Smile" color="gray" size={25} />
                </button>
              </div>
            }
            value={message}
            placeholder="Digite algo aqui"
            variant="outline-orange"
            className="pl-2"
            onChange={(evt) => setMessage(evt.target.value)}
            onKeyDown={(evt: any) => {
              if (evt.keyCode === 13) {
                onSendMessage('message');
              }
            }}
          />
        </div>
        {isSending ? (
          <div className="flex items-center justify-center ml-2">
            <Spinner />
          </div>
        ) : (
          <button
            type="button"
            onClick={() => onSendMessage('message')}
            disabled={disabled}
            className="w-[50px]"
          >
            <IconDictionary name="Send" color="gray" size={20} />
          </button>
        )}
      </div>
      <Modal
        isOpen={previewFiles.length > 0}
        setIsOpen={() => {
          setPreviewFiles([]);
          setMessage('');
        }}
        title="Documentos, Fotos e Vídeos"
        minHeight="300px"
      >
        <Card className="flex flex-col">
          <div className="flex flex-col justify-center items-center">
            {!!previewFiles && previewFiles[0]?.type === 'video/mp4' && (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video
                controls
                style={{ maxHeight: 350, borderRadius: 5 }}
                src={previewFiles[0]?.preview}
              />
            )}

            {!!previewFiles && previewFiles[0]?.type.includes('image') && (
              <img
                style={{ maxHeight: 350, borderRadius: 5 }}
                src={previewFiles[0]?.preview}
                alt="img"
              />
            )}
          </div>
          <div className="flex w-full">
            <div className="w-full">
              <Input
                value={message}
                placeholder="Digite uma mensagem"
                variant="outline-orange"
                className="pl-2"
                onChange={(evt) => setMessage(evt.target.value)}
              />
            </div>
            {isSending ? (
              <div className="flex items-center justify-center ml-2">
                <Spinner />
              </div>
            ) : (
              <button
                type="button"
                className="w-[50px]"
                onClick={() => onSendMessage('file')}
              >
                <IconDictionary name="Send" color="gray" size={15} />
              </button>
            )}
          </div>
        </Card>
        <div className="flex justify-center">
          {!!previewFiles &&
            previewFiles.map((item) => {
              if (item.type.includes('image')) {
                return (
                  <img
                    style={{
                      objectFit: 'cover',
                      maxHeight: 50,
                      width: 50,
                      borderRadius: 5,
                      margin: '0 5px',
                    }}
                    src={item.preview}
                    alt="img"
                  />
                );
              }
              if (item.type === 'video/mp4') {
                return (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  <video
                    style={{ width: 50, height: 50, borderRadius: 5 }}
                    src={item.preview}
                  />
                );
              }
              return (
                <img
                  style={{
                    objectFit: 'cover',
                    maxHeight: 50,
                    width: 50,
                    borderRadius: 5,
                    margin: '0 5px',
                  }}
                  src="https://www.svgrepo.com/show/144578/pdf.svg"
                  alt="img"
                />
              );
            })}
          {/* eslint-disable-next-line react/button-has-type */}
          <button {...getRootProps()} className="w-[50px] h-[50px]">
            <input {...getInputProps()} />
            <IconDictionary name="plus" color="gray" size={15} />
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Footer;
