import React from 'react';
import { Card, HeaderPages } from '../../../components';
import ServiceInvoiceCompanyConfig from '../components/serviceInvoiceCompanyConfig';

const InvoiceConfig = () => (
  <>
    <HeaderPages title="Configuração de nota fiscal" />

    <Card className="mt-10 flex-col p-6">
      <div className="grid grid-cols-2 md:flex flex-wrap lg:flex-nowrap gap-2 mt-9" />
      <ServiceInvoiceCompanyConfig />
    </Card>
  </>
);

export default InvoiceConfig;
