import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { lighten } from 'polished';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import { reloadAccess, reloadTheme } from '../../../hooks/globalAtom';
import { getCurrentUserAccess, getMenus } from '../api';
import { CurrentUserAccessProps, MenuSideBar, ThemeData } from '../types';
import { ApiTheme } from '../../companieCustom/api';
import { getItem, setItem } from '../../../helpers/storage.helper';
import LogoEstrela from '../../../assets/png/logo-estrela.png';
import LogoMobile from '../../../assets/png/logo-cor-branca.png';

export function useFetchMenu(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<MenuSideBar[]>([]);
  useEffect(() => {
    const FetchCompany = () => {
      setIsLoading(true);
      const companies = getMenus();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { MenusList: data, isLoading };
}

export function useFetchCurrentAccess() {
  const [key] = useAtom(reloadAccess);
  const accountProps = getTokenAccountInformation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<CurrentUserAccessProps[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const res =
        accountProps.profile_id === 1
          ? []
          : await getCurrentUserAccess(accountProps.id);
      if (typeof res !== 'string') {
        setData(res);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { AccessListUser: data, isLoading, setAccessListUser: setData };
}

export function useTheme(companieId: number | undefined) {
  const [updateTheme, setUpdateTheme] = useAtom(reloadTheme);
  const pathLogo = getItem('logo-path') || '';
  let colorHex = getItem('color-hex') || '#712770';

  const [theme, setTheme] = useState<ThemeData>({
    path_logo: pathLogo,
    color_hex: colorHex,
    logo: pathLogo !== '' ? pathLogo : LogoEstrela,
    logo_mobile: pathLogo !== '' ? pathLogo : LogoMobile,
    hasCustomImage: false,
  });

  if (companieId === undefined) {
    const root = document.documentElement;
    root.style.setProperty('--primary', theme.color_hex);
    root.style.setProperty('--secondary', lighten(0.1, theme.color_hex));
    return {
      theme,
    };
  }

  useEffect(() => {
    const getTheme = async () => {
      const data = await ApiTheme.getCompanyTheme(companieId);
      if (data && data.path_logo && data.path_logo !== '') {
        setItem('logo-path', data.path_logo);
      }
      if (data.color_hex && data.color_hex !== '') {
        setItem('color-hex', data.color_hex);
        const root = document.documentElement;
        root.style.setProperty('--primary', data.color_hex);
        root.style.setProperty('--secondary', data.color_hex);
        colorHex = data.color_hex;
      }
      setTheme({
        path_logo: data.path_logo,
        color_hex: colorHex,
        logo: data.path_logo !== '' ? data.path_logo : LogoEstrela,
        logo_mobile: data.path_logo !== '' ? data.path_logo : LogoMobile,
        hasCustomImage: data.path_logo !== '',
      });
    };

    if (updateTheme.path === '') return;

    if (updateTheme.path === 'initial') {
      // primeira vez que renderizar
      getTheme();
      setUpdateTheme({ path: '', colorHex: '' });

      return;
    }
    if (updateTheme.path !== '') {
      // Se o usuario atual alterar a logo
      setTheme({
        path_logo: updateTheme.path,
        color_hex: updateTheme.colorHex,
        logo: updateTheme.path,
        logo_mobile: updateTheme.path,
        hasCustomImage: updateTheme.path !== '',
      });

      setUpdateTheme({ path: '', colorHex: '' });
    }
  }, [updateTheme]);

  return {
    theme,
  };
}
