/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Card, SelectComponent } from '../../../../../components';
import ShortParagraphAnswer from './shortParagraph';
import MultipleRadio from './multipleRadio';
import IconDictionary from '../../../../../components/Icons/icons';
import MultipleBox from './multiBox';
import QuestionList from './list';
import DateQuestion from './data';
import { FormResponse } from '../../../types';

type QuestionProps = {
  title: string;
  is_active: boolean;
  options: { title: string; is_active: boolean; id: number }[];
  type_response: string;
  id: number;
};
interface props {
  questions: QuestionProps;
  answers: FormResponse;
  id: number;
  questionId: number;
  updateAnswers: (e: FormResponse) => void;
}

const AnswerContainer: React.FC<props> = ({
  questions,
  id,
  questionId,
  answers,
  updateAnswers,
}) => {
  const GetQuestion = (type: string, question: QuestionProps) => {
    switch (type) {
      case 'textarea':
      case 'text':
        return (
          <ShortParagraphAnswer
            title={question.title}
            setText={(e) => {
              const currentIndex = answers.responses.findIndex(
                (item) => item.search_form_question_id === question.id
              );
              if (question.options[0]) {
                if (currentIndex !== -1) {
                  answers.responses[currentIndex] = {
                    search_form_question_id: questionId,
                    options: [
                      {
                        search_form_question_option_id: question.options[0].id,
                        text_response: e,
                      },
                    ],
                  };
                } else {
                  answers.responses.push({
                    search_form_question_id: questionId,
                    options: [
                      {
                        text_response: e,
                        search_form_question_option_id: question.options[0].id,
                      },
                    ],
                  });
                }
              }

              updateAnswers({
                ...answers,
              });
            }}
          />
        );

      case 'date':
        return (
          <DateQuestion
            text={question.title}
            setText={(e) => {
              const currentIndex = answers.responses.findIndex(
                (item) => item.search_form_question_id === question.id
              );
              if (currentIndex !== -1) {
                answers.responses[currentIndex] = {
                  search_form_question_id: questionId,
                  options: [
                    {
                      text_response: e,
                      search_form_question_option_id: question.options[0].id,
                    },
                  ],
                };
              } else {
                answers.responses.push({
                  search_form_question_id: questionId,
                  options: [
                    {
                      text_response: e,
                      search_form_question_option_id: question.options[0].id,
                    },
                  ],
                });
              }
              updateAnswers({
                ...answers,
              });
            }}
          />
        );
      case 'radio':
        return (
          <MultipleRadio
            options={question.options}
            text={question.title}
            setAnswer={(idOption, text) => {
              const currentIndex = answers.responses.findIndex(
                (item) => item.search_form_question_id === question.id
              );
              if (currentIndex !== -1) {
                answers.responses[currentIndex] = {
                  search_form_question_id: questionId,
                  options: [
                    {
                      search_form_question_option_id: idOption,
                      text_response: text,
                    },
                  ],
                };
              } else {
                answers.responses.push({
                  search_form_question_id: questionId,
                  options: [
                    {
                      search_form_question_option_id: idOption,
                      text_response: text,
                    },
                  ],
                });
              }
              updateAnswers({
                ...answers,
              });
            }}
          />
        );
      case 'checkbox':
        return (
          <MultipleBox
            options={question.options}
            text={question.title}
            setText={(options: any[]) => {
              const currentIndex = answers.responses.findIndex(
                (item) => item.search_form_question_id === question.id
              );
              if (currentIndex !== -1) {
                answers.responses[currentIndex] = {
                  search_form_question_id: questionId,
                  options,
                };
              } else {
                answers.responses.push({
                  search_form_question_id: questionId,
                  options,
                });
              }
              updateAnswers({
                ...answers,
              });
            }}
          />
        );
      case 'select':
        return (
          <QuestionList
            options={question.options.map((item: any) => ({
              label: item.title,
              value: item.id,
            }))}
            text={question.title}
            updateAnswer={(options: any) => {
              const currentIndex = answers.responses.findIndex(
                (item) => item.search_form_question_id === question.id
              );
              if (currentIndex !== -1) {
                answers.responses[currentIndex] = {
                  search_form_question_id: questionId,
                  options,
                };
              } else {
                answers.responses.push({
                  search_form_question_id: questionId,
                  options,
                });
              }
              updateAnswers({
                ...answers,
              });
            }}
          />
        );
      default:
        return <> </>;
    }
  };

  return (
    <div className="relative">
      <Card className="flex-col mt-2 px-4 py-3 relative">
        <div className="flex flex-wrap-reverse sm:flex-nowrap flex sm:justify-between w-full">
          {GetQuestion(questions.type_response, questions)}
        </div>
      </Card>
    </div>
  );
};

export default AnswerContainer;
