/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  Button,
  Input,
  InputCity,
  InputDate,
  SelectComponent,
} from '../../../../../../components';
import maskHelper from '../../../../../../helpers/mask.helper';
import { dictionaryError } from '../../../../../../helpers/utils.helper';
import { OfferProps, createOfferProps } from '../../../../types';
import { useFetchClientDropdown } from '../../../../../clients/hooks';
import { ApiOffer } from '../../../../api';
import cpfValidation from '../../../../../../helpers/validations/cpf.validation';
import cnpjValidation from '../../../../../../helpers/validations/cnpj.validation';
import phoneValidate from '../../../../../../helpers/validations/phone.validate';
import { useFetchContact } from '../../../../../contacts/hooks';
import { ContactOpportunity } from '../../../../../opportunity/types';
import IconDictionary from '../../../../../../components/Icons/icons';
import Apartment from './apartment';
import Hotel from './hotel';
import InsetHotel from './insertHotel';
import { apartmentProps, hotelsProps, packageProps } from '../types';
import { companieFlightsOption } from '../../utils';

interface generalProps {
  defaultValue: OfferProps | null;
  providerOptions: { label: string; value: number }[];
  // setTypeValue: (e: 'total' | 'apartment') => void;
  // typeValue: 'total' | 'apartment';
}

const Package: React.FC<generalProps> = ({ defaultValue, providerOptions }) => {
  const todayDate = new Date();
  const yesterdayDate = new Date(todayDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  const [typeValue, setTypeValue] = useState<'total' | 'apartment'>('total');

  const schema = yup.object().shape({
    destiny: yup.string().required('O destino é obrigatório.'),
    departureDate: yup
      .date()
      .required('A data de partida é obrigatória.')
      .typeError('A data de partida deve ser uma data válida.'),
    arrivalDate: yup
      .date()
      .required('A data de chegada é obrigatória.')
      .typeError('A data de chegada deve ser uma data válida.')
      .min(
        yup.ref('departureDate'),
        'A data de chegada deve ser posterior à data de partida.'
      ),
    provider_id: yup.number(),
    companieFlight: yup.string().required('A companhia aérea é obrigatória.'),
    value_type: yup
      .mixed()
      .oneOf(
        ['total', 'appartment'],
        'O tipo de valor deve ser "total" ou "appartment".'
      )
      .required('O tipo de valor é obrigatório.'),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [apartments, setApartments] = useState<apartmentProps[]>([]);

  const [hotels, setHotels] = useState<hotelsProps[]>([]);
  const [currentHotel, setCurrentHotel] = useState<hotelsProps>();
  const [showModalInsertHotel, setShowModalInsertHotel] = useState(false);

  const { handleSubmit, setValue, formState, reset } = useForm<packageProps>({
    resolver: yupResolver(schema),
  });

  const Submit = async (values: packageProps) => {
    // if (defaultValue !== null && !isCreate) {
    //   setIsLoading(true);
    //   const res = await ApiOffer.updateOffer(
    //     {
    //       person_document: values.person_document,
    //       person_email: values.person_email,
    //       person_name: values.person_name,
    //       person_whatsapp: values.person_whatsapp,
    //       person_civil_status: values.person_civil_status,
    //       person_id: values.person_id,
    //       person_name_fantasy: values.person_name_fantasy,
    //       person_birth: values.person_birth,
    //       person_rg: values.person_rg,
    //       person_gender: values.person_gender,
    //     },
    //     defaultValue.id
    //   );
    //   if (res.id) {
    //     setDefaultValue({
    //       ...defaultValue,
    //       id: res.id,
    //       person_document: res.person_document,
    //       person_email: res.person_email,
    //       person_name: res.person_name,
    //       person_whatsapp: res.person_whatsapp,
    //       person_civil_status: res.person_civil_status,
    //       opportunity_id: res.opportunity_id,
    //       person_id: res.person_id,
    //       person_name_fantasy: res.person_name_fantasy,
    //       person_birth: res.person_birth,
    //       person_rg: res.person_rg,
    //       person_gender: res.person_gender,
    //       created_at: res.created_at,
    //       status: res.status,
    //       status_date: res.status_date,
    //       total: res.total,
    //       updated_at: res.updated_at,
    //       number: res.number,
    //     });
    //     setIsLoading(false);
    //     notify({
    //       message: 'Proposta atualizada com sucesso!',
    //       type: 'Success',
    //     });
    //     nextPage();
    //   } else {
    //     setIsLoading(false);
    //     notify({
    //       message: dictionaryError(res),
    //       type: 'Error',
    //     });
    //   }
    // } else {
    //   setIsLoading(true);
    //   const response = await ApiOffer.createOffer({
    //     ...values,
    //     opportunity_id: opportunityId,
    //     person_id: defaultValues.person_id,
    //   });
    //   if (response.id) {
    //     setDefaultValue({
    //       ...defaultValue,
    //       id: response.id,
    //       person_document: response.person_document,
    //       person_email: response.person_email,
    //       person_name: response.person_name,
    //       person_whatsapp: response.person_whatsapp,
    //       person_civil_status: response.person_civil_status,
    //       opportunity_id: response.opportunity_id,
    //       person_id: response.person_id,
    //       person_name_fantasy: response.person_name_fantasy,
    //       person_birth: response.person_birth,
    //       person_rg: response.person_rg,
    //       person_gender: response.person_gender,
    //       created_at: response.created_at,
    //       status: response.status,
    //       status_date: response.status_date,
    //       total: response.total,
    //       updated_at: response.updated_at,
    //       number: response.number,
    //     });
    //     nextPage();
    //     notify({ message: 'Proposta salva com sucesso', type: 'Success' });
    //   } else {
    //     notify({ message: dictionaryError(response), type: 'Error' });
    //     setIsLoading(false);
    //   }
  };

  const handleInsertApartment = () => {
    setApartments(
      apartments.concat([
        {
          adult: [{ value: 0, ravFix: 0, ravVar: 0, tax: 0 }],
          baby: [{ value: 0, ravFix: 0, ravVar: 0, tax: 0 }],
          child: [{ value: 0, ravFix: 0, ravVar: 0, tax: 0 }],
          type: '',
        },
      ])
    );
  };

  const handleEditApartment = (index: number, newElement: apartmentProps) => {
    setApartments((prevApartments) =>
      prevApartments.map((apartment, i) =>
        i === index ? { ...apartment, ...newElement } : apartment
      )
    );
  };

  const handleDeleteApartment = (index: number) => {
    if (apartments.length === 1) {
      setApartments([]);
      return;
    }
    setApartments(apartments.filter((item, indexC) => indexC !== index));
  };

  const handleInsertHotel = (
    name: string,
    obs: string,
    reg: string,
    value?: number,
    tax?: number,
    ravVar?: number,
    ravFix?: number
  ) => {
    hotels.push({
      type: 'Duplo',
      name,
      obs,
      reg,
      ravFix,
      ravVar,
      tax,
      value,
      apartment: apartments,
    });
    setHotels(hotels);
  };

  const handleDeleteHotel = (index: number) => {
    setHotels(hotels.filter((__, indexC) => indexC !== index));
  };

  return (
    <>
      <InsetHotel
        editApartment={handleEditApartment}
        defaultValues={currentHotel}
        insert={handleInsertHotel}
        mode={typeValue}
        setShow={setShowModalInsertHotel}
        show={showModalInsertHotel}
        apartments={apartments}
      />
      <form
        className="md:grid flex flex-wrap md:grid-cols-3 gap-3 mt-8"
        onSubmit={handleSubmit(Submit)}
      >
        <InputCity
          options={[]}
          title="Destino (cidade)"
          classNameDiv="w-full"
          // isDisabled={contactOptions.length === 0}
          // isMulti={false}
          // closeMenuOnSelect
          // options={contactOptions}
          // value={
          //   contactOptions.length === 0 && defaultValue
          //     ? { label: defaultValue.person_name, value: defaultValue.person_id }
          //     : contactOptions.find(
          //         (item) => item.value === defaultValues.person_id
          //       )
          // }
          onChange={(e: any) => {
            setValue('destiny', e.value);
          }}
          errorMensage={formState.errors.destiny?.message}
        />

        <SelectComponent
          classNameDiv="w-full"
          title="Fornecedor"
          options={providerOptions}
          // isDisabled={contactOptions.length === 0}
          // isMulti={false}
          // closeMenuOnSelect
          // options={contactOptions}
          // value={
          //   contactOptions.length === 0 && defaultValue
          //     ? { label: defaultValue.person_name, value: defaultValue.person_id }
          //     : contactOptions.find(
          //         (item) => item.value === defaultValues.person_id
          //       )
          // }
          onChange={(e: any) => {
            setValue('provider_id', e.value);
          }}
          errorMensage={formState.errors.provider_id?.message}
        />

        <SelectComponent
          classNameDiv="w-full"
          title="CIA Aérea"
          options={companieFlightsOption}
          // isDisabled={contactOptions.length === 0}
          // isMulti={false}
          // closeMenuOnSelect
          // options={contactOptions}
          // value={
          //   contactOptions.length === 0 && defaultValue
          //     ? { label: defaultValue.person_name, value: defaultValue.person_id }
          //     : contactOptions.find(
          //         (item) => item.value === defaultValues.person_id
          //       )
          // }
          onChange={(e: any) => {
            setValue('companieFlight', e.value);
          }}
          errorMensage={formState.errors.companieFlight?.message}
        />

        <InputDate
          title="Data de ida"
          onChange={(e: any) => {
            setValue('departureDate', e);
          }}
          errorMensage={formState.errors.departureDate?.message}
        />
        <InputDate
          title="Data de volta"
          onChange={(e: any) => {
            setValue('arrivalDate', e);
          }}
          errorMensage={formState.errors.arrivalDate?.message}
        />
        <div className="flex flex-col gap-3 text-sm">
          <p>Exibir valores por:</p>
          <fieldset className="flex gap-3 items-center mt-1.5">
            <label htmlFor="appartment" className="flex gap-1">
              <input
                type="radio"
                id="apartment"
                name="Apartamento"
                value="apartment"
                checked={typeValue === 'apartment'}
                className="mb-0 accent-primary text-white cursor-pointer"
                onChange={() => setTypeValue('apartment')}
              />
              Apartamento
            </label>

            <label htmlFor="total" className="flex gap-1">
              <input
                type="radio"
                className="mb-0 accent-primary cursor-pointer"
                id="total"
                name="total"
                value="total"
                checked={typeValue === 'total'}
                onChange={() => setTypeValue('total')}
              />
              Total
            </label>
          </fieldset>
        </div>
        <hr className="w-full h-px bg-primary col-span-2 lg:col-span-3" />
        <Apartment
          editApartment={handleEditApartment}
          deleteApartment={handleDeleteApartment}
          insertApartment={handleInsertApartment}
          apartment={apartments}
          current
        />
        <hr className="w-full h-px bg-primary col-span-3" />
        <Hotel
          handleInsert={() => setCurrentHotel(undefined)}
          hotels={hotels}
          handleDelete={handleDeleteHotel}
          handleEdit={(hotel) => {
            setCurrentHotel(hotel);
            setShowModalInsertHotel(true);
          }}
          current
          mode={typeValue}
          setShowInsertHotel={setShowModalInsertHotel}
        />
      </form>
    </>
  );
};

export default Package;
