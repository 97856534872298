import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Attachments, Button } from '../../../../components';
import Modal from '../../../../components/Modal/Modal';
import { ApiClient } from '../../api';
import { CreateUpdateModal } from '../../types';
import { ToastNotify } from '../../../../components/Toast/toast';
import { useFetchAttachments } from '../../hooks';
import GeneralDataClient from './components/generalData';
import SocialMedia from '../../../../components/Register/socialMedia';
import Address from '../../../../components/Register/address';

import ClientContactList from './components/contactListInClients';

import FinancialSummary from './components/financialSummary';
import ServiceInvoiceConfig from './components/serviceInvoiceConfig';

const header = [
  {
    id: 0,
    title: 'Dados Pessoais',
    page: 0,
  },
  {
    id: 1,
    title: 'Contatos',
    page: 1,
  },
  {
    id: 2,
    title: 'Endereço',
    page: 2,
  },
  {
    id: 3,
    title: 'Redes Sociais',
    page: 3,
  },
  {
    id: 4,
    title: 'Anexos',
    page: 4,
  },
  {
    id: 5,
    title: 'Resumo Financeiro',
    page: 5,
  },
  {
    id: 6,
    title: 'Nota fiscal',
    page: 6,
  },
];

const RegisterClient: React.FC<CreateUpdateModal> = ({
  show,
  setShow,
  registerPage,
  setRegisterPage,
  reloadTable,
  setClient,
  client,
  companyUserId,
}) => {
  const [clientId, setClientId] = useState(-1);
  const [key, setKey] = useState(`${Math.random()} attachments`);
  const { AttachmentsList, isLoadingAttachmentsList } = useFetchAttachments(
    key,
    client?.id ?? clientId
  );

  const { notify } = ToastNotify();
  const nextPage = () => {
    if (registerPage.page < header.length - 1) {
      setRegisterPage({
        title: header[registerPage.page + 1].title,
        page: registerPage.page + 1,
      });
    } else {
      reloadTable(`${Math.random()} create`);
      setShow(false);
    }
  };

  useEffect(() => {
    setKey(`${Math.random()} files`);
  }, [show]);

  return (
    <Modal
      title={client ? 'Atualizar Cliente' : 'Cadastrar Clientes'}
      isOpen={show}
      setIsOpen={() => {
        reloadTable(`${Math.random()} close`);
        setShow(false);
        setClientId(-1);
      }}
      size="6xlarge"
      minHeight="min-h-[745px]"
    >
      <div className="flex flex-col">
        <div className="grid grid-cols-2 md:flex gap-2 mt-9 ">
          {header.map((item) => (
            <Button
              key={item.id}
              variant={
                registerPage.page === item.page ? 'primary-strong' : 'gray'
              }
              actionType="button-full-width"
              className={classNames([
                'font-medium',
                'md:w-32',
                client !== null ? 'cursor-pointer' : 'cursor-default',
              ])}
              onClick={() => {
                if (client !== null) {
                  setRegisterPage(item);
                }
              }}
            >
              {item.title}
            </Button>
          ))}
        </div>
        <hr className="w-full h-[1.5px] bg-primary mt-3" />
        <div>
          {registerPage.page === 0 && (
            <GeneralDataClient
              setClient={setClient}
              setId={setClientId}
              notify={notify}
              nextPage={nextPage}
              defaultValue={client}
              id={companyUserId}
            />
          )}
          {registerPage.page === 1 && (
            <div>
              <ClientContactList
                clientId={client?.id ?? clientId}
                isLoading={isLoadingAttachmentsList}
              />
            </div>
          )}
          {registerPage.page === 2 && (
            <Address
              hasNumber
              apiFunction={async (values, id) => {
                const res = await ApiClient.updateAddress(values, id);
                if (res.id) {
                  setClient(res);
                }
                return res;
              }}
              defaultValue={
                client && {
                  address: client.address ?? '',
                  city: client.city ?? '',
                  complement: client.complement,
                  province: client.province ?? '',
                  state: client.state ?? '',
                  zipcode: client.zipcode ?? '',
                  number: client.number,
                }
              }
              id={client?.id ?? clientId}
              nextPage={nextPage}
            />
          )}
          {registerPage.page === 3 && (
            <SocialMedia
              apiFunction={async (values, id) => {
                const res = await ApiClient.updateSocialMedia(values, id);
                if (res.id) {
                  setClient(res);
                }
                return res;
              }}
              id={client?.id ?? clientId}
              nextPage={nextPage}
              setId={setClientId}
              fields={['homepage', 'facebook', 'instagram', 'telegram']}
              defaultValues={{
                facebook: client?.facebook ?? '',
                instagram: client?.instagram ?? '',
                homepage: client?.homepage ?? '',
                obs: client?.obs ?? '',
              }}
            />
          )}
          {registerPage.page === 4 && (
            <Attachments
              AttachmentsList={AttachmentsList}
              apiFunction={ApiClient.createClientAttachments}
              deleteApiFunction={ApiClient.deleteAttachments}
              id={client?.id ?? clientId}
              keyFile="client_id"
              nextPage={nextPage}
              updateTable={setKey}
              isLoadingTable={isLoadingAttachmentsList}
            />
          )}
          {registerPage.page === 5 && (
            <FinancialSummary nextPage={nextPage} client={client} />
          )}
          {registerPage.page === 6 && <ServiceInvoiceConfig client={client!} />}
        </div>
        <div className="flex w-full gap-2 justify-center mt-9">
          {header.map((item) =>
            item.page === registerPage.page ? (
              <div className="h-3 w-3 rounded-full bg-primary" key={item.id}>
                {' '}
              </div>
            ) : (
              <div className="h-3 w-3 rounded-full bg-gray-400" key={item.id}>
                {' '}
              </div>
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RegisterClient;
