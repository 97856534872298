import React, { useContext, useState } from 'react';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import { ApiCheckout } from '../../api';
import {
  CreateAccountProps,
  PaymentCardProps,
  ResponseCreateCompanie,
} from '../../types';

import ButtonRounded from '../button';
import CardRounded from '../card';
import maskHelper from '../../../../helpers/mask.helper';
import { dictionaryError } from '../../../../helpers/utils.helper';
import { notify } from '../../../../components/Toast/toast';
import { Input, isLoadingApplication } from '../../../../components';
import { getCardBrand, validateExpiryDate } from '../../utils';
import visa from '../../../../assets/png/visa.png';
import mastercard from '../../../../assets/png/mastercard.png';
import elo from '../../../../assets/png/elo.png';
import amex from '../../../../assets/png/American.png';
import hipercard from '../../../../assets/png/Hipercard.png';
import CreditCardAnimation from './creditCardAnimation';
import FinishedPayment from './finishedPayment';
import Stamps from './stamp';
import SummaryPayment from './summaryPayment';
import ModalPaymentSuccess from './modalPaymentSuccess';
import { accessCompanie } from '../../../admin/company/utils';

interface props {
  isDisabled: boolean;
  personalInform: CreateAccountProps | null;
  planInfo?: any;
  setIsDone: React.Dispatch<React.SetStateAction<boolean>>;
  done: boolean;
}

const PaymentCard: React.FC<props> = ({
  personalInform,
  isDisabled,
  done,
  setIsDone,
  planInfo = {
    value: 100,
    qty: 10,
    name: 'Plano Básico',
    is_trial: false,
    id: 0,
  },
}) => {
  const { setState } = useContext(isLoadingApplication);
  function GetLogo(brand: string) {
    switch (brand) {
      case 'visa':
        return <img src={visa} alt="visa_logo" />;
      case 'mastercard':
        return <img src={mastercard} alt="visa_logo" />;
      case 'elo':
        return <img src={elo} alt="visa_logo" />;
      case 'amex':
        return <img src={amex} alt="visa_logo" />;
      case 'hipercard':
        return <img src={hipercard} alt="visa_logo" />;

      default:
        return '';
    }
  }
  const [selected, setSelected] = useState({ name: '', value: '' });

  const resetCreditCard = {
    name: 'NOME COMPLETO',
    number: '•••• •••• •••• ••••',
    dueDate: '••/••',
    cvv: '•••',
    logo: '',
    type: 'F',
  };

  const [link, setLink] = useState<any>({
    boleto: null,
    pix: null,
    card: null,
  });
  const [creditCard, setCreditCard] = useState(resetCreditCard);

  const [logo, setLogo] = useState('');

  const schema = yup.object().shape({
    card: yup.object({
      expiresAt: yup
        .string()
        .required('Campo obrigatório')
        .test(
          'is-valid-expiry',
          'Data deve ser maior que hoje',
          validateExpiryDate
        ),
      number: yup.string().required('Campo obrigatório'),
      holder: yup.string().required('Campo obrigatório'),
      cvv: yup
        .string()
        .required('Campo obrigatório')
        .matches(/^[0-9]{3}$/, 'CVV inválido'),
    }),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [[showModalPayment, email], setShowModalPayment] = useState([
    false,
    '',
  ]);

  const { setValue, getValues, trigger, formState, setError } =
    useForm<PaymentCardProps>({
      resolver: yupResolver(schema),
    });

  const Submit = async () => {
    if (selected.name === 'Cartão') {
      const form = await trigger();
      if (!form) return;
    }

    if (personalInform) {
      const payload = {
        ...personalInform,
        form_of_payment: planInfo.is_trial ? null : selected.value,
        card: getValues('card'),
        companie_limit_user_id: planInfo.id,
      };
      setIsLoading(true);

      const res: ResponseCreateCompanie | string = await ApiCheckout.create(
        payload
      );
      if (typeof res !== 'string') {
        if (res.payment?.status === 'denied') {
          notify({
            message:
              'O cartão foi rejeitado pela operadora. Tente novamente com um novo cartão.',
            type: 'Error',
          });
          setIsLoading(false);
          return;
        }
        notify({
          message: 'Assinatura relizada com sucesso!',
          type: 'Success',
        });

        if (planInfo.is_trial || selected.name === 'Cartão') {
          setShowModalPayment([true, res.email]);
          setIsDone(true);
          setIsLoading(false);
          return;
        }

        setLink({
          boleto: {
            barcode: res.payment?.Boleto?.barCode,
            pdf: res.payment?.Boleto?.pdf,
          },
          pix: {
            code: res.payment?.Pix?.qrCode,
            image: res.payment?.Pix?.image,
          },
          card: { email: res.email },
        });
        setIsDone(true);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
      }
      setIsLoading(false);
    }
  };

  return (
    <CardRounded disabled={isDisabled} finished={done}>
      <ModalPaymentSuccess
        login={() => accessCompanie(email, setState)}
        setShow={(show) => setShowModalPayment([show, ''])}
        show={showModalPayment}
      />
      <SummaryPayment
        done={done}
        isDisabled={isDisabled}
        planInfo={planInfo}
        resetCreditCard={() => setCreditCard(resetCreditCard)}
        selected={selected}
        setSelected={setSelected}
        setValue={setValue}
      />
      {selected.value === 'creditcard' && !done && (
        <>
          <strong className="mt-5 text-sm">Dados do cartão</strong>
          <CreditCardAnimation
            typeCard={creditCard.type}
            cvv={creditCard.cvv}
            dueDate={creditCard.dueDate}
            logo={GetLogo(logo)}
            name={creditCard.name}
            number={creditCard.number}
          />
          <div className="relative grid grid-cols-4 gap-x-2 gap-y-4 mt-8 mb-5">
            <Input
              disabled={isDisabled}
              variant="outline-orange"
              title="Número do cartão"
              classNameDiv="col-span-3"
              onFocus={() => {
                setCreditCard({ ...creditCard, type: 'F' });
              }}
              onBlur={(e) => {
                const type: any = getCardBrand(e.target.value);
                if (e.target.value.length === 19) {
                  setLogo(type);
                  setValue('card.number', e.target.value);
                } else {
                  setLogo('');
                  setError('card.number', { message: 'Número inválido' });
                }
              }}
              onChange={(e) => {
                e.target.value = maskHelper.creditCard(e.target.value);
                if (e.target.value.length > 0) {
                  setCreditCard({ ...creditCard, number: e.target.value });
                } else {
                  setCreditCard({
                    ...creditCard,
                    number: resetCreditCard.number,
                  });
                }
              }}
              errorMensage={formState.errors.card?.number?.message}
              borderDefault="border-[#03a6c7]"
            />

            <Input
              disabled={isDisabled}
              variant="outline-orange"
              title="CVV"
              onChange={(e) => {
                e.target.value = e.target.value.slice(0, 4);
                if (e.target.value.length > 0) {
                  setCreditCard({ ...creditCard, cvv: e.target.value });
                } else {
                  setCreditCard({
                    ...creditCard,
                    cvv: resetCreditCard.cvv,
                  });
                }
              }}
              onBlur={(e) => {
                setValue('card.cvv', maskHelper.number(e.target.value));
              }}
              type="number"
              errorMensage={formState.errors.card?.cvv?.message}
              borderDefault="border-[#03a6c7]"
              onFocus={() => {
                setCreditCard({ ...creditCard, type: 'B' });
              }}
            />
            <Input
              disabled={isDisabled}
              variant="outline-orange"
              title="Nome no Cartão"
              classNameDiv="col-span-2"
              maxLength={30}
              onBlur={(e) => {
                setValue('card.holder', e.target.value);
              }}
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  setCreditCard({ ...creditCard, name: e.target.value });
                } else {
                  setCreditCard({
                    ...creditCard,
                    name: resetCreditCard.name,
                  });
                }
              }}
              errorMensage={formState.errors.card?.holder?.message}
              borderDefault="border-[#03a6c7]"
              onFocus={() => {
                setCreditCard({ ...creditCard, type: 'F' });
              }}
            />
            <Input
              disabled={isDisabled}
              variant="outline-orange"
              title="Validade"
              placeholder="MM/AAAA"
              classNameDiv="col-span-2"
              onChange={(e) => {
                e.target.value = maskHelper.formatDateMY(e.target.value);
                if (e.target.value.length > 0) {
                  setCreditCard({
                    ...creditCard,
                    dueDate: `${e.target.value.slice(
                      0,
                      2
                    )}/${e.target.value.slice(-2)}`,
                  });
                } else {
                  setCreditCard({
                    ...creditCard,
                    dueDate: resetCreditCard.dueDate,
                  });
                }
              }}
              onBlur={(e) => {
                setValue(
                  'card.expiresAt',
                  moment(e.target.value, 'MM/YYYY').format('YYYY-MM')
                );
              }}
              errorMensage={formState.errors.card?.expiresAt?.message}
              borderDefault="border-[#03a6c7]"
              onFocus={() => {
                setCreditCard({ ...creditCard, type: 'F' });
              }}
            />
          </div>
        </>
      )}
      {selected.value === 'boleto' && !done && (
        <div className="flex flex-col text-xs">
          <strong className="mt-5 text-sm">Dados do Boleto</strong>
          <strong className="font-semibold mt-5 mb-2">Atenção:</strong>
          <p>
            1- O pagamento atual corresponde apenas para a assinatura do{' '}
            <strong>mês atual.</strong> A cada novo mês será gerado um novo
            boleto referente ao mês corrente da assinatura.
          </p>
          <br />
          <p>
            2- Pagamentos com Boleto Bancários levam{' '}
            <strong>até 3 dias úteis</strong> para serem compensados e então ter
            a plataforma 100% liberada.
          </p>
          <br />
          <p>
            3- Depois do pagamento, fique atendo ao seu e-mail para receber os
            dados de acesso a plataforma (verifique também a caixa de SPAM).
          </p>
        </div>
      )}
      {selected.value === 'pix' && !done && (
        <div className="flex flex-col text-xs">
          <strong className="mt-5 text-sm mb-5">Dados do Pix</strong>
          <p>
            1- O pagamento atual corresponde apenas para a assinatura do{' '}
            <strong>mês atual.</strong> A cada novo mês será gerado um novo
            pagamento referente ao mês corrente da assinatura.
          </p>
          <br />
          <p>
            2- Basta escanear, com o aplicativo do seu banco, o QRCode que
            iremos gerar para sua compra. O PIX foi desenvolvido pelo Banco
            Central para facilitar suas compras e é 100% seguro.
          </p>
          <br />
          <p>
            3- Depois do pagamento, fique atendo ao seu e-mail para receber os
            dados de acesso a plataforma (verifique também a caixa de SPAM).
          </p>
        </div>
      )}
      <ButtonRounded
        isLoading={isLoading}
        disabled={
          isLoading ||
          isDisabled ||
          (selected.name === '' && !planInfo.is_trial) ||
          done
        }
        onClick={Submit}
      >
        Finalizar
      </ButtonRounded>

      <Stamps />

      <FinishedPayment done={done} link={link} selected={selected} />
    </CardRounded>
  );
};

export default PaymentCard;
