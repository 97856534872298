import React, { useState, useRef } from 'react';

import { useFetchFunnelByProfile } from '../../../funnel/hooks';

import { Button, Modal } from '../../../../components';

import { getTokenAccountInformation } from '../../../../helpers/token.helper';
import { useFetchUsersCompanie } from '../../../users/hooks';
import { useFetchSalesChannel } from '../../../salesChannel/hooks';
import { useFetchSegment } from '../../../segment/hooks';
import { GroupSelects } from '../../../../hooks/GroupSelects';
import { ToastNotify } from '../../../../components/Toast/toast';

import { useWppContext } from '../../context';
import { ApiWhatsInstances } from '../../api';
import { dictionaryError } from '../../../../helpers/utils.helper';

export const RegisterModal: React.FC = () => {
  const { notify } = ToastNotify();

  const { modal, setModal, whatsappPlans, setReload } = useWppContext();
  const accountProps = getTokenAccountInformation();
  const FormConfig = useRef<HTMLFormElement>(null);

  const config = {
    show: modal.show === 'register' || modal.show === 'update',
    title: modal.show === 'register' ? 'Cadastrar' : 'Atualizar',
    isUpdate: modal.show === 'update',
  };

  const [loading, setLoading] = useState<boolean>(false);

  const UserListProps = useFetchUsersCompanie('key', accountProps.companie_id);
  const optionUser = UserListProps.UsersList.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const SalesChannelProps = useFetchSalesChannel(
    'key',
    accountProps.companie_id
  );
  const optionSaleChannel = SalesChannelProps.SalesChannelList.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const SegmentProps = useFetchSegment('key', accountProps.companie_id);
  const optionsSegments = SegmentProps.SegmentList.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const FunnelListProps = useFetchFunnelByProfile('key', accountProps.id);
  const optionsFunnel = FunnelListProps.FunnelsList.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const SubmitForms = async (dataForm: any) => {
    setLoading(true);

    const data = {
      ...dataForm,
      whatsappPlansId: whatsappPlans.id,
      instanceId: modal.data.id,
    };

    if (config.isUpdate) {
      const payload = {
        is_sending_message_automatic: data.is_sending_message_automatic,
        companie_user_id_owner: Number(data.companie_user_id_owner),
        funnel_step_id: Number(data.funnel_step_id),
        sales_channel_id: data.sales_channel_id,
        segment_id: data.segment_id,
        is_create_opportunity: data.is_create_opportunity,
        is_bulk_shipping: data.is_bulk_shipping,
      };
      const res = await ApiWhatsInstances.updateCompanieWhatsappInstances(
        payload,
        data.instanceId
      );
      if (typeof res === 'string') {
        notify({ message: dictionaryError(res), type: 'Error' });
        setLoading(false);
        return;
      }
      notify({
        message: 'Instância atualizada com sucesso!',
        type: 'Success',
      });
    } else {
      const payload = {
        is_sending_message_automatic: data.is_sending_message_automatic,
        is_bulk_shipping: data.is_bulk_shipping,
        companie_whatsapp_plan_id: data.whatsappPlansId,
        whatsapp_number: dataForm.whatsapp_number, // input
        companie_user_id_owner: Number(data.companie_user_id_owner), // companie_user_id
        funnel_step_id: Number(data.funnel_step_id), // etapa
        sales_channel_id: data.sales_channel_id, // canal de venda
        segment_id: data.segment_id, // null
        is_create_opportunity: data.is_create_opportunity, // checkbox default false || false
      };
      const res = await ApiWhatsInstances.createCompanieWhatsappInstances(
        payload
      );

      if (typeof res === 'string') {
        if (res.includes('This name') && res.includes('is already in use')) {
          notify({
            message:
              'Número do telefone já esta em uso. Por favor digite um novo número.',
            type: 'Warning',
          });
          setLoading(false);
          return;
        }
        notify({ message: dictionaryError(res), type: 'Error' });
        setLoading(false);
        return;
      }
      setModal({ show: 'connect', data: { id: res.id } });
      notify({
        message: 'Instância cadastrada com sucesso!',
        type: 'Success',
      });
    }

    setLoading(false);
    setReload(String(Math.random()));
    setModal({ ...modal, show: 'undefined' });
  };

  return (
    <Modal
      title={`${config.title} Instâncias`}
      isOpen={config.show}
      setIsOpen={() => setModal({ ...modal, show: 'undefined' })}
      size="2xlarge"
      minHeight="min-h-[400px]"
    >
      <GroupSelects
        ref={FormConfig}
        apiFunction={SubmitForms}
        optionSaleChannel={optionSaleChannel}
        optionUser={optionUser}
        optionsFunnel={optionsFunnel}
        optionsSegments={optionsSegments}
        type="form"
        config={modal.data}
      />
      <Button
        isLoading={loading}
        type="button"
        variant="primary-strong"
        className="w-48 sm:w-64 col-span-3 mx-auto mt-5"
        actionType="button-loading"
        onClick={() => FormConfig.current?.requestSubmit()}
        disabled={loading}
      >
        {config.title} Instância
      </Button>
    </Modal>
  );
};
