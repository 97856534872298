import { baseApiPrivate } from '../../../api/baseApi';

async function getUserPlans() {
  return baseApiPrivate({ method: 'GET', url: 'companie/whatsapp/plans' });
}

export async function purchaseUpdatePlan(plan: any, whatsappPlanId: any) {
  const verify = plan.status === 'Ag.Pagamento' || plan.status === 'Ativo';

  return baseApiPrivate({
    method: verify ? 'PUT' : 'POST',
    url: `companie/whatsapp/plans${verify ? `/upgrade/${plan.id}` : ''}`,
    body: { whatsapp_plan_id: whatsappPlanId.id },
  });
}

async function createCompanieWhatsappInstances(dataForm?: any) {
  return baseApiPrivate({
    body: dataForm,
    method: 'POST',
    url: 'companie/whatsapp/instances',
  });
}

async function updateCompanieWhatsappInstancesStatus(payload: any, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    body: payload,
    url: `companie/whatsapp/instances/status/${id}`,
  });
}

async function updateCompanieWhatsappInstances(
  payload: any,
  idInstance: number
) {
  return baseApiPrivate({
    method: 'PUT',
    body: payload,
    url: `companie/whatsapp/instances/${idInstance}`,
  });
}

async function getCompanieWhatsappInstances(id: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/whatsapp/instances/${id}`,
  });
}

async function deleteCompanieWhatsappInstances(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/whatsapp/instances/${id}`,
  });
}

export async function adminWhatsappPlansActive() {
  return baseApiPrivate({
    method: 'GET',
    url: 'admin/whatsapp/plans/active',
  });
}

export async function companieWhatsappPlansCancel(planId: number | undefined) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/whatsapp/plans/cancel/${planId}`,
  });
}

export async function getWhatsappInstancesByPlan(
  companieWhatsappPlanId: number
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/whatsapp/instances/${companieWhatsappPlanId}`,
  });
}

export const ApiWhatsInstances = {
  getUserPlans,
  getCompanieWhatsappInstances,
  createCompanieWhatsappInstances,
  updateCompanieWhatsappInstances,
  updateCompanieWhatsappInstancesStatus,
  deleteCompanieWhatsappInstances,
};
