/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { number, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Input,
  Modal,
  SelectComponent,
  Switch,
  TextArea,
} from '../../../components';
import { ApiExpenses } from '../api';
import { EconomicActivity } from '../../../types';
import { ToastNotify } from '../../../components/Toast/toast';

export interface CreateCompanyInvoiceServiceModalProps {
  isOpen?: boolean;
  /* eslint-disable no-unused-vars */
  setIsOpen: (e: boolean) => void;
  economicActivity: EconomicActivity | null | undefined;
}

const schema = object().shape({
  issRate: string()
    .matches(
      /^[+-]?(\d+(\.\d+)?|\.\d+)$/,
      'A alíquota ISS deve ser um número válido'
    )
    .required('A alíquota ISS é obrigatória'),
  defaultInvoiceDescription: string().nullable(),
  cityServiceCodeId: string().required(
    'O código do serviço municipal é obrigatório'
  ),
});
const CreateCompanyInvoiceServiceModal: FC<
  CreateCompanyInvoiceServiceModalProps
> = ({ isOpen, setIsOpen, economicActivity }) => {
  const [economicActivities, setEconomicActivities] = useState<
    EconomicActivity[]
  >([]);

  const economicActivitiesOptions = useMemo(
    () =>
      economicActivities?.map((e) => ({
        value: e.id,
        label: `${e.cityServiceCode} - ${e.description}`,
      })),
    [economicActivities]
  );
  const {
    register,
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: economicActivity ?? {},
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    ApiExpenses.getPossibleCompanyEconomicActivities().then((response) =>
      setEconomicActivities(response.data)
    );
  }, []);
  const onSubmit = (companyInvoiceService: any) => {
    const selectedService = economicActivities.find(
      (e: any) => e.id === companyInvoiceService.cityServiceCodeId
    );
    ApiExpenses.updateCompanyInvoiceServices({
      id: companyInvoiceService?.id,
      companyInvoiceTypeId: companyInvoiceService?.companyInvoiceTypeId,
      cityServiceCodeId: companyInvoiceService?.cityServiceCodeId,
      issRate: +(companyInvoiceService?.issRate ?? 0),
      irWithholdingRate:
        companyInvoiceService?.irWithholdingRate ??
        selectedService?.irWithholdingRate ??
        0,
      pisWithholdingRate:
        companyInvoiceService?.pisWithholdingRate ??
        selectedService?.pisWithholdingRate ??
        0,
      cofinsWithholdingRate:
        companyInvoiceService?.cofinsWithholdingRate ??
        selectedService?.cofinsWithholdingRate ??
        0,
      csllWithholdingRate:
        companyInvoiceService?.csllWithholdingRate ??
        selectedService?.csllWithholdingRate ??
        0,
      inssWithholdingRate:
        companyInvoiceService?.inssWithholdingRate ??
        selectedService?.inssWithholdingRate ??
        0,
      issWithholdingRate:
        companyInvoiceService?.issWithholdingRate ??
        selectedService?.issWithholdingRate ??
        0,
      defaultInvoiceDescription:
        companyInvoiceService?.defaultInvoiceDescription ?? 0,
      isDefault: companyInvoiceService?.isDefault ?? false,
      description: selectedService?.description,
    }).then((response) => {
      if (typeof response === 'string') {
        ToastNotify().notify({
          message:
            'Não foi possível salvar as configurações de serviço da empresa',
          type: 'Error',
        });
        return;
      }
      ToastNotify().notify({
        message: 'Configurações de serviço da empresa salvo com suceso',
        type: 'Success',
      });
      setIsOpen(false);
    });
  };
  return (
    <Modal
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      title="Cadastro de serviço de nota fiscal"
      minHeight="800px"
      size="2xlarge"
      header
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
        <div>
          <label
            className="block text-sm text-gray-700 font-bold"
            htmlFor="cityServiceCodeId"
          >
            Código do serviço municipal
          </label>
          <Controller
            name="cityServiceCodeId"
            control={control}
            render={({ field }) => (
              <SelectComponent
                {...field}
                options={economicActivitiesOptions}
                placeholder="Selecione o código de serviço municipal"
                value={
                  field?.value &&
                  economicActivitiesOptions?.find(
                    (o) => String(o.value) === String(field?.value)
                  )
                }
                onChange={(o: any) => setValue(field.name, o.value)}
                errorMensage={errors?.cityServiceCodeId?.message as string}
              />
            )}
          />
        </div>
        <div>
          <Input
            {...register('issRate')}
            title="Alíqota ISS (%)"
            type="number"
            step="0.01"
            max={100}
            min={0}
            errorMensage={errors?.issRate?.message}
          />
        </div>
        <TextArea
          {...register('defaultInvoiceDescription')}
          title="Descrição do serviço"
          classNamediv="font-bold"
          className="font-normal"
          onChange={(e) =>
            setValue('defaultInvoiceDescription', e.target.value)
          }
        />
        <div className="flex gap-2 items-center mt-2">
          <Controller
            name="isDefault"
            control={control}
            render={({ field }) => (
              <Switch
                {...field}
                state={Boolean(field.value)}
                onChange={() => setValue(field.name, !field.value)}
              />
            )}
          />
          <p className="text-sm ml-2">Definir como serviço padrão</p>
        </div>
        <div className="flex justify-center gap-4 mt-6">
          <Button
            className="px-4"
            variant="outline-primary"
            onClick={() => setIsOpen(false)}
          >
            Cancelar
          </Button>
          <Button type="submit" className="px-4">
            Salvar
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateCompanyInvoiceServiceModal;
