import { baseApiPrivate } from '../../../api/baseApi';

async function getFileBase64Api(url: string) {
  try {
    const file = url.split('net/')[1].split('/');
    const folder = file[1];
    const fileName = file[2];

    const payload = {
      file_name: fileName,
      folder,
    };

    return baseApiPrivate({
      method: 'POST',
      url: 'companie/azureFiles',
      body: payload,
    });
  } catch (e) {
    console.error(e);
    return 'Não foi possível obter a base64';
  }
}

export { getFileBase64Api };
