import React, { useState } from 'react';
import {
  Button,
  Input,
  Modal,
  SelectComponent,
  TextArea,
} from '../../../../../../components';

interface props {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line no-unused-vars
  insert: (e: string) => void;
}

const InsertTour: React.FC<props> = ({ setShow, show, insert }) => {
  const [name, setName] = useState('');
  const handleAdd = () => {
    insert(name);
    setShow(false);
  };
  return (
    <Modal
      size="4xlarge"
      minHeight="min-h-[200px]"
      isOpen={show}
      setIsOpen={setShow}
      title="Adicionar passeio"
    >
      <hr className="w-screen bg-primary h-px mt-3 mb-4 -mx-6" />
      <TextArea
        title="Descrição do passeio"
        onChange={(e) => setName(e.target.value)}
      />
      <div className="w-full md:w-1/2 grid grid-cols-2 gap-3 mt-4">
        <SelectComponent options={[]} title="Tipo de passeio" />
        <Input type="time" variant="outline-orange" title="Duração" />
      </div>
      <hr className="w-full bg-primary h-px mt-3 mb-4" />
      <div className="flex gap-2 mt-5 place-self-end">
        <Button
          onClick={() => setShow(false)}
          actionType="button-full-width"
          variant="outline-primary"
        >
          Voltar
        </Button>
        <Button
          onClick={handleAdd}
          actionType="button-full-width"
          variant="primary-strong"
        >
          Adicionar
        </Button>
      </div>
    </Modal>
  );
};

export default InsertTour;
