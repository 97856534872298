import React from 'react';

import { Modal, Card, Spinner } from '..';
import { ApiWhatsInstances } from '../../modules/whatsapp/api';
import {
  connectSocketWithApi,
  deleteConnection,
  listenToInstanceUpdates,
  listenToQrCodeChange,
} from '../../modules/whatsapp/socket';

interface props {
  show: boolean;
  // eslint-disable-next-line no-unused-vars
  close: (e: boolean) => void;
  closeOnConnect: () => void;
  instance: any;
}

export const ModalConnectInstance: React.FC<props> = ({
  show,
  instance,
  close,
  closeOnConnect,
}) => {
  const [loading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState({
    base64: '',
  });

  const handleConnect = (text: any) => {
    if (text === 'Conectada') {
      close(false);
      closeOnConnect();
    }
  };

  const handleQrUpdate = (p: any) => {
    setData({ ...data, base64: p });
  };

  React.useEffect(() => {
    const requestQRCode = async () => {
      setIsLoading(true);
      const qrcodeimage: any =
        await ApiWhatsInstances.updateCompanieWhatsappInstancesStatus(
          {
            action: 'connect',
          },
          instance.id
        );
      setIsLoading(false);

      if (qrcodeimage && qrcodeimage.base64) {
        setData(qrcodeimage);
      }
    };

    if (show === true) {
      requestQRCode();
      if (instance.id) {
        connectSocketWithApi(instance.id);
        listenToInstanceUpdates(handleConnect);
        listenToQrCodeChange(handleQrUpdate);
      }
    } else {
      deleteConnection('received_instance');
    }
  }, [show]);

  return (
    <Modal
      title="Use o WhatsApp no Leadsim"
      isOpen={show}
      setIsOpen={close}
      size="2xlarge"
      minHeight="min-h-[685px]"
    >
      <Card className="flex justify-center items-center ">
        {loading ? (
          <div className="w-full mt-16 flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="flex flex-col gap-3">
            <div className="flex flex-col sm:flex-row sm:justify-between gap-3 w-full">
              <div className="flex flex-col text-sm gap-2">
                <p>1. Abra o WhatsApp em seu celular.</p>
                <p>
                  2. Toque em{' '}
                  <strong className="font-semibold">Mais Opções</strong> ANDROID
                  ou
                  <strong className="font-semibold"> Configurações</strong> no
                  Iphone.
                </p>
                <p>
                  3. Toque em{' '}
                  <strong className="font-semibold">
                    Dispositivos conectados
                  </strong>{' '}
                  e, em seguida, em{' '}
                  <strong className="font-semibold">
                    Conectar um dispositivo.
                  </strong>
                </p>
                <p>
                  4. Aponte seu celular para esta tela para capturar o QR code.
                </p>
              </div>
              <img width={200} src={data.base64} alt="qrcode" />
            </div>
            <div className="flex flex-col gap-3 items-center">
              <p className="text-lg font-light text-gray-600">Tutorial</p>
              <p className="text-xs text-green-600 mb-3">
                Precisa de ajuda para começar?
              </p>
              <iframe
                className="w-full"
                // width="560"
                height="315"
                src="https://www.youtube.com/embed/ZVn_Gqj8Xes?si=oWWWmpKgfhAznK2Z"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
          </div>
        )}
      </Card>
    </Modal>
  );
};
