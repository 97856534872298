import React, { useEffect, useState } from 'react';
import SelectComponent, {
  OptionsSelectProp,
  SelectComponentProps,
} from '../Select/select';
import { ApiOpportunity } from '../../modules/opportunity/api';

const CityInput: React.FC<SelectComponentProps> = (props) => {
  const [citiesOptions, setCitiesOptions] = useState<OptionsSelectProp[]>([]);
  const [citiesLoading, setCitiesLoading] = useState<boolean>(false);
  const [citiesInput, setCitiesInput] = useState('');
  const [citiesInputSearch, setCitiesInputSearch] = useState('');

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setCitiesInputSearch(citiesInput);
    }, 500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [citiesInput]);

  useEffect(() => {
    const fetchCity = async () => {
      try {
        const formatName = citiesInputSearch
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/ç/g, 'c')
          .toLowerCase()
          .replace(' ', '-');

        if (formatName.length === 0) return;

        setCitiesLoading(true);

        const cities = await ApiOpportunity.getCitiesByName(formatName);

        if ('id' in cities) {
          setCitiesOptions([
            {
              label: `${cities.nome} - ${cities.microrregiao.mesorregiao.UF.sigla}`,
              value: cities.nome,
            },
          ]);
        } else {
          setCitiesOptions(
            cities.map((elem) => ({
              label: `${elem.nome} - ${elem.microrregiao.mesorregiao.UF.sigla}`,
              value: elem.nome,
            }))
          );
        }

        setCitiesLoading(false);
      } catch (e) {
        console.error(e);
        setCitiesOptions([]);
        setCitiesLoading(false);
      }
    };
    fetchCity();
  }, [citiesInputSearch]);

  return (
    <SelectComponent
      {...props}
      inputValue={citiesInput}
      isLoading={citiesLoading}
      options={citiesOptions}
      onInputChange={setCitiesInput}
    />
  );
};

export default CityInput;
