import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import EmojiPicker from 'emoji-picker-react';
import { Button, Modal, Spinner, Switch, TextArea } from '../../../components';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError, fileToBase64 } from '../../../helpers/utils.helper';

import { ApiWhatsapp } from '../api';
import IconDictionary from '../../../components/Icons/icons';
import { SelectDynamicOptions } from './dynamicSelect';
import { imagesExtentions } from '../../../constants';
import { getFileBase64Api } from '../../azureFiles/api';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  whatsapp: any | null;
}

export const RegisterWhatsapp: React.FC<registerModalProps> = ({
  whatsapp,
  reloadTable,
  setShow,
  show,
}) => {
  const { notify } = ToastNotify();
  const [openEmoji, setOpenEmoji] = React.useState(false);
  const [emoji, setEmoji] = React.useState<any>(null);
  const [mouseTarget, setMouseTarget] = React.useState<number>(0);

  const emojiRef = useRef<any>(null);
  const triggerBntEmojiRef = useRef<HTMLButtonElement | null>(null);

  const [values, setValues] = useState<Omit<any, 'id'>>({
    text: whatsapp ? whatsapp?.text : '',
    path: whatsapp ? whatsapp?.path : null,
    is_active: whatsapp ? whatsapp?.is_active : false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isNewImage, setIsNewImage] = useState(false);

  const getFilenameFromPath = (filePath: string) => {
    const parts = filePath.split('/');
    return parts[parts.length - 1];
  };
  const getFileExtension = (filename: string) => {
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return null; // Se não houver ponto no nome do arquivo, retorna null ou lance um erro
    }
    return filename.substring(lastDotIndex + 1);
  };

  const checkIfIsImage = (pathFile: string) => {
    const type = getFileExtension(pathFile);

    if (
      type &&
      imagesExtentions.includes(type.toLocaleLowerCase().replace('.', ''))
    ) {
      return true;
    }
    return false;
  };

  const Submit = async () => {
    setIsLoading(true);
    let body = {};
    if (whatsapp !== null) {
      // Modificação da mensagem
      if (isNewImage) {
        body = {
          text: values.text,
          is_active: values.is_active,
          file: {
            originalFileName: values.path.path,
            base64: (await fileToBase64(values.path)).split(',')[1],
          },
        };

        const res = await ApiWhatsapp.updateWhatsappMessage(whatsapp.id, body);
        if (res.id) {
          setIsLoading(false);
          notify({
            message: 'Mensagem atualizada com sucesso',
            type: 'Success',
          });
          reloadTable(`${Math.random()} update`);
          setShow(false);
        } else {
          setIsLoading(false);
          notify({ message: dictionaryError(res), type: 'Error' });
        }
      } else {
        // Verifica se na modificação a imagem foi deletada ou não.
        if (values.path) {
          body = {
            text: values.text,
            is_active: values.is_active,
          };
        } else {
          body = {
            text: values.text,
            is_active: values.is_active,
            file: null,
          };
        }

        const res = await ApiWhatsapp.updateWhatsappMessage(whatsapp.id, body);
        if (res.id) {
          setIsLoading(false);
          notify({
            message: 'Mensagem atualizada com sucesso',
            type: 'Success',
          });
          reloadTable(`${Math.random()} update`);
          setShow(false);
        } else {
          setIsLoading(false);
          notify({ message: dictionaryError(res), type: 'Error' });
        }
      }
    } else {
      // Criação da mensagem
      if (values.path) {
        body = {
          text: values.text,
          is_active: values.is_active,
          file: {
            originalFileName: values.path.path,
            base64: (await fileToBase64(values.path)).split(',')[1],
          },
        };
      } else {
        body = {
          text: values.text,
          is_active: values.is_active,
          file: null,
        };
      }

      const res = await ApiWhatsapp.postWhatsappMessages(body);
      if (res.id) {
        setIsLoading(false);
        notify({ message: 'Mensagem criada com sucesso', type: 'Success' });
        reloadTable(`${Math.random()} create`);
        setShow(false);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
      }

      setIsLoading(false);
    }
  };

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles: File[]) => {
      const fileCreateObj = acceptedFiles.map((f: any) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
          filename: `${Math.floor(Math.random() * 20)}_file`,
        })
      );
      setIsNewImage(true);
      setValues({
        ...values,
        path: fileCreateObj[0],
      });
    },
  });

  const InsertTextAtCursor = (text: string) => {
    const newText = `${values.text.slice(
      0,
      mouseTarget
    )} ${text} ${values.text.slice(mouseTarget)}`;
    setValues({ ...values, text: newText });
    const newCursorPosition = mouseTarget + text.length + 1;
    setMouseTarget(newCursorPosition);
  };

  const fetchBase64 = async (data: any) => {
    if (data.path && checkIfIsImage(data.path)) {
      const base64 = await getFileBase64Api(data.path);
      setValues((prev) => ({
        ...prev,
        base64,
      }));
    }
  };

  useEffect(() => {
    if (show === true) {
      if (whatsapp) {
        setValues({
          id: whatsapp.id ?? '',
          text: whatsapp.text,
          path: whatsapp.path,
          is_active: whatsapp.is_active,
        });
        fetchBase64(whatsapp);
      } else {
        setValues({
          id: '',
          text: '',
          path: null,
          is_active: false,
        });
      }

      setIsNewImage(false);

      setMouseTarget(whatsapp?.text?.length ?? 0);
      setOpenEmoji(false);
    }
  }, [whatsapp, show]);

  useEffect(() => {
    if (emoji !== null) InsertTextAtCursor(emoji);
  }, [emoji]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        emojiRef.current &&
        !emojiRef.current.contains(event.target) &&
        triggerBntEmojiRef.current &&
        !triggerBntEmojiRef.current.contains(event.target)
      ) {
        setOpenEmoji(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title={whatsapp ? 'Atualizar Mensagem' : 'Criar Mensagem'}
      size="2xlarge"
      minHeight="min-h-[500px]"
    >
      <div className="flex flex-col gap-3">
        <TextArea
          onMouseUp={(e: any) => {
            setMouseTarget(e.target.selectionStart);
          }}
          id="text_message_field"
          icon={
            <div className="absolute top-1 right-0 flex">
              {openEmoji && (
                <div
                  ref={emojiRef}
                  className="flex absolute right-[10px] top-[25px] z-20"
                >
                  <EmojiPicker
                    key="emoji_modal"
                    onEmojiClick={(e) => {
                      setEmoji(e.emoji);
                    }}
                  />
                </div>
              )}
              <button
                ref={triggerBntEmojiRef}
                type="button"
                className="w-[30px]"
                onClick={(evt) => {
                  evt.preventDefault();
                  setOpenEmoji(!openEmoji);
                  setEmoji(null);
                }}
              >
                <IconDictionary name="Smile" color="gray" size={20} />
              </button>
            </div>
          }
          className="h-[150px] p-4"
          placeholder="Mensagem"
          value={values.text}
          onFocus={() => {
            setOpenEmoji(false);
          }}
          onChange={(e) => {
            setValues({
              ...values,
              text: e.target.value,
            });
          }}
          onKeyUp={(e: any) => {
            setMouseTarget(e.target.selectionStart);
          }}
        />

        <SelectDynamicOptions
          isContract={false}
          onChange={(event: string) => InsertTextAtCursor(event)}
        />
        <div className="flex flex-col items-center w-full">
          {values.path ? (
            <div className="relative">
              <button
                className="flex items-center justify-center w-5 h-5 bg-white border-red border border-solid rounded-full absolute right-0 top-0 text-red hover:scale-110"
                type="button"
                onClick={() => setValues({ ...values, path: null })}
              >
                <IconDictionary
                  name="Excluir"
                  size={18}
                  title="Remover imagem"
                />
              </button>
              {checkIfIsImage(values.path?.name || values.path) ? (
                <img
                  style={{
                    opacity: isDragActive ? 0.5 : 1,
                    maxHeight: 250,
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                  src={values.path?.preview || values.path}
                  alt="img"
                />
              ) : (
                <div className="mt-5 flex flex-col gap-2 w-full items-center text-xs">
                  <IconDictionary name="Arquivo" className="w-10 h-10" />
                  <p>
                    {getFilenameFromPath(
                      `${values.path.path}` ?? values.path?.name
                    )}
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div
              {...getRootProps()}
              className={classNames('cursor-pointer', {
                'border-[1px] border-dashed border-[#aaa] p-4 rounded-md bg-background mb-4 relative w-full h-24 flex items-center justify-center':
                  !values.path,
                'border-[#DC7E19] bg-primary-400/10': isDragActive,
              })}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p className="font-light text-sm text-primary">
                  Solte o arquivo aqui...
                </p>
              ) : (
                <p className="font-light text-sm text-center text-[#555]">
                  Arraste e solte aqui ou clique para selecionar um arquivo
                </p>
              )}
            </div>
          )}
        </div>
        <div>
          <p className="text-sm font-normal mb-2">Status</p>
          <Switch
            onChange={() =>
              setValues({ ...values, is_active: !values.is_active })
            }
            state={values.is_active}
          />
        </div>

        <Button
          disabled={values.text.length === 0 || isLoading}
          variant="primary-strong"
          onClick={Submit}
          className="font-bold relative flex items-center justify-center mt-5 w-64 mx-auto"
        >
          Salvar
          {isLoading && (
            <div className="absolute right-0">
              <Spinner />
            </div>
          )}
        </Button>
      </div>
    </Modal>
  );
};
