import React from 'react';

import {
  CompanieWhatsappInstancesProps,
  ResultPlans,
  ModalProps,
  CreateContextProps,
  ProviderProps,
} from '../types';

import { ApiWhatsInstances, adminWhatsappPlansActive } from '../api';
import { formatInstance } from '../utils';
import { FinancialProps } from '../../financial/types';

const Context = React.createContext<CreateContextProps>(
  {} as CreateContextProps
);

export const Provider: React.FC<ProviderProps> = ({
  children,
}: ProviderProps) => {
  const [instances, setInstances] = React.useState<
    CompanieWhatsappInstancesProps[]
  >([]);
  const [whatsappPlans, setWhatsappPlans] = React.useState({} as ResultPlans);
  const [listPlans, setListPlans] = React.useState([] as any);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [reload, setReload] = React.useState('');
  const [reloadUserPlan, setReloadUserPlans] = React.useState('');
  const [financialData, setFinancialData] = React.useState<FinancialProps[]>(
    []
  );

  const [modal, setModal] = React.useState<ModalProps>({
    show: 'undefined',
    data: {},
  });

  const fetchAllPlansActive = async () => {
    const plansActive = await adminWhatsappPlansActive();
    setListPlans(plansActive);
  };

  const fetchUserPlan = async () => {
    const data = await formatInstance();
    if (!data || data.status !== 'Ativo') {
      await fetchAllPlansActive();
    }
    if (!data) {
      setModal({ show: 'purchase' });
      return null;
    }
    setFinancialData(data.financial);
    if (data.status === 'Inadimplente') {
      setModal({ data, show: 'negociate' });
      return data;
    }
    if (data.disable) {
      setModal({ show: 'purchase' });
      setWhatsappPlans({} as any);
    } else if (data.disablePurchase) {
      setModal({ show: 'warn' });
      setWhatsappPlans(data);
    } else {
      setWhatsappPlans(data);
    }

    return data;
  };

  const loadInstances = async (id: number) => {
    setIsLoading(true);
    const companieInstances =
      await ApiWhatsInstances.getCompanieWhatsappInstances(id);
    setInstances(companieInstances);
    setIsLoading(false);
  };

  const init = async () => {
    setIsLoading(true);
    const data = await fetchUserPlan();
    if (data && data.id) {
      loadInstances(data.id);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (whatsappPlans.id) {
      loadInstances(whatsappPlans.id);
    }
  }, [reload]);

  React.useEffect(() => {
    fetchUserPlan();
  }, [reloadUserPlan]);

  React.useEffect(() => {
    init();
  }, []);

  // const value = React.useMemo<any>(() => ({ data }), []);

  return (
    <Context.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        instances,
        whatsappPlans,
        modal,
        isLoading,
        listPlans,
        reload,
        setModal,
        setReload,
        setReloadUserPlans,
        setInstances,
        setWhatsappPlans,
        financialData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export function useWppContext() {
  const context = React.useContext(Context);
  return context;
}
