/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Input,
  Modal,
  SelectComponent,
  TextArea,
} from '../../../../../../components';
import TotalTable from '../Table/totalTable';
import { ApartmentItemProps, apartmentProps, hotelsProps } from '../types';
import { OptionsSelectProp } from '../../../../../../components/Select/select';
import maskHelper from '../../../../../../helpers/mask.helper';

const Title = {
  adult: 'Adulto',
  child: 'Criança',
  baby: 'Bebê',
};

const FieldsValuesApartment = ({
  elem,
  handleChanges,
  i,
  index,
  keyType,
}: {
  keyType: 'adult' | 'baby' | 'child';
  elem: ApartmentItemProps;
  index: number;
  i: number;
  handleChanges: (
    e: any,
    iAdult: number,
    keyInput: 'adult' | 'baby' | 'child',
    keyValue: 'value' | 'tax' | 'ravVar' | 'ravFix',
    iApartment: number
  ) => void;
}) => (
  <div className="grid grid-cols-2 md:grid-cols-5 gap-2">
    <Input
      defaultValue={maskHelper.formatCurrencyInput(String(elem.value))}
      variant="outline-orange"
      title={`Valor ${Title[keyType]}`}
      onChange={(e) => {
        e.target.value = maskHelper.formatCurrencyInput(e.target.value);
      }}
      onBlur={(e) => handleChanges(e.target.value, i, keyType, 'value', index)}
    />
    <Input
      variant="outline-orange"
      title="Taxas"
      onChange={(e) => {
        e.target.value = maskHelper.formatCurrencyInput(e.target.value);
      }}
      onBlur={(e) => handleChanges(e.target.value, i, keyType, 'tax', index)}
    />
    <Input
      variant="outline-orange"
      title="Rav. variável"
      onChange={(e) => {
        e.target.value = maskHelper.formatCurrencyInput(e.target.value);
      }}
      onBlur={(e) => handleChanges(e.target.value, i, keyType, 'ravVar', index)}
    />
    <Input
      variant="outline-orange"
      title="Rav. fixa"
      onChange={(e) => {
        e.target.value = maskHelper.formatCurrencyInput(e.target.value);
      }}
      onBlur={(e) => handleChanges(e.target.value, i, keyType, 'ravFix', index)}
    />
    <Input
      value={maskHelper.formatCurrencyInput(
        String(elem.value + elem.ravFix + elem.ravVar + elem.tax)
      )}
      disabled
      variant="outline-orange"
      title="Total"
    />
  </div>
);

interface generalProps {
  show: boolean;
  setShow: (e: boolean) => void;
  insert: (
    name: string,
    obs: string,
    reg: string,
    value?: number,
    tax?: number,
    ravVar?: number,
    ravFix?: number
  ) => void;
  mode: 'apartment' | 'total';
  apartments: apartmentProps[];
  editApartment: (index: number, e: apartmentProps) => void;
  defaultValues?: hotelsProps;
}

const InsetHotel: React.FC<generalProps> = ({
  mode,
  show,
  setShow,
  insert,
  apartments,
  defaultValues,
  editApartment,
}) => {
  const titleModal =
    mode === 'total' ? 'Inserir hotel - Total' : 'Inserir hotel - Apartamento';

  const { handleSubmit, setValue } = useForm<hotelsProps>({
    defaultValues,
  });

  const [hotelCost, setHotelCost] = useState({
    name: defaultValues?.name,
    obs: defaultValues?.obs,
    value: defaultValues?.value ?? 0,
    tax: defaultValues?.tax ?? 0,
    ravVar: defaultValues?.ravVar ?? 0,
    ravFix: defaultValues?.ravFix ?? 0,
  });

  const Submit = (e: hotelsProps) => {
    if (!defaultValues)
      insert(
        e.name,
        e.obs,
        e.reg,
        e.value ?? 0,
        e.tax ?? 0,
        e.ravVar ?? 0,
        e.ravFix ?? 0
      );
    setShow(false);
  };

  const handleChanges = (
    e: any,
    iAdult: number,
    key: 'adult' | 'baby' | 'child',
    keyValue: 'value' | 'tax' | 'ravVar' | 'ravFix',
    iApartment: number
  ) => {
    apartments[iApartment][key][iAdult][keyValue] = Number(
      maskHelper.number(e)
    );
    editApartment(iApartment, apartments[iApartment]);
  };

  const getTotalValue = (
    currentAp: apartmentProps,
    keyValue: 'value' | 'tax' | 'ravVar' | 'ravFix'
  ) => {
    const sum =
      currentAp.adult.reduce((p, c) => c[keyValue] + p, 0) +
      currentAp.baby.reduce((pr, cur) => cur[keyValue] + pr, 0) +
      currentAp.child.reduce((pre, curre) => curre[keyValue] + pre, 0);

    return sum;
  };

  const getTotalValues = () =>
    hotelCost.value / 100 +
    hotelCost.tax / 100 +
    hotelCost.ravVar / 100 +
    hotelCost.ravFix / 100;

  useEffect(() => {
    if (show)
      setHotelCost({
        name: defaultValues?.name,
        obs: defaultValues?.obs,
        value: defaultValues?.value ?? 0,
        tax: defaultValues?.tax ?? 0,
        ravVar: defaultValues?.ravVar ?? 0,
        ravFix: defaultValues?.ravFix ?? 0,
      });
  }, [show]);

  return (
    <Modal size="6xlarge" title={titleModal} isOpen={show} setIsOpen={setShow}>
      <form onSubmit={handleSubmit(Submit)}>
        <div className="flex flex-wrap sm:flex-nowrap gap-2 w-full xl:w-1/2">
          <Input
            title="Nome do hotel"
            variant="outline-orange"
            classNameDiv="w-full sm:w-1/2 xl:w-52"
            defaultValue={hotelCost.name}
            onBlur={(e) => setValue('name', e.target.value)}
          />
          <SelectComponent
            classNameDiv="w-full sm:w-1/2 xl:w-52"
            options={[]}
            title="Reg. alimentação"
            onChange={(e: OptionsSelectProp) => setValue('reg', e.value)}
          />
        </div>
        <TextArea
          defaultValue={hotelCost.obs}
          title="Observações"
          onBlur={(e) => setValue('obs', e.target.value)}
        />
        <br />
        {mode === 'total' ? (
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-2">
            <Input
              id="value_hotel"
              defaultValue={maskHelper.formatMoeda(hotelCost.value)}
              variant="outline-orange"
              title="Valor"
              onChange={(e) => {
                e.target.value = maskHelper.formatCurrencyInput(e.target.value);
              }}
              onBlur={(e) => {
                setValue('value', Number(maskHelper.number(e.target.value)));
                setHotelCost({
                  ...hotelCost,
                  value: Number(maskHelper.number(e.target.value)),
                });
              }}
            />
            <Input
              id="tax_hotel"
              defaultValue={maskHelper.formatMoeda(hotelCost.tax)}
              variant="outline-orange"
              title="Taxas"
              onChange={(e) => {
                e.target.value = maskHelper.formatCurrencyInput(e.target.value);
              }}
              onBlur={(e) => {
                setValue('tax', Number(maskHelper.number(e.target.value)));
                setHotelCost({
                  ...hotelCost,
                  tax: Number(maskHelper.number(e.target.value)),
                });
              }}
            />
            <Input
              id="ravVar_hotel"
              defaultValue={maskHelper.formatMoeda(hotelCost.ravVar)}
              variant="outline-orange"
              title="Rav. variável"
              onChange={(e) => {
                e.target.value = maskHelper.formatCurrencyInput(e.target.value);
              }}
              onBlur={(e) => {
                setValue('ravVar', Number(maskHelper.number(e.target.value)));
                setHotelCost({
                  ...hotelCost,
                  ravVar: Number(maskHelper.number(e.target.value)),
                });
              }}
            />
            <Input
              defaultValue={maskHelper.formatMoeda(hotelCost.ravFix)}
              id="ravFix_hotel"
              variant="outline-orange"
              title="Rav. fixa"
              onChange={(e) => {
                e.target.value = maskHelper.formatCurrencyInput(e.target.value);
              }}
              onBlur={(e) => {
                setValue('ravFix', Number(maskHelper.number(e.target.value)));
                setHotelCost({
                  ...hotelCost,
                  ravFix: Number(maskHelper.number(e.target.value)),
                });
              }}
            />
            <Input
              variant="outline-orange"
              title="Total"
              disabled
              value={maskHelper.formatMoeda(getTotalValues())}
            />
          </div>
        ) : (
          <div className="max-h-[400px] overflow-y-auto overflow-x-hidden">
            {apartments.map((item, index) => (
              <>
                <p className="font-semibold text-sm mb-2">
                  Apartamento {(index + 1).toString().padStart(2, '0')}
                </p>
                {item.adult.map((adult, i) => (
                  <FieldsValuesApartment
                    elem={adult}
                    handleChanges={handleChanges}
                    i={i}
                    index={index}
                    keyType="adult"
                  />
                ))}
                {item.child.map((child, i) => (
                  <FieldsValuesApartment
                    elem={child}
                    handleChanges={handleChanges}
                    i={i}
                    index={index}
                    keyType="child"
                  />
                ))}
                {item.baby.map((baby, i) => (
                  <FieldsValuesApartment
                    elem={baby}
                    handleChanges={handleChanges}
                    i={i}
                    index={index}
                    keyType="baby"
                  />
                ))}

                <TotalTable
                  type={mode}
                  fix={getTotalValue(item, 'ravFix')}
                  tax={getTotalValue(item, 'tax')}
                  value={getTotalValue(item, 'value')}
                  variable={getTotalValue(item, 'ravVar')}
                />
                <br />
              </>
            ))}
          </div>
        )}
        <div className="flex justify-center mt-6 gap-3">
          <Button
            variant="outline-primary"
            actionType="button-loading"
            onClick={() => setShow(false)}
          >
            Voltar
          </Button>
          <Button type="submit" actionType="button-loading">
            Salvar
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default InsetHotel;
