import React, { useEffect, useState } from 'react';
import { Button, Modal, Table } from '../../../../../components';
import IconDictionary from '../../../../../components/Icons/icons';
import { ToastNotify } from '../../../../../components/Toast/toast';
import { ApiClient } from '../../../api';
import RegisterContact from './RegisterContactInClient';
import { ClientContactData } from '../../../types';
import Spinner from '../../../../../components/Spinner/spinner';

interface ClientContactListProps {
  clientId: number;
  isLoading: boolean;
}

const ClientContactList: React.FC<ClientContactListProps> = ({
  clientId,
  isLoading,
}) => {
  const { notify } = ToastNotify();
  const [showDelete, setShowDelete] = useState(false);
  const [currentContact, setCurrentContact] =
    useState<ClientContactData | null>(null);
  const [showCreateUpdate, setShowCreateUpdate] = useState({
    open: false,
    edit: false,
  });

  const [contactsFetch, setContactsFetch] = useState<ClientContactData[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const columns = [
    {
      name: 'Nome',
      key: 'nome',
      selector: (row: ClientContactData) => row.name,
    },
    {
      name: 'Organização',
      key: 'organization',
      selector: (row: ClientContactData) => row.organization,
    },
    {
      name: 'WhatsApp',
      key: 'whatsapp',
      selector: (row: ClientContactData) => row.whatsapp,
      cell: (row: ClientContactData) => row.whatsapp,
    },
    {
      name: 'E-mail',
      key: 'email',
      selector: (row: ClientContactData) => row.email,
      hide: 800,
    },
    ...(contactsFetch.length > 0
      ? [
          {
            name: '',
            width: '50px',
            cell: (row: ClientContactData) => (
              <IconDictionary
                color="#DA3C3C"
                name="Excluir"
                size={20}
                className="cursor-pointer"
                onClick={() => {
                  setCurrentContact(row);
                  setShowDelete(true);
                }}
              />
            ),
          },
        ]
      : []),
  ];

  const fetchContactsByClientId = async (clientIdFilter: number) => {
    try {
      const allContacts = await ApiClient.getAllClientContacts();

      const filteredContacts = allContacts.filter(
        (contact) => contact.client_id === clientIdFilter
      );

      setContactsFetch(filteredContacts);
    } catch (error) {
      console.error('Erro ao buscar contatos:', error);
      notify({ message: 'Erro ao buscar contatos.', type: 'Error' });
    }
  };

  useEffect(() => {
    fetchContactsByClientId(clientId);
  }, [clientId]);

  const reloadContacts = () => {
    fetchContactsByClientId(clientId);
  };

  const handleDelete = async () => {
    if (!currentContact) return;

    const { id, name } = currentContact;

    if (id === undefined) {
      notify({
        message: 'ID do contato não está definido.',
        type: 'Error',
      });
      return;
    }

    setIsDeleting(true);
    try {
      await ApiClient.deleteClientContact(id);
      notify({
        message: `O contato "${name}" foi removido com sucesso.`,
        type: 'Success',
      });
      setShowDelete(false);
      reloadContacts();
    } catch (error: any) {
      console.error('Erro na exclusão do contato:', error);
      notify({
        message: error.message || 'Erro ao remover o contato.',
        type: 'Error',
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="w-full flex flex-col px-4">
      <RegisterContact
        show={showCreateUpdate.open}
        setShow={(show: boolean) =>
          setShowCreateUpdate((prev) => ({ ...prev, open: show }))
        }
        edit={showCreateUpdate.edit}
        contactData={currentContact || undefined}
        onSuccess={reloadContacts}
        clientId={clientId}
      />

      <div className="mt-4">
        <Button
          className="w-52 mb-4"
          actionType="button-add"
          variant="primary"
          onClick={() => {
            setShowCreateUpdate({ open: true, edit: false });
            setCurrentContact(null);
          }}
        >
          Adicionar Contato
        </Button>
      </div>

      <Table
        columns={columns}
        data={contactsFetch}
        selectedRowId={-1}
        onRowClick={(row: ClientContactData) => {
          setCurrentContact(row);
          setShowCreateUpdate({ open: true, edit: true });
        }}
        isLoading={isLoading}
      />

      {currentContact && showDelete && (
        <Modal
          setIsOpen={() => setShowDelete(false)}
          title="Remover Contato"
          isOpen={showDelete}
          size="2xlarge"
          minHeight="min-h-[300px]"
        >
          <strong className="text-center text-lg">
            Tem certeza que deseja remover {currentContact.name}?
          </strong>
          <p className="text-sm mt-4 mb-10">
            Esta ação não pode ser desfeita. O contato será removido dos nossos
            serviços.
          </p>
          <div className="flex gap-3 w-full">
            <Button
              variant="primary-strong"
              onClick={() => setShowDelete(false)}
              className="w-1/2"
            >
              Cancelar
            </Button>
            <Button
              className="w-1/2 relative flex items-center justify-center hover:bg-gray/40 disabled:border-[#ddd] disabled:text-gray-600"
              variant="outline-primary"
              disabled={isDeleting}
              onClick={handleDelete}
            >
              Remover{' '}
              {isDeleting && (
                <div className="absolute right-0">
                  <Spinner />
                </div>
              )}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ClientContactList;
