/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import DatePicker from 'react-date-picker';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button, Modal, SelectComponent } from '../../../components';
import maskHelper from '../../../helpers/mask.helper';
import { OpportunityProps } from '../../opportunity/types';
import { ApiTransferOpportunity } from '../api';
import { filtersInformationProps } from '../types';
import { useFetchStep } from '../../funnel/hooks';

interface propsfiltermodal extends filtersInformationProps {
  start?: Date;
  end?: Date;
  period: string;
}

interface filterProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setTableData: (e: { data: OpportunityProps[]; total: number }) => void;
  companyId: number;
  responseOptions: { label: string; value: number }[];
  productList: { label: string; value: number }[];
  userOptions: { label: string; value: number }[];
  salesChannelOptions: { label: string; value: number }[];
  opportunityList: { label: string; value: number }[];
  FunnelOptions: { label: string; value: number }[];
  filters: propsfiltermodal;
  setFilters: (e: propsfiltermodal) => void;
}

const FilterModal: React.FC<filterProps> = ({
  companyId,
  opportunityList,
  productList,
  responseOptions,
  salesChannelOptions,
  FunnelOptions,
  filters,
  setTableData,
  setShow,
  setFilters,
  show,
  userOptions,
}) => {
  const defaultValues = {
    companieUserId: [],
    funnelId: null,
    opportunityId: null,
    responses_win_lose_id: [],
    salesChannelId: [],
    productId: [],
    order: 'title_asc',
    status: 'Todos',
    skip: 0,
    take: 30,
    period: 'all',
    funnelStepId: null,
  };

  const [isLoading, setIsLoading] = useState(false);
  const schema = yup.object().shape({
    order: yup.string().required('Selecione uma opção'),
    status: yup.string().required('Selecione uma opção'),
  });

  const { formState, handleSubmit, setValue, reset } =
    useForm<propsfiltermodal>({
      resolver: yupResolver(schema),
      defaultValues: {
        funnelStepId: null,
        opportunityId: null,
        productId: [],
        salesChannelId: [],
        responses_win_lose_id: [],
        companieUserId: [],
        funnelId: null,
        order: 'title_asc',
        status: 'Todos',
      },
    });

  const orderOptions = [
    { value: 'title_asc', label: 'Alfabética crescente' },
    { value: 'title_desc', label: 'Alfabética decrescente' },
    { value: 'created_at_asc', label: 'Data crescente' },
    { value: 'created_at_desc', label: 'Data decrescente' },
  ];

  const statusOptions = [
    { value: 'Aberta', label: 'Aberta' },
    { value: 'Ganha', label: 'Ganha' },
    { value: 'Perdida', label: 'Perdida' },
    { value: 'Todos', label: 'Todos' },
  ];

  const { StepList } = useFetchStep('', filters.funnelId ?? -1);
  const optionsSteps = StepList.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const Submit = async (e: filtersInformationProps) => {
    let period = 'all';
    if (filters.start && filters.end) {
      period = `other_period/${maskHelper.formatDateYMD(
        filters.start.toLocaleDateString('pt-br')
      )}/${maskHelper.formatDateYMD(
        filters.end.toLocaleDateString('pt-br') ?? ''
      )}`;
    }

    setIsLoading(true);
    const opportunity = await ApiTransferOpportunity.filterOpportunities(
      companyId,
      period,
      {
        companieUserId: e.companieUserId,
        funnelId: e.funnelId,
        opportunityId: e.opportunityId,
        order: e.order,
        productId: e.productId,
        responses_win_lose_id: e.responses_win_lose_id,
        salesChannelId: e.salesChannelId,
        skip: 0,
        take: 30,
        status: e.status,
        funnelStepId: e.funnelStepId,
      }
    );
    if (typeof opportunity !== 'string') {
      setTableData(opportunity);
      setShow(false);
    }

    setIsLoading(false);
  };

  const CleanFilters = () => {
    reset();
    setFilters(defaultValues);
    setShow(false);
  };

  return (
    <Modal
      title="Filtrar oportunidades"
      isOpen={show}
      setIsOpen={setShow}
      size="2xlarge"
      minHeight="min-h-[685px]"
    >
      <form
        className="flex flex-col sm:grid sm:grid-cols-2 gap-3"
        onSubmit={handleSubmit(Submit)}
      >
        <SelectComponent
          value={orderOptions.find((item) => item.value === filters.order)}
          title="Ordenar"
          options={orderOptions}
          closeMenuOnSelect
          onChange={(e: any) => {
            setValue('order', e.value);
            setFilters({ ...filters, order: e.value });
          }}
          errorMensage={formState.errors.order?.message}
        />

        <SelectComponent
          value={statusOptions.find((item) => item.value === filters.status)}
          title="Status"
          options={statusOptions}
          closeMenuOnSelect
          onChange={(e: any) => {
            setValue('status', e.value);
            setFilters({ ...filters, status: e.value });
          }}
          errorMensage={formState.errors.status?.message}
        />
        <SelectComponent
          value={responseOptions.filter(
            (item) =>
              filters.responses_win_lose_id.includes(item.value) === true
          )}
          hasDivision
          title="Respostas"
          options={responseOptions}
          isMulti
          closeMenuOnSelect={false}
          onChange={(e: any[]) => {
            setValue(
              'responses_win_lose_id',
              e.map((res) => res.value)
            );
            setFilters({
              ...filters,
              responses_win_lose_id: e.map((res) => res.value),
            });
          }}
        />
        <SelectComponent
          value={productList.filter(
            (item) => filters.productId.includes(item.value) === true
          )}
          title="Produto"
          options={productList}
          closeMenuOnSelect={false}
          isMulti
          onChange={(e: any[]) => {
            setValue(
              'productId',
              e.map((product) => product.value)
            );
            setFilters({
              ...filters,
              productId: e.map((res) => res.value),
            });
          }}
        />

        <SelectComponent
          value={userOptions.filter(
            (item) => filters.companieUserId.includes(item.value) === true
          )}
          title="Usuários"
          options={userOptions}
          isMulti
          closeMenuOnSelect={false}
          onChange={(e: any[]) => {
            setValue(
              'companieUserId',
              e.map((user) => user.value)
            );
            setFilters({
              ...filters,
              companieUserId: e.map((res) => res.value),
            });
          }}
        />

        <SelectComponent
          value={opportunityList.find(
            (item) => item.value === filters.opportunityId
          )}
          title="Oportunidade"
          maxOptionsHeight="120px"
          options={opportunityList}
          closeMenuOnSelect
          onChange={(e: any) => {
            setValue('opportunityId', e.value);
            setFilters({
              ...filters,
              opportunityId: e.value,
            });
          }}
        />

        <SelectComponent
          value={FunnelOptions.find((item) => item.value === filters.funnelId)}
          title="Funil"
          maxOptionsHeight="120px"
          options={FunnelOptions}
          closeMenuOnSelect
          onChange={(e: any) => {
            setValue('funnelId', e.value);
            setFilters({ ...filters, funnelId: e.value });
          }}
        />

        <SelectComponent
          value={optionsSteps.find(
            (item) => item.value === filters.funnelStepId
          )}
          title="Etapa"
          isDisabled={!filters.funnelId}
          maxOptionsHeight="120px"
          options={optionsSteps}
          closeMenuOnSelect
          onChange={(e: any) => {
            setValue('funnelStepId', e.value);
            setFilters({ ...filters, funnelStepId: e.value });
          }}
        />
        <SelectComponent
          value={salesChannelOptions.filter(
            (item) => filters.salesChannelId.includes(item.value) === true
          )}
          title="Canal de venda"
          maxOptionsHeight="120px"
          options={salesChannelOptions}
          closeMenuOnSelect={false}
          isMulti
          onChange={(e: any[]) => {
            setValue(
              'salesChannelId',
              e.map((sale) => sale.value)
            );
            setFilters({
              ...filters,
              salesChannelId: e.map((res) => res.value),
            });
          }}
        />

        <div className="flex flex-col mb-1.5 ">
          <p className="text-sm mb-1">Período</p>
          <div className="flex w-full">
            <DatePicker
              openCalendarOnFocus
              format="dd/MM/yyyy"
              className="start_date_input_gray w-full"
              onChange={(e: Date) => {
                setFilters({ ...filters, start: e });
              }}
              value={filters.start}
            />
            <p className="bg-primary min-w-[44px] h-11 flex items-center justify-center text-xs text-white">
              Até
            </p>
            <DatePicker
              calendarClassName="z-50"
              openCalendarOnFocus
              format="dd/MM/yyyy"
              className="end_date_input_gray w-full"
              onChange={(e: Date) => {
                setFilters({ ...filters, end: e });
              }}
              value={filters.end}
              minDate={filters.start}
            />
          </div>
        </div>
        <div className="flex gap-3 col-span-2 justify-center w-full mt-4">
          <Button
            variant="outline-primary"
            actionType="button-loading"
            onClick={CleanFilters}
          >
            Limpar Filtros
          </Button>
          <Button
            disabled={isLoading}
            variant="primary-strong"
            type="submit"
            actionType="button-loading"
          >
            Filtrar
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default FilterModal;
