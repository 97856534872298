import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Modal, Card, SelectComponent, Button } from '../../../../components';
import { useWppContext } from '../../context';
import maskHelper from '../../../../helpers/mask.helper';

import { purchaseUpdatePlan } from '../../api';

export const PurchaseModal: React.FC = () => {
  const navigate = useNavigate();

  const { modal, setModal, setReloadUserPlans, whatsappPlans, listPlans } =
    useWppContext();

  const [loading, setLoading] = React.useState(false);
  const [selectPlan, setSelectPlan] = React.useState({} as any);

  const listPlan = listPlans
    .filter((fil: any) =>
      Number(whatsappPlans.value) > 0 ? fil.qty > (whatsappPlans.qty || 0) : fil
    )
    .sort((a: any, b: any) => a.qty - b.qty)
    .map((map: any) => ({
      id: map.id,
      qty: map.qty,
      value: map.value,
      label: `${map.name} - ${map.qty} instâncias`,
    }));

  const Purchase = () => {
    setLoading(true);

    purchaseUpdatePlan(whatsappPlans, selectPlan)
      .then((plan) => {
        setReloadUserPlans(`${Math.random()}_key`);
        setModal({ show: 'warn' });
        window.open(`${plan.subscription.Subscription.paymentLink}`, '_blank');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title="Contratar Plano"
      isOpen={modal.show === 'purchase'}
      setIsOpen={() => {
        if (Number(whatsappPlans.value) > 0) {
          setModal({ show: 'undefined' });
        } else {
          navigate('/');
        }
      }}
      size="medium"
      minHeight="min-h-[250px]"
    >
      <Card className="flex-col w-full pt-5 sm:px-4 pb-3.5 gap-4 sm:min-w-[350px]">
        <SelectComponent
          variant="outline-primary"
          value={selectPlan}
          className="w-full"
          options={listPlan}
          closeMenuOnSelect
          onChange={setSelectPlan}
          maxOptionsHeight="300px"
        />
        <div className="flex items-center justify-center">
          <hr className="h-[1px] w-5 sm:w-[50px] bg-black mr-2" />
          <p className="text-sm">
            Valor total pelas{' '}
            <strong className="text-test_orange">
              {selectPlan.qty || '0'} instâncias
            </strong>
          </p>
          <hr className="h-[1px] w-5 sm:w-[50px] bg-black ml-2" />
        </div>
        <div className="flex items-center justify-center">
          <p className="text-sm ml-[-20px]">
            R${' '}
            <strong className="text-4xl text-test_orange">
              {maskHelper.formatMoeda(selectPlan.value).replace('R$', '')}
            </strong>
          </p>
        </div>
        <p className="text-xs mt-2">
          A compra das instancias, gera uma assinatura recorrente mensal e só
          poderá ser pago com o cartão de crédito. <br /> <br />
          Obs: Não existe fidelidade, podendo ser cancelado a qualquer momento.
        </p>
        <div className="flex gap-2 -mx-4">
          <Button
            disabled={loading}
            variant="outline-primary"
            actionType="button-loading"
            className="w-full bg-test_orange my-1"
            onClick={() => navigate('/')}
          >
            <strong className="text-sm font-medium">Sair</strong>
          </Button>
          <Button
            type="submit"
            isLoading={loading}
            disabled={loading || !selectPlan?.id}
            actionType="button-loading"
            className="w-full bg-test_orange my-1"
            onClick={Purchase}
          >
            <strong className="text-sm font-medium">Confirmar Compra</strong>
          </Button>
        </div>
      </Card>
    </Modal>
  );
};
