/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  Button,
  Input,
  InputDate,
  SelectComponent,
} from '../../../../../../components';
import maskHelper from '../../../../../../helpers/mask.helper';
import { dictionaryError } from '../../../../../../helpers/utils.helper';
import { OfferProps, createOfferProps } from '../../../../types';
import { useFetchClientDropdown } from '../../../../../clients/hooks';
import { ApiOffer } from '../../../../api';
import cpfValidation from '../../../../../../helpers/validations/cpf.validation';
import cnpjValidation from '../../../../../../helpers/validations/cnpj.validation';
import phoneValidate from '../../../../../../helpers/validations/phone.validate';
import { useFetchContact } from '../../../../../contacts/hooks';
import { ContactOpportunity } from '../../../../../opportunity/types';
import IconDictionary from '../../../../../../components/Icons/icons';
import { ApartmentItemProps, apartmentProps, hotelsProps } from '../types';
import TotalTable from '../Table/totalTable';

const ApartmentTable = ({
  item,
  i,
  title,
}: {
  item: ApartmentItemProps[];
  i: number;
  title: string;
}) => {
  const value = item.reduce((prev, curr) => prev + curr.value, 0);
  const tax = item.reduce((prev, curr) => prev + curr.tax, 0);
  const variable = item.reduce((prev, curr) => prev + curr.ravVar, 0);

  const fix = item.reduce((prev, curr) => prev + curr.ravFix, 0);

  const total = value + tax + variable + fix;
  return (
    <div className="px-5 mb-3">
      <div className="grid grid-cols-5 w-full">
        <p className="flex gap-1">
          Valor {title}
          <strong>{maskHelper.formatCurrencyInput(String(value))}</strong>
        </p>
        <p className="flex gap-1">
          Taxas:
          <strong>{maskHelper.formatCurrencyInput(String(tax))}</strong>
        </p>
        <p className="flex gap-1">
          Rav. variável:
          <strong>{maskHelper.formatCurrencyInput(String(variable))}</strong>
        </p>
        <p className="flex gap-1">
          Rav. fixa:
          <strong>{maskHelper.formatCurrencyInput(String(fix))}</strong>
        </p>
        <p className="flex gap-1">
          Total:
          <strong>{maskHelper.formatCurrencyInput(String(total))}</strong>
        </p>
      </div>
    </div>
  );
};

interface generalProps {
  current: boolean;
  mode: 'total' | 'apartment';
  handleDelete: (index: number) => void;
  handleEdit: (elem: hotelsProps) => void;
  handleInsert: () => void;
  hotels: hotelsProps[];
  setShowInsertHotel: (e: boolean) => void;
}

const Hotel: React.FC<generalProps> = ({
  current,
  mode,
  setShowInsertHotel,
  handleDelete,
  handleEdit,
  hotels,
  handleInsert,
}) => {
  const [toggleList, setToggleList] = useState(false);
  const todayDate = new Date();
  const yesterdayDate = new Date(todayDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  const category = [
    { value: 'feminino', label: 'Feminino' },
    { value: 'masculino', label: 'Masculino' },
  ];
  const getTotalValue = (
    item: apartmentProps,
    key: 'value' | 'ravFix' | 'ravVar' | 'tax'
  ) =>
    item.adult.reduce((prev, curr) => prev + Number(curr[key]), 0) +
    item.child.reduce((prev, curr) => prev + Number(curr[key]), 0) +
    item.baby.reduce((prev, curr) => prev + Number(curr[key]), 0);
  return (
    <div className="w-full col-span-3 flex flex-col gap-3 mb-10">
      <div className="max-w-full flex justify-between items-center text-sm">
        <strong className="font-semibold">Inserir hotéis</strong>
        <div className="flex gap-2">
          <Button
            actionType="button-add"
            className="hidden md:flex w-32"
            onClick={() => {
              handleInsert();
              setShowInsertHotel(true);
              setToggleList(true);
            }}
          >
            Inserir Hotel
          </Button>
          <Button
            actionType="button-add"
            className="md:hidden w-14"
            onClick={() => {
              setShowInsertHotel(true);
              setToggleList(true);
            }}
          />
          <button
            type="button"
            className="flex items-center gap-1.5 text-xs py-1 px-2 border border-solid border-gray-400 rounded"
            onClick={() => setToggleList(!toggleList)}
          >
            {toggleList ? (
              <>
                Recolher
                <IconDictionary name="Seta Acima" size={24} />
              </>
            ) : (
              <>
                Expandir
                <IconDictionary name="Seta Abaixo" size={24} />
              </>
            )}
          </button>
        </div>
      </div>

      <div
        className={`-mx-6 flex flex-col overflow-y-hidden  transition-all duration-500 ease-in-out ${
          toggleList ? 'max-h-screen mt-3 h-fit' : 'max-h-0'
        }`}
      >
        <div className="text-xs max-w-full overflow-auto max-h-[400px]">
          <div className="grid grid-cols-9 w-full py-2 bg-gray-200 px-10 min-w-[800px]">
            <div className="col-span-4 font-medium">Nome do hotel</div>
            <div className="font-medium col-span-2">Tipo de Apto</div>
            <div className="font-medium col-span-2">Reg. alimentação</div>
          </div>
          {hotels.map((elem, index) => (
            <>
              <div className="min-w-[800px] grid grid-cols-9 items-center w-full py-3 text-xs px-10 border-b border-gray-200 border-solid">
                <div className="col-span-4 font-normal text-nowrap">
                  {elem.name}
                </div>
                <div className="font-normal col-span-2">{elem.type}</div>
                <div className="font-normal col-span-2">{elem.reg}</div>
                <div className="flex gap-2">
                  <button
                    onClick={() => handleEdit(elem)}
                    type="button"
                    className="hover:scale-110 flex items-center justify-center rounded p-1 w-8 h-8 bg-gray-200 text-gray-600"
                  >
                    <IconDictionary name="Editar" />
                  </button>
                  <button
                    onClick={() => handleDelete(index)}
                    type="button"
                    className="hover:scale-110 flex items-center justify-center rounded p-1 w-8 h-8 bg-red/10 text-red"
                  >
                    <IconDictionary name="Lixeira" />
                  </button>
                </div>
              </div>

              {mode === 'apartment' &&
                elem.apartment.map((item, i) => (
                  <div className="flex flex-col min-w-[800px]">
                    <p className="font-semibold text-sm mb-2 text-primary pl-5 mt-3 mb-4">
                      Apartamento {(i + 1).toString().padStart(2, '0')}
                    </p>
                    <ApartmentTable title="adulto" i={i} item={item.adult} />
                    <ApartmentTable title="criança" i={i} item={item.child} />
                    <ApartmentTable title="bebê" i={i} item={item.baby} />
                    <TotalTable
                      type="apartment"
                      fix={getTotalValue(item, 'ravFix')}
                      tax={getTotalValue(item, 'tax')}
                      value={getTotalValue(item, 'value')}
                      variable={getTotalValue(item, 'ravVar')}
                    />
                  </div>
                ))}
            </>
          ))}
          {mode === 'total' && (
            <TotalTable
              type="total"
              fix={hotels.reduce((prev, curr) => prev + Number(curr.ravFix), 0)}
              tax={hotels.reduce((prev, curr) => prev + Number(curr.tax), 0)}
              value={hotels.reduce(
                (prev, curr) => prev + Number(curr.value),
                0
              )}
              variable={hotels.reduce(
                (prev, curr) => prev + Number(curr.ravVar),
                0
              )}
            />
          )}
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default Hotel;
