import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Button, Modal, SelectComponent } from '../../../components';
import { filterPropsContract } from '../types';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  period: string;
  profileId: number;
  restartPagination: () => void;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  userOptions: { label: string; value: number }[];
  clientsOptions: { label: string; value: number }[];
  offerList: { label: string; value: number }[];
  setFilter: (e: filterPropsContract) => void;
  setClientInput: (e: string) => void;
  isLoadingClients: boolean;
  filters: filterPropsContract;
  setInputOpportunity: (e: string) => void;
  isLoadingDropdown: boolean;
}

const SearchContract: React.FC<registerModalProps> = ({
  show,
  setShow,
  offerList,
  clientsOptions,
  userOptions,
  filters,
  period,
  profileId,
  isLoadingClients,
  isLoadingDropdown,
  setInputOpportunity,
  setFilter,
  setClientInput,
  restartPagination,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingClear, setIsLoadingClear] = useState(false);
  const schema = yup.object().shape({
    order: yup.string().required('Selecione uma opção'),
  });

  const { formState, handleSubmit, setValue, reset, control } =
    useForm<filterPropsContract>({
      resolver: yupResolver(schema),
      defaultValues: filters,
    });

  const orderOptions = [
    { value: 'created_at_desc', label: 'Data decrescente' },
    { value: 'created_at_asc', label: 'Data crescente' },
  ];

  const statusOptions = [
    { value: 'Aberto', label: 'Aberto' },
    { value: 'Ag.Pagamento', label: 'Aguardando Pagamento' },
    { value: 'Ativo', label: 'Ativo' },
    { value: 'Inadimplente', label: 'Inadimplente' },
    { value: 'Interrompido', label: 'Interrompido' },
    { value: 'Cancelado', label: 'Cancelado' },
  ];

  const Submit = async (e: filterPropsContract) => {
    setIsLoadingClear(true);
    setFilter(e);
    setShow(false);
    restartPagination();
    setIsLoadingClear(false);
  };

  const ClearData = async () => {
    setIsLoading(true);
    const defaultFilter: filterPropsContract = {
      offerId: [],
      clientId: [],
      companieUserPromoterId: [],
      order: 'created_at_desc',
      status: [],
      filter: period,
      date_end: '',
      date_start: '',
      skip: 0,
      take: 10,
    };
    await Submit(defaultFilter);
    reset(defaultFilter);
    restartPagination();
    setIsLoading(false);
  };
  useEffect(() => {
    reset(filters);
  }, [show]);
  return (
    <Modal
      title="Procurar Contrato"
      isOpen={show}
      setIsOpen={setShow}
      size="2xlarge"
      minHeight="min-h-[500px]"
    >
      <form
        className="flex flex-col sm:grid sm:grid-cols-2 gap-3"
        onSubmit={handleSubmit(Submit)}
      >
        <Controller
          control={control}
          name="status"
          render={({ field }) => (
            <SelectComponent
              classNameDiv="col-span-2"
              title="Status"
              options={statusOptions}
              closeMenuOnSelect
              defaultValue={statusOptions[0]}
              isMulti
              value={field.value}
              maxOptionsHeight="300px"
              onChange={(e: any[]) => {
                setValue('status', e);
              }}
              errorMensage={formState.errors.status?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="order"
          render={({ field }) => (
            <SelectComponent
              title="Ordenar"
              options={orderOptions}
              value={orderOptions.filter((item) => field.value === item.value)}
              defaultValue={orderOptions[0]}
              closeMenuOnSelect
              onChange={(e: any) => {
                setValue('order', e.value);
              }}
              errorMensage={formState.errors.order?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="clientId"
          defaultValue={filters.clientId}
          render={({ field }) => (
            <SelectComponent
              title="Clientes"
              options={clientsOptions}
              value={field.value}
              isMulti
              isLoading={isLoadingClients}
              onInputChange={setClientInput}
              autoComplete
              closeMenuOnSelect={false}
              onChange={(e: any[]) => {
                setValue('clientId', e);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="companieUserPromoterId"
          render={({ field }) => (
            <SelectComponent
              title="Usuários Promotor"
              options={userOptions}
              value={userOptions.filter((item) =>
                field.value.includes(item.value)
              )}
              isDisabled={profileId === 5}
              isMulti
              closeMenuOnSelect={false}
              onChange={(e: any[]) => {
                setValue(
                  'companieUserPromoterId',
                  e.map((user) => user.value)
                );
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="offerId"
          defaultValue={filters.offerId}
          render={({ field }) => (
            <SelectComponent
              value={field.value}
              isLoading={isLoadingDropdown}
              onInputChange={setInputOpportunity}
              autoComplete
              isClearable
              title="Propostas"
              maxOptionsHeight="120px"
              options={offerList}
              isMulti
              closeMenuOnSelect
              onChange={(e: any[]) => {
                setValue('offerId', e);
              }}
            />
          )}
        />
        <div className="col-span-2 w-full flex gap-3 justify-center flex-col sm:flex-row">
          <Button
            disabled={isLoadingClear || isLoading}
            variant="outline-primary"
            onClick={ClearData}
            className="font-bold flex items-center justify-center mt-4 w-64"
          >
            Limpar
          </Button>
          <Button
            disabled={isLoadingClear || isLoading}
            variant="primary-strong"
            actionType="button-loading"
            isLoading={isLoadingClear || isLoading}
            type="submit"
            className="font-bold relative flex items-center justify-center mt-4 w-64"
          >
            Filtrar
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default SearchContract;
