import React, { ReactNode, useState } from 'react';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { Search } from '@styled-icons/material-rounded/Search';
import Card from '../../../../components/Card/card';
import { Button, Input } from '../../../../components';
import IconDictionary from '../../../../components/Icons/icons';
import TableWithPaginationBack from '../../../../components/Table/tableWithPaginationBack';

/* eslint-disable no-unused-vars */
interface MainProps {
  input?: number;
  currentPageTable: number;
  setCurrentPageTable: (e: number) => void;
  startDate: (e: number) => void;
  totalRegisters: number;
  isLoading?: boolean;
  title: string;
  titleCard?: string;
  column: any;
  objectReport?: () => Promise<any[][]>;
  filename?: string;
  FilterComponent?: ReactNode;
  sizeIcon?: number;
  CustomCard?: ReactNode;
  selectedRowId?: number;
  reportAndCreate?: boolean;
  cardsDirection?: 'row' | 'column';
  PlansCustomCard?: ReactNode;
  filterTable: () => any[];
  setModalCreateUpdate?: React.Dispatch<React.SetStateAction<boolean>>;
  setRegisterPage: React.Dispatch<React.SetStateAction<any>>;
  onRowClick?: (e: any) => void;
  search?: (e: string) => void;
  customHead?: ReactNode;
  showRegisterAction?: boolean;
}

const MainPagePagination: React.FC<MainProps> = ({
  title,
  column,
  isLoading,
  objectReport,
  filename,
  FilterComponent,
  CustomCard,
  sizeIcon = 28,
  selectedRowId,
  reportAndCreate = false,
  titleCard = title,
  cardsDirection = 'row',
  totalRegisters,
  currentPageTable,
  input,
  PlansCustomCard,
  customHead,
  setCurrentPageTable,
  startDate,
  filterTable,
  setModalCreateUpdate,
  setRegisterPage,
  onRowClick,
  search,
  showRegisterAction,
}) => {
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const exportToXLS = async () => {
    if (objectReport) {
      setIsLoadingExport(true);
      const exportData = await objectReport();
      if (exportData[0].length > 0) {
        const fileType =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const csvData = [];
        const keys = exportData[0];
        const values = exportData.slice(1, exportData.length);
        let item: any = {};

        for (let j = 0; j < values.length; j++) {
          for (let i = 0; i < keys.length; i++) {
            item[keys[i]] = values[j][i];
          }
          csvData.push(item);
          item = {};
        }

        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, filename + fileExtension);
      }

      setIsLoadingExport(false);
    }
  };

  return (
    <div className="w-full">
      <div className="w-full flex justify-between">
        <div className="flex gap-3 items-center text-primary">
          <IconDictionary name={title} size={sizeIcon} />
          <strong className="text-lg text-black">{title}</strong>
        </div>
      </div>
      <div className="w-full flex flex-col gap-3 mt-8 min-h-full">
        {!customHead ? (
          <>
            {PlansCustomCard}
            <div
              className={`flex flex-col ${
                cardsDirection === 'row'
                  ? 'xl:flex-row'
                  : 'xl:grid xl:grid-cols-2'
              } gap-3.5`}
            >
              {FilterComponent ?? (
                <Card className="flex-col w-full pt-5 px-4 pb-3.5 gap-4">
                  <strong>Pesquisar</strong>
                  <div className="flex flex-col sm:flex-row gap-2 items-center">
                    <Input
                      value={input}
                      onChange={(e) => search && search(e.target.value)}
                      icon={
                        <Search size={18} color="#8C8C8C" className="mr-4" />
                      }
                      placeholder={`Pesquisar ${title}`}
                      className="w-full"
                    />
                  </div>
                </Card>
              )}

              {objectReport ? (
                <>
                  {reportAndCreate && (
                    <Card
                      className={`flex-col w-full ${
                        cardsDirection === 'column' ? 'xl:w-full' : 'xl:w-1/3'
                      } justify-center items-center gap-4 pb-3.5 pt-5`}
                    >
                      <strong>Cadastrar {titleCard}</strong>
                      <Button
                        className="w-40 h-11"
                        onClick={() => {
                          if (setModalCreateUpdate) setModalCreateUpdate(true);
                          setRegisterPage({ title: 'Dados Pessoais', page: 0 });
                        }}
                      >
                        Cadastrar
                      </Button>
                    </Card>
                  )}
                  <Card
                    className={`flex-col w-full ${
                      cardsDirection === 'column' ? 'xl:w-full' : 'xl:w-1/3'
                    }  justify-center items-center gap-4 pb-3.5 pt-5`}
                  >
                    <strong className="text-md">Exportar da tabela</strong>
                    <Button
                      className="max-w-[160px] h-11 relative flex items-center justify-center disabled:opacity-80"
                      variant="primary"
                      onClick={exportToXLS}
                      disabled={isLoadingExport}
                      isLoading={isLoadingExport}
                      actionType="button-loading"
                    >
                      Download
                    </Button>
                  </Card>
                  {CustomCard}
                </>
              ) : (
                <>
                  {CustomCard}
                  {showRegisterAction && (
                    <Card className="flex-col w-full xl:w-1/3 justify-center items-center gap-4 pb-3.5 pt-5">
                      <strong>Cadastrar {title}</strong>
                      <Button
                        className="w-40 h-11"
                        onClick={() => {
                          if (setModalCreateUpdate) setModalCreateUpdate(true);
                          setRegisterPage({ title: 'Dados Pessoais', page: 0 });
                        }}
                      >
                        Cadastrar
                      </Button>
                    </Card>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          customHead
        )}
        <Card className="min-h-[55vh] lg:min-h-[70vh] px-6 pb-8 flex-col w-full overflow-x-auto">
          <TableWithPaginationBack
            setStartDate={startDate}
            totalRegisters={totalRegisters}
            isLoading={isLoading ?? false}
            columns={column}
            data={filterTable()}
            currentPage={currentPageTable}
            setCurrentPage={setCurrentPageTable}
            totalPerPage={10}
            onRowClick={onRowClick}
            selectedRowId={selectedRowId}
          />
        </Card>
        <br />
      </div>
    </div>
  );
};
MainPagePagination.defaultProps = {
  showRegisterAction: true,
};
export default MainPagePagination;
