import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchFunnelByProfile } from '../../funnel/hooks';
import { useFetchSegment } from '../../segment/hooks';
import { useFetchSalesChannel } from '../../salesChannel/hooks';
import { useFetchResponses } from '../../responseWinLose/hooks';
import { useFetchAllProductsActive } from '../../product/hooks';
import { useFetchActivitieType } from '../../activitieType/hooks';

import UpdateOpportunity from './update';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import { FunnelList } from '../../funnel/types';

interface Option {
  value: number;
  label: string;
}

interface ProductOption {
  value: number;
  label: string;
  price: number;
}

const UpdateOpportunityPage = () => {
  const { opportunityId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [funnelOptions, setFunnelOptions] = useState<Option[]>([]);
  const [productOptions, setProductOptions] = useState<ProductOption[]>([]);
  const [activityTypeOptions, setActivityTypeOptions] = useState<Option[]>([]);
  const [salesChannelOptions, setSalesChannelOptions] = useState<Option[]>([]);
  const [segmentOptions, setSegmentOptions] = useState<Option[]>([]);
  const [responseWinLose, setResponseWinLose] = useState<any>([]);
  const [currentFunnel, setCurrentFunnel] = useState<FunnelList | null>(null);
  const accountProps = getTokenAccountInformation();

  const { ProductsList, isLoading: isLoadingProducts } =
    useFetchAllProductsActive('key');
  const { FunnelsList } = useFetchFunnelByProfile('key', accountProps.id);
  const { ActivitieTypeList } = useFetchActivitieType(
    'key',
    accountProps.companie_id
  );
  const { SalesChannelList } = useFetchSalesChannel(
    'key',
    accountProps.companie_id
  );
  const { SegmentList } = useFetchSegment('key', accountProps.companie_id);
  const { ResponsesList } = useFetchResponses('key', accountProps.companie_id);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const mappedFunnels = FunnelsList.filter(
        (item: any) => item.funnel_step.length > 0
      ).map((funnel: any) => ({
        value: funnel.id,
        label: funnel.title,
      }));
      setFunnelOptions(mappedFunnels);

      const selectedFunnel = FunnelsList.find(
        (funnel: FunnelList) => funnel.funnel_step.length > 0
      );
      setCurrentFunnel(selectedFunnel || null);

      const mappedProducts = ProductsList.map((item) => ({
        value: item.id,
        label: item.title,
        price: item.value,
      }));
      setProductOptions(mappedProducts);

      const mappedActivities = ActivitieTypeList.map((sale) => ({
        value: sale.id,
        label: sale.title,
      }));
      setActivityTypeOptions(mappedActivities);

      const mappedSalesChannels = SalesChannelList.map((sale) => ({
        value: sale.id,
        label: sale.title,
      }));
      setSalesChannelOptions(mappedSalesChannels);

      const mappedSegments = SegmentList.map((segment) => ({
        value: segment.id,
        label: segment.title,
      }));
      setSegmentOptions(mappedSegments);

      setResponseWinLose(ResponsesList);
    } catch (error) {
      console.error('Erro ao carregar dados:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    ProductsList,
    FunnelsList,
    ActivitieTypeList,
    SalesChannelList,
    SegmentList,
    ResponsesList,
  ]);

  return (
    <div>
      {isLoading || isLoadingProducts ? (
        <p>Carregando...</p>
      ) : (
        <UpdateOpportunity
          funnels={funnelOptions}
          productOptions={productOptions}
          activityOptions={activityTypeOptions}
          closeUpdate={() => window.close()}
          responseWinLose={responseWinLose}
          opportunityId={Number(opportunityId)}
          salesChannelOptions={salesChannelOptions}
          segmentOptions={segmentOptions}
          stepsOptions={currentFunnel?.funnel_step ?? []}
        />
      )}
    </div>
  );
};

export default UpdateOpportunityPage;
