import { socket } from '../../../config';

function connectSocketWithApi(instanceId: number) {
  if (!socket) return;

  socket.emit('set_room_instance', `set_room_${instanceId}`);
}

function createRoomMessage(whatsapp: string) {
  if (!socket) return;

  const room = `${whatsapp}_person`;

  socket.emit('set_room', room);
}

function listenToInstanceUpdates(action: (e: any) => void) {
  if (!socket) return;
  socket.on('received_instance', (text) => {
    action(text);
  });
}

function listenToInstanceUpdatesOtherUser(action: (e: any) => void) {
  if (!socket) return;
  socket.on('instance_had_status_update', (text) => {
    action(text);
  });
}

function listenToQrCodeChange(action: (e: any) => void) {
  if (!socket) return;
  socket.on('update_qrcode_status', (text) => {
    action(text);
  });
}

function deleteConnection(connectionName: string) {
  if (!socket) return;

  socket.off(connectionName);
}

export {
  connectSocketWithApi,
  createRoomMessage,
  listenToInstanceUpdates,
  listenToInstanceUpdatesOtherUser,
  listenToQrCodeChange,
  deleteConnection,
};
