/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  Button,
  Input,
  InputDate,
  SelectComponent,
} from '../../../../../../components';
import maskHelper from '../../../../../../helpers/mask.helper';
import { dictionaryError } from '../../../../../../helpers/utils.helper';
import { OfferProps, createOfferProps } from '../../../../types';
import { useFetchClientDropdown } from '../../../../../clients/hooks';
import { ApiOffer } from '../../../../api';
import cpfValidation from '../../../../../../helpers/validations/cpf.validation';
import cnpjValidation from '../../../../../../helpers/validations/cnpj.validation';
import phoneValidate from '../../../../../../helpers/validations/phone.validate';
import { useFetchContact } from '../../../../../contacts/hooks';
import { ContactOpportunity } from '../../../../../opportunity/types';
import IconDictionary from '../../../../../../components/Icons/icons';
import { apartmentTypesOptions } from '../../utils';
import { apartmentProps } from '../types';

interface generalProps {
  current: boolean;
  apartment: apartmentProps[];
  insertApartment: () => void;
  deleteApartment: (index: number) => void;
  editApartment: (index: number, apartment: apartmentProps) => void;
}

const Apartment: React.FC<generalProps> = ({
  current,
  apartment,
  insertApartment,
  deleteApartment,
  editApartment,
}) => {
  const [toggleList, setToggleList] = useState(false);
  const todayDate = new Date();
  const yesterdayDate = new Date(todayDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  const category = [
    { value: 'feminino', label: 'Feminino' },
    { value: 'masculino', label: 'Masculino' },
  ];

  return (
    <div className="col-span-3 flex flex-col w-full">
      <div className="w-full flex justify-between items-center text-sm">
        <strong className="font-semibold">Inserir Apartamentos</strong>
        <div className="flex gap-2">
          <Button
            actionType="button-add"
            className="hidden md:flex w-52"
            onClick={() => {
              insertApartment();
              if (!toggleList) setToggleList(true);
            }}
          >
            Inserir Apartamento
          </Button>
          <Button
            actionType="button-add"
            className="w-14 md:hidden"
            onClick={() => {
              insertApartment();
              if (!toggleList) setToggleList(true);
            }}
          />
          <button
            type="button"
            className="flex items-center gap-1.5 text-xs py-1 px-2 border border-solid border-gray-400 rounded"
            onClick={() => setToggleList(!toggleList)}
          >
            {toggleList ? (
              <>
                Recolher
                <IconDictionary name="Seta Acima" size={24} />
              </>
            ) : (
              <>
                Expandir
                <IconDictionary name="Seta Abaixo" size={24} />
              </>
            )}
          </button>
        </div>
      </div>

      <div
        className={`flex flex-col overflow-hidden transition-all duration-500 ease-in-out ${
          toggleList ? 'max-h-screen mt-3' : 'max-h-0'
        }`}
      >
        {apartment.map((elem, index) => (
          <div
            className={`flex flex-col ${
              index + 1 === apartment.length ? 'mb-40' : 'mb-0'
            }`}
          >
            <div className="flex items-center gap-1">
              <strong className="text-sm text-nowrap font-semibold">
                Apartamento {(index + 1).toString().padStart(2, '0')}
              </strong>
              <hr className="bg-gray-200 h-px w-full" />
              {/* <Button actionType="button-edit">Editar</Button> */}
              <Button
                onClick={() => deleteApartment(index)}
                actionType="button-remove"
              >
                Remover
              </Button>
            </div>
            <div className="w-full grid-col-1 sm:grid grid-cols-2 md:grid-cols-4 gap-2">
              <SelectComponent
                maxOptionsHeight="150px"
                key={String(Math.random())}
                value={apartmentTypesOptions.find(
                  (type) => type.value === elem.type
                )}
                options={apartmentTypesOptions}
                title="Tipo de apartamento"
                onChange={(e: any) => {
                  editApartment(index, {
                    ...elem,
                    type: e.value,
                  });
                }}
              />
              <Input
                defaultValue={elem.adult.length}
                variant="outline-orange"
                type="number"
                title="Adultos (18+)"
                onBlur={(e) =>
                  editApartment(index, {
                    ...elem,
                    adult: new Array(Number(e.target.value)).fill({
                      value: 0,
                      ravFix: 0,
                      ravVar: 0,
                      tax: 0,
                    }),
                  })
                }
              />
              <Input
                defaultValue={elem.child.length}
                variant="outline-orange"
                type="number"
                title="Crianças (2-17)"
                onBlur={(e) => {
                  editApartment(index, {
                    ...elem,
                    child: new Array(Number(e.target.value)).fill({
                      value: 0,
                      ravFix: 0,
                      ravVar: 0,
                      tax: 0,
                    }),
                  });
                }}
              />
              <Input
                defaultValue={elem.baby.length}
                variant="outline-orange"
                type="number"
                title="Bebês (0-1)"
                onBlur={(e) =>
                  editApartment(index, {
                    ...elem,
                    baby: new Array(Number(e.target.value)).fill({
                      value: 0,
                      ravFix: 0,
                      ravVar: 0,
                      tax: 0,
                    }),
                  })
                }
              />
            </div>
          </div>
        ))}
      </div>
      {/* )} */}
    </div>
  );
};

export default Apartment;
