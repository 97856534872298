import { getItem } from './storage.helper';

export function changeOpacityRootColor(alphaFactor: number) {
  const hex = getItem('color-hex');
  // Remove "#" do início, se presente
  let sanitizedHex = hex.replace('#', '');

  // Se o hex não tem um canal alfa, adiciona "FF" como opacidade total
  if (sanitizedHex.length === 6) sanitizedHex += 'FF';

  // Extrai componentes RGB e Alfa
  const r = sanitizedHex.substring(0, 2);
  const g = sanitizedHex.substring(2, 4);
  const b = sanitizedHex.substring(4, 6);
  let a = parseInt(sanitizedHex.substring(6, 8), 16); // Converte alfa para decimal

  // Ajusta a opacidade com o fator e limita entre 0 e 255
  a = Math.max(0, Math.min(255, a + alphaFactor));

  // Converte o valor alfa ajustado de volta para hexadecimal com 2 dígitos
  const newAlpha = a.toString(16).padStart(2, '0');

  // Retorna a cor ajustada
  return `#${r}${g}${b}${newAlpha}`;
}
