import { baseApiPrivate } from '../../../../api/baseApi';

async function getCompanyServiceInvoices(filters: Record<string, any> = {}) {
  const searchParams = new URLSearchParams(filters);
  return baseApiPrivate({
    method: 'GET',
    url: `companie/financial/serviceInvoiceCompany/report?${searchParams}`,
  });
}

async function getServiceInvoiceXMl(serviceInvoiceId: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/financial/serviceInvoiceCompany/${serviceInvoiceId}/xml?`,
    file: true,
    responseFile: true,
  });
}
async function getServiceInvoicePDF(serviceInvoiceId: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/financial/serviceInvoiceCompany/${serviceInvoiceId}/pdf`,
    file: true,
    responseFile: true,
  });
}
async function cancelServiceInvoice(serviceInvoiceId: string) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/financial/serviceInvoiceCompany/${serviceInvoiceId}/cancel`,
  });
}

export const APiFinancialServiceInvoiceReports = {
  getCompanyServiceInvoices,
  getServiceInvoiceXMl,
  getServiceInvoicePDF,
  cancelServiceInvoice,
};
