/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Plan } from '../../../../../components';

import { Cards } from '../../whatsapp/cards';
import { Messages } from '../../whatsapp/messages';
import Footer from '../../whatsapp/footer';
import { ToastNotify } from '../../../../../components/Toast/toast';

import ModalHasNoConnectedInstances from '../../modal/modalHasNoConnectsInstances';
import {
  connectSocketWithApi,
  createRoomMessage,
  deleteConnection,
  listenToInstanceUpdates,
  listenToInstanceUpdatesOtherUser,
} from '../../../../whatsapp/socket';

interface Props {
  contact: any;
  totalMessages: number;
  messageData: any[];
  // eslint-disable-next-line no-unused-vars
  setMessageKey: (a: string) => void;
  onCancel: () => void;
  instanceData: {
    data: any[];
    message:
      | 'instanceConnected'
      | 'instanceDisconnected'
      | 'thisContactHasNoInstance'
      | 'thereAreNoConnectedInstances'
      | 'planIsNotActive';
  };
}

const Whatsapp: React.FC<Props> = ({
  totalMessages,
  messageData,
  contact,
  instanceData,
  setMessageKey,
  onCancel,
}: Props) => {
  const { notify } = ToastNotify();
  const navigate = useNavigate();

  const [instanceId, setInstanceId] = useState(
    messageData
      ? messageData[messageData.length - 1]?.companie_whatsapp_instance_id
      : null
  );

  const [show, setShow] = useState(false);
  const [showHasNoInstances, setShowHasNoInstances] = useState(false);
  const [socketDisable, setSocketDisable] = useState(false);

  const handleUpdateInstances = (text: any) => {
    if (text === 'Desconectada') {
      setSocketDisable(true);
      notify({
        message: 'No momento não existe instância conectada!',
        type: 'Warning',
      });
      setMessageKey(`${Math.random()} key`);
    }
  };

  const handleOtherUserUpdateInstance = (data: any) => {
    if (data.companie_whatsapp_instance_id === instanceId) {
      setMessageKey(String(Math.random()));
    }
  };

  const whatsapp = `55${contact.person.whatsapp}`;

  useEffect(() => {
    if (!instanceData) return;

    if (instanceData.message === 'thereAreNoConnectedInstances') {
      setShowHasNoInstances(true);
      setShow(false);
    }

    if (
      instanceData.message === 'instanceDisconnected' ||
      instanceData.message === 'thisContactHasNoInstance'
    ) {
      setShow(false);
    }
  }, [instanceData]);

  useEffect(() => {
    if (instanceData.message === 'thereAreNoConnectedInstances') {
      setShowHasNoInstances(true);
    }

    listenToInstanceUpdates(handleUpdateInstances);

    if (instanceId) {
      // Quando a instancia atual for desconectada por outros atualiza os dados da pagina
      listenToInstanceUpdatesOtherUser(handleOtherUserUpdateInstance);
    }

    connectSocketWithApi(instanceId);
    createRoomMessage(whatsapp);

    return () => {
      deleteConnection('received_instance');
      deleteConnection('instance_status_update');
    };
  }, []);

  return (
    <>
      <ModalHasNoConnectedInstances
        setShow={setShowHasNoInstances}
        show={showHasNoInstances}
      />

      <div className="relative flex flex-col w-full">
        <Plan
          local="opportunities"
          title="Ative o seu Plano"
          open={instanceData?.message === 'planIsNotActive'}
          onConfirm={() => navigate('/instances')}
          onCancel={onCancel}
        />

        {(instanceData?.message === 'thisContactHasNoInstance' ||
          instanceData?.message === 'instanceDisconnected') &&
          !show && (
            <Cards
              data={instanceData.data}
              onSelect={(ins) => {
                setShow(true);
                setInstanceId(ins.id);
              }}
            />
          )}

        {(instanceData?.message === 'instanceConnected' ||
          instanceData?.message === 'thereAreNoConnectedInstances' ||
          show) && (
          <>
            <Messages
              totalMessages={totalMessages}
              messages={messageData}
              whatsappPhone={whatsapp}
              instanceId={instanceId}
            />
            <Footer
              instanceId={instanceId}
              contact={contact}
              disabled={
                socketDisable ||
                instanceData.message === 'thereAreNoConnectedInstances'
              }
            />
          </>
        )}
      </div>
    </>
  );
};

export default Whatsapp;
