import { baseApiPrivate } from '../../../api/baseApi';

async function getWhatsappMessages() {
  return baseApiPrivate({
    method: 'GET',
    url: 'companie/whatsapp/message/default',
  });
}

async function getWhatsappMessagesActive() {
  return baseApiPrivate({
    method: 'GET',
    url: 'companie/whatsapp/message/default/active',
  });
}

async function postWhatsappMessages(body: any) {
  return baseApiPrivate({
    method: 'POST',
    url: `companie/whatsapp/message/default`,
    body,
  });
}
async function updateWhatsappMessage(id: string, body: any) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/whatsapp/message/default/${id}`,
    body,
  });
}

async function deleteWhatsappMessage(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/whatsapp/message/default/${id}`,
  });
}

async function getBlobFromUrl(url: string) {
  return baseApiPrivate({
    method: 'GET',
    url,
    file: true,
    responseFile: true,
  });
}

export const ApiWhatsapp = {
  postWhatsappMessages,
  updateWhatsappMessage,
  getWhatsappMessages,
  getWhatsappMessagesActive,
  deleteWhatsappMessage,
  getBlobFromUrl,
};
