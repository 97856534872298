/* eslint-disable jsx-a11y/label-has-associated-control */
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { MdFilePresent } from 'react-icons/md';
import { Button, Input } from '../../../components';

const schema = Yup.object({
  file: Yup.mixed().required('O arquivo do certificado digital é obrigatório'),
  password: Yup.string().required(
    'A senha do certificado digital é obrigatória'
  ),
});
interface FormProps {
  onSubmit: (data: any) => void;
  defaultValues: Record<string, any>;
  onBack?: () => void;
}

const ServiceInvoiceAccessData: FC<FormProps> = ({
  defaultValues,
  onSubmit,
  onBack,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    accept: {
      'application/x-pkcs12': ['.pfx', '.txt'],
    },
    multiple: false,
    onDrop: (acceptedFiles: any) => {
      setValue('file', acceptedFiles?.[0]);
    },
  });

  const file = watch('file');

  return (
    <div className="mt-4">
      <p>Sua prefeitura utiliza autenticação através de Certificado Digital.</p>
      <p>
        Utilizamos os dados de acesso para realizar a integração com o portal da
        sua prefeitura. O manuseio dos dados críticos, como senha, não ocorre em
        nenhum momento deste processo.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="flex gap-4 flex-col">
        <div className="mt-7">
          <label htmlFor="file">Certificado digital</label>
          <div className="flex flex-col items-center w-full">
            <div
              {...getRootProps()}
              className={classNames(
                'border-[1px] border-dashed border-[#aaa] p-4 rounded-md cursor-pointer bg-background relative w-full h-24 flex items-center justify-center',
                {
                  'border-red': !!errors?.file?.message,
                }
              )}
            >
              <input {...register('file')} {...getInputProps()} />
              {file?.name ? (
                <p className="font-light text-sm text-primary flex items-center gap-2">
                  <MdFilePresent size={20} />
                  {file?.name}
                </p>
              ) : isDragActive ? (
                <p className="font-light text-sm text-primary">
                  Solte o certificado de acesso aqui...
                </p>
              ) : (
                <p className="font-light text-sm text-center text-[#555]">
                  Arraste e solte aqui ou clique para selecionar um arquivo
                  contendo o certificado digital
                </p>
              )}
            </div>
          </div>
          {errors?.file?.message && (
            <div className="text-red text-xs mt-0.5 block">
              {errors?.file?.message as string}
            </div>
          )}
        </div>
        <div>
          <Input
            type="password"
            variant="outline-orange"
            {...register('password')}
            title="Senha do certificado digital"
            errorMensage={errors.password?.message as string}
          />
        </div>
        <div className="flex justify-center gap-2">
          <Button className="px-4" onClick={onBack}>
            Voltar
          </Button>
          <Button type="submit" className="px-4">
            Salvar e continuar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ServiceInvoiceAccessData;
