import React, { useState } from 'react';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import RegisterClient from '../components/register';
import { useFetchClientsList } from '../hooks';
import { ClientProps } from '../types';
import { ToastNotify } from '../../../components/Toast/toast';
import maskHelper from '../../../helpers/mask.helper';
import { Button, Card, DeleteModal, Modal } from '../../../components';
import { ApiClient } from '../api';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import MainPagePagination from '../../main/components/mainPage/mainPageWithPagination';
import { ApiContract } from '../../contract/api';
import ImportClients from '../../admin/company/components/register/components/importClients';

const Clients: React.FC = () => {
  const [modalImport, setModalImport] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalProviders, setModalProviders] = useState(false);
  const [startData, setStartData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientData, setClientData] = useState<ClientProps | null>(null);
  const [key, setKey] = useState('key');
  const [registerPage, setRegisterPage] = useState({
    title: 'Dados Pessoais',
    page: 0,
  });
  const accountProps = getTokenAccountInformation();
  const companyId = accountProps.companie_id;
  const [InputValue, setInputValue] = useState('');
  const { ClientsList, isLoading } = useFetchClientsList(
    key,
    companyId,
    startData,
    10,
    InputValue
  );
  const { notify } = ToastNotify();

  const columns = [
    {
      name: 'Nome/R. Social',
      key: 'name',
      selector: (row: ClientProps) => row.name,
    },
    {
      name: 'Apelido/Nome Fantasia',
      key: 'name_fantasy',
      selector: (row: ClientProps) => row.name_fantasy,
    },
    {
      name: 'CPF/CNPJ',
      key: 'document',
      selector: (row: ClientProps) => row.document,
      cell: (row: ClientProps) =>
        row.document && maskHelper.cnpjCpf(row.document),
    },
    {
      name: 'Nacionalidade',
      key: 'nationality',
      selector: (row: ClientProps) => row.nationality,
      hide: 1474,
    },
    {
      name: 'WhatsApp',
      key: 'whatsapp',
      selector: (row: ClientProps) => row.whatsapp,
      cell: (row: ClientProps) =>
        row.whatsapp && maskHelper.phone(row.whatsapp),
    },
    {
      name: 'E-mail',
      key: 'email',
      selector: (row: ClientProps) => row.email,
      hide: 1366,
    },
    {
      name: 'status',
      key: 'status',
      selector: (row: ClientProps) => row.status,
      cell: (row: ClientProps) => {
        if (row.status === 'ACTIVE') {
          return <p>Ativo</p>;
        }
        if (row.status === 'INACTIVE') {
          return <p>Inativo</p>;
        }
        return <p> </p>;
      },
    },
    {
      name: '',
      cell: (row: ClientProps) => (
        <CloseOutline
          size={20}
          className="cursor-pointer w-fit"
          color="#DA3C3C"
          onClick={() => {
            setClientData(row);
            setShowModalDelete(true);
          }}
        />
      ),
    },
  ];

  const generateCsv = async () => {
    const res = await ApiClient.getClientByCompanie(
      accountProps.companie_id,
      0,
      100000000
    );
    if (typeof res !== 'string' && res.data) {
      const csvData = [
        [
          'Nome ou Razão Social',
          'Apelido ou Nome Fantasia',
          'Data de nascimento',
          'CPF/CNPJ',
          'Email',
          'Telefone Fixo',
          'Telefone Celular',
          'Whatsapp',
          'Status',
          'Cep',
          'Logradouro',
          'Bairro',
          'Cidade',
          'Estado',
        ],
        ...res.data.map((item) => [
          item.name,
          item.name_fantasy,
          maskHelper.formatDateDMY(item.birth ?? ''),
          maskHelper.cnpjCpf(item.document ?? ''),
          item.email,
          maskHelper.phone(item.phone ?? ''),
          maskHelper.phone(item.cellular ?? ''),
          maskHelper.phone(item.whatsapp ?? ''),
          item.status,
          item.zipcode,
          item.address,
          item.province,
          item.city,
          item.state,
        ]),
      ];
      return csvData;
    }
    return [[]];
  };

  const searchClient = async (value: string) => {
    setStartData(0);
    setCurrentPage(1);
    setInputValue(value);
  };

  return (
    <>
      <MainPagePagination
        search={searchClient}
        currentPageTable={currentPage}
        setCurrentPageTable={setCurrentPage}
        startDate={setStartData}
        totalRegisters={ClientsList.total}
        isLoading={isLoading}
        column={columns}
        title="Clientes"
        filename="clientes"
        filterTable={() => ClientsList.data}
        setModalCreateUpdate={setModalProviders}
        CustomCard={
          <Card className="min-w-fit px-2 flex-col w-1/3 justify-center items-center gap-4 pb-3.5 pt-5">
            <strong className="text-md">Importar Clientes</strong>
            <Button className="w-40 h-11" onClick={() => setModalImport(true)}>
              Importar
            </Button>
          </Card>
        }
        setRegisterPage={() => {
          setClientData(null);
          setRegisterPage({ title: 'Dados Pessoais', page: 0 });
        }}
        onRowClick={(e: ClientProps) => {
          setRegisterPage({ title: 'Dados Pessoais', page: 0 });
          setClientData(e);
          setModalProviders(true);
        }}
        objectReport={generateCsv}
        reportAndCreate
      />
      <RegisterClient
        setClient={setClientData}
        companyUserId={accountProps.id}
        client={clientData}
        reloadTable={setKey}
        registerPage={registerPage}
        setRegisterPage={setRegisterPage}
        setShow={setModalProviders}
        show={modalProviders}
      />
      <Modal
        size="2xlarge"
        minHeight="min-h-[100px]"
        title="Importar Clientes"
        isOpen={modalImport}
        setIsOpen={setModalImport}
      >
        <ImportClients companieUserId={accountProps.id} mode="companieUser" />
      </Modal>
      {clientData && (
        <DeleteModal
          apiFunction={async (id) => {
            const hasContract = await ApiContract.filterContracts({
              clientId: [id],
              companieUserPromoterId: [],
              date_end: '',
              date_start: '',
              filter: 'all',
              offerId: [],
              order: 'created_at_desc',
              skip: 0,
              take: 0,
              status: [],
            });
            if (typeof hasContract !== 'string' && hasContract.total > 0) {
              return false;
            }
            const res = await ApiClient.deleteClient(id);
            return res;
          }}
          notify={notify}
          type="Cliente"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={clientData.id}
          name={clientData.name}
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default Clients;
