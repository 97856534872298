import { baseApi, baseApiPrivate } from '../../../api/baseApi';
import { CredentialsAPI, CredentialsImport } from '../types';

async function getConfig(companyId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/settings/automations/${companyId}`,
  });
}

async function getLayoutConfig(companyId: number) {
  return baseApi({
    method: 'GET',
    url: `session/companie/settings/automations/companieANDtype/${companyId}/form`,
  });
}

async function changeRandom(payload: boolean) {
  return baseApiPrivate({
    method: 'POST',
    body: {
      is_lead_random: payload,
    },
    url: `companie/settings/automations/random`,
  });
}

async function changeDuplicate(payload: boolean) {
  return baseApiPrivate({
    method: 'POST',
    body: {
      is_duplicated_opp_funnel: payload,
    },
    url: `companie/settings/automations/duplicated-opp-funnel`,
  });
}

async function saveForm(payload: any) {
  return baseApiPrivate({
    method: 'POST',
    body: payload,
    url: `companie/settings/automations/create/form`,
  });
}

async function saveImport(payload: CredentialsImport) {
  return baseApiPrivate({
    method: 'POST',
    body: payload,
    url: `companie/settings/automations/create/import`,
  });
}

async function saveAPI(payload: CredentialsAPI) {
  return baseApiPrivate({
    method: 'POST',
    body: payload,
    url: `companie/settings/automations/create/api`,
  });
}

async function createForm(credentials: any) {
  return baseApi({
    body: credentials,
    method: 'POST',
    url: 'session/companie/captured/lead/form',
  });
}

async function createImport(file: any) {
  return baseApiPrivate({
    body: file,
    method: 'POST',
    url: 'companie/captured/lead/import',
  });
}

async function companyHasConfiguration(companyId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/settings/automations/companieANDtype/${companyId}/import`,
  });
}

export const ApiInputAutomation = {
  getLayoutConfig,
  createForm,
  saveForm,
  saveAPI,
  saveImport,
  changeRandom,
  companyHasConfiguration,
  changeDuplicate,
  createImport,
  getConfig,
};
