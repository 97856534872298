import React, { useContext, useEffect, useRef, useState } from 'react';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  EditorState,
  Modifier,
  ContentState,
  convertToRaw,
  AtomicBlockUtils,
  convertFromRaw,
  ContentBlock,
} from 'draft-js';
import '../../../styles/textEditor.css';
import {
  Button,
  ModalImagesCompanie,
  SelectComponent,
  isLoadingApplication,
} from '../../../components';
import IconDictionary from '../../../components/Icons/icons';
import { ApiContract } from '../api';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';
import { getItem } from '../../../helpers/storage.helper';
import { ApiTheme } from '../../companieCustom/api';
import { baseApiPrivate } from '../../../api/baseApi';
import { clientOptions } from '../../../constants';

const optionsEditor = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'remove',
    'history',
  ],
  colorPicker: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    colors: [
      'rgb(97,189,109)',
      'rgb(26,188,156)',
      'rgb(84,172,210)',
      'rgb(44,130,201)',
      'rgb(147,101,184)',
      'rgb(71,85,119)',
      'rgb(204,204,204)',
      'rgb(65,168,95)',
      'rgb(0,168,133)',
      'rgb(61,142,185)',
      'rgb(41,105,176)',
      'rgb(85,57,130)',
      'rgb(40,50,78)',
      'rgb(0,0,0)',
      'rgb(247,218,100)',
      'rgb(251,160,38)',
      'rgb(235,107,86)',
      'rgb(226,80,65)',
      'rgb(163,143,132)',
      'rgb(87,87,86)',
      'rgb(239,239,239)',
      'rgb(255,255,255)',
      'rgb(250,197,28)',
      '#954893',
      'rgb(209,72,65)',
      'rgb(184,49,47)',
      'rgb(124,112,107)',
      'rgb(209,213,216)',
    ],
  },
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
  },
};

const SelectDinamicOptions = ({ editorState, onChange }: any) => {
  const [value, setValue] = useState<{ label: string; value: string } | null>(
    null
  );
  const addPlaceholder = (placeholder: any) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      placeholder,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  return (
    <div className="w-full my-1">
      <SelectComponent
        closeMenuOnSelect
        value={value}
        options={clientOptions}
        className="w-64"
        placeholder="Adicionar Opções"
        onChange={(e: any) => {
          addPlaceholder(e.value);
          setValue(null);
        }}
      />
    </div>
  );
};

const ContractEditPage: React.FC = () => {
  const editorRef = useRef<any>(null);
  const { notify } = ToastNotify();
  const isLoading = useContext(isLoadingApplication);
  const [key, setKey] = useState('');
  const [showModalImage, setModalImage] = useState(false);
  const [positionCard, setPostionCard] = useState({
    position: 'absolute',
    top: 110,
  });
  const [htmlPdf, setHtmlPdf] = useState<string>();
  const [editorState, setState] = useState<EditorState>();

  const [contractId, setContractId] = useState<number>();
  const urlImg = getItem('logo-path');

  const saveDraft = async (showMessage: boolean) => {
    isLoading.setState(true);

    if (editorState !== undefined) {
      const rawContentState = convertToRaw(editorState.getCurrentContent());

      let html = draftToHtml(rawContentState);

      html = html.replace(
        /<div style="text-align:(left|right|center|none);">([\s\S]*?)<img([^>]+?)style="([^"]*?)"([^>]*?)\/>/g,
        (match, align, content, imgTag, styleContent, rest) => {
          let alignment = align;

          if (alignment === 'none') {
            alignment = 'center';
          }

          if (alignment === 'center') {
            styleContent = styleContent.replace(/float:\s*(left|right);?/, '');

            styleContent += '; display: inline-block; float: none;';
          }

          if (alignment === 'left' || alignment === 'right') {
            if (styleContent.includes('float')) {
              styleContent = styleContent.replace(
                /float:\s*(left|right);?/,
                `float: ${alignment};`
              );
            } else {
              styleContent += `; float: ${alignment};`;
            }
          }
          return `<div style="text-align:${alignment};">${content}<img ${imgTag} style="${styleContent}" ${rest}/></div><div style="clear: both;"></div>`;
        }
      );

      if (contractId !== undefined) {
        const response = await ApiContract.updateContract({ html }, contractId);
        if (response.id && showMessage === true) {
          notify({
            message: 'Contrato atualizado com sucesso',
            type: 'Success',
          });
        }
        setKey(String(Math.random()));
      } else {
        const response = await ApiContract.createContract({ html });
        if (response.id && showMessage === true) {
          notify({
            message: 'Contrato criado com sucesso',
            type: 'Success',
          });
        }
        setKey(String(Math.random()));
      }
    }

    isLoading.setState(false);
  };

  const SaveToPDFIcon = () => {
    async function downloadPdf() {
      isLoading.setState(true);
      const res = await baseApiPrivate({
        url: 'companie/contract-custom/generatePDF',
        method: 'GET',
        responseFile: true,
      });

      const downloadUrl = URL.createObjectURL(res);
      const download = document.createElement('a');
      download.href = downloadUrl;
      download.download = 'contract.pdf';
      download.target = '_blank';
      document.body.appendChild(download);
      download.click();
      document.body.removeChild(download);
      URL.revokeObjectURL(downloadUrl);
      isLoading.setState(false);

      isLoading.setState(false);
    }

    return (
      <button
        type="button"
        onClick={async () => {
          await saveDraft(false);
          downloadPdf();
        }}
        className="w-9 h-9 ml-1 border-[#dadada] hover:border-primary rounded-md cursor-pointer border border-solid flex items-center justify-center"
      >
        <IconDictionary name="PDF" />
      </button>
    );
  };

  const loadImage = async () => {
    try {
      const blob = await ApiTheme.getBlobFromUrl(urlImg);

      const reader = new FileReader();
      const base64Promise = new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
      });

      reader.readAsDataURL(blob);

      const base64data = await base64Promise;
      return base64data;
    } catch (error) {
      console.error('Erro ao carregar a imagem:', error);
      return 'error';
      // throw error;
    }
  };

  const insertImage = async (
    editor: EditorState,
    base64: any,
    width = 'auto',
    height = 'auto'
  ) => {
    const contentState = editor.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'IMAGE',
      'IMMUTABLE',
      {
        src: base64,
        width,
        height,
        alignment: 'left',
      }
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editor, {
      currentContent: contentStateWithEntity,
    });

    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
  };

  const UploadImage = () => (
    <button
      type="button"
      onClick={async () => {
        setModalImage(true);
      }}
      className="w-9 h-9 ml-1 border-[#dadada] hover:border-primary rounded-md cursor-pointer border border-solid flex items-center justify-center"
    >
      <IconDictionary name="Imagem" />
    </button>
  );

  const handlePastedText = (
    text: string,
    _html: string,
    editor: EditorState
  ) => {
    try {
      const deserializedContent = convertFromRaw(JSON.parse(text));
      const contentState = Modifier.replaceWithFragment(
        editor.getCurrentContent(),
        editor.getSelection(),
        deserializedContent.getBlockMap()
      );
      const newEditorState = EditorState.push(
        editor,
        contentState,
        'insert-fragment'
      );
      setState(newEditorState);

      return true;
    } catch (error) {
      console.error('Erro:', error);
      return false;
    }
  };

  const handlePasteEvent = async () => {
    if (editorState) {
      const te = await navigator.clipboard.read();
      if (te.length > 0) {
        try {
          const blob = await te[0].getType('image/png');

          const reader = new FileReader();
          const base64Promise = new Promise((resolve, reject) => {
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
          });

          reader.readAsDataURL(blob);

          const base64data = await base64Promise;
          const editorStateProps = await insertImage(editorState, base64data);
          setState(editorStateProps);
        } catch (err) {
          console.warn(err);
        }
      }
    }
  };

  const blockRendererFn = (contentBlock: ContentBlock) => {
    const type = contentBlock.getType();

    if (type === 'unstyled') {
      const text = contentBlock.getText();

      if (text === '{{new_page}}') {
        return {
          component: () => (
            <div
              className="bg-gray-200 -mx-20 text-gray-200"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: text }}
            />
          ),
          editable: true,
        };
      }
    }

    return null;
  };

  useEffect(() => {
    const fetchContract = async () => {
      isLoading.setState(true);
      const res = await ApiContract.getContract();
      if (res != null && res.html) {
        const draft = htmlToDraft(`<p>${res.html}</p>`);
        const contentState = ContentState.createFromBlockArray(
          draft.contentBlocks,
          draft.entityMap
        );
        setHtmlPdf(res.html);
        let editorStateProps = EditorState.createWithContent(contentState);
        if (res.html.length === 8) {
          const imgaBase64 = await loadImage();
          if (imgaBase64 !== 'error')
            editorStateProps = await insertImage(editorStateProps, imgaBase64);
        }
        setState(editorStateProps);
        setContractId(res.id);
      } else {
        const imgaBase64 = await loadImage();
        const contentState = ContentState.createFromText('');
        let editorStateProps = EditorState.createWithContent(contentState);
        if (imgaBase64 !== 'error')
          editorStateProps = await insertImage(editorStateProps, imgaBase64);
        const html = draftToHtml(
          convertToRaw(editorStateProps.getCurrentContent())
        );
        setState(editorStateProps);
        setHtmlPdf(html);
      }
      isLoading.setState(false);
    };
    fetchContract();
  }, [key]);

  useEffect(() => {}, [editorState]);

  useEffect(() => {
    const handleScroll = () => {
      try {
        if (window.scrollY > editorRef.current.wrapper.offsetTop - 158) {
          setPostionCard({ position: 'fixed', top: 0 });
        } else {
          setPostionCard({ position: 'absolute', top: 110 });
        }
      } catch (e) {
        console.error(e);
      }
    };
    window.addEventListener('paste', handlePasteEvent);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('paste', handlePasteEvent);
    };
  }, []);

  return (
    <div className="w-full flex flex-col pb-8">
      <ModalImagesCompanie
        insertImage={async (url: string, width, heigth) => {
          if (editorState) {
            const editorStateProps = await insertImage(
              editorState,
              url,
              width,
              heigth
            );
            setState(editorStateProps);
          }
        }}
        setState={isLoading.setState}
        setShow={setModalImage}
        show={showModalImage}
      />
      <div className="flex gap-3 items-center text-primary">
        <IconDictionary name="Contratos" size={24} />
        <strong className="text-lg text-black">Personalizar Contrato</strong>
      </div>
      <div className="min-h-[1300px] lg:min-h-fit min-h-screen mt-6 mb-5 w-[793px] mx-auto">
        <Editor
          customBlockRenderFunc={blockRendererFn}
          ref={editorRef}
          editorState={editorState}
          onEditorStateChange={(e) => setState(e)}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarCustomButtons={[
            <UploadImage />,
            <SaveToPDFIcon />,
            <SelectDinamicOptions />,
          ]}
          toolbar={optionsEditor}
          handlePastedText={handlePastedText}
          toolbarStyle={{
            ...positionCard,
            width: '793px',
            zIndex: 39,
            border: '1px #e6e6e6 solid',
            padding: '12px 10px 0px 10px',
          }}
          editorStyle={{
            width: '793px',
            marginTop: '190px',
            backgroundColor: 'white',
            padding: '24px',
            overflowX: 'hidden',
            minHeight: '1123.56px',
          }}
        />
      </div>
      {htmlPdf !== undefined && (
        <Button
          onClick={() => saveDraft(true)}
          className="mx-auto w-64"
          variant="primary-strong"
          actionType="button-loading"
          isLoading={isLoading.state}
          disabled={isLoading.state}
        >
          Salvar
        </Button>
      )}
    </div>
  );
};

export default ContractEditPage;
