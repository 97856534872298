import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsFiletypePdf, BsFiletypeXml } from 'react-icons/bs';
import { MdOutlineCancel } from 'react-icons/md';
import MainPagePagination from '../../../main/components/mainPage/mainPageWithPagination';
import { APiFinancialServiceInvoiceReports } from '../api';
import maskHelper from '../../../../helpers/mask.helper';
import { ToastNotify } from '../../../../components/Toast/toast';

const ReportsServiceInvoice = () => {
  const { register } = useForm({});
  const [loadingInvoiceAction, setLoadingInvoiceAction] = useState(false);

  const [startData, setStartData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [serviceInvoices, setServiceInvoices] = useState([]);
  const [loadingServiceInvoices, setLoadingServiceInvoices] = useState(false);

  const { notify } = ToastNotify();
  const onClickXmLButton = async (serviceInvoiceId: string) => {
    try {
      setLoadingInvoiceAction(true);
      const response =
        await APiFinancialServiceInvoiceReports.getServiceInvoiceXMl(
          serviceInvoiceId
        );
      if (typeof response === 'string') {
        notify({
          message: 'Não foi possível baixar o xml da nota fiscal',
          type: 'Error',
        });
        return;
      }
      const blob = new Blob([response], { type: 'application/xml' });
      // Create a temporary anchor element to trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'file.xml'; // Set the file name for the download

      // Trigger the download by simulating a click
      link.click();

      // Clean up
      URL.revokeObjectURL(link.href);
    } catch (error) {
      notify({
        message: 'Não foi possível baixar o xml da nota fiscal',
        type: 'Error',
      });
    } finally {
      setLoadingInvoiceAction(false);
    }
  };
  const onClickPDFButton = async (serviceInvoiceId: string) => {
    try {
      const response =
        await APiFinancialServiceInvoiceReports.getServiceInvoicePDF(
          serviceInvoiceId
        );
      if (typeof response === 'string') {
        notify({
          message: 'Não foi possível baixar o pdf da nota fiscal',
          type: 'Error',
        });
        return;
      }
      const blob = new Blob([response], { type: 'application/pdf' });
      // Create a temporary anchor element to trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'file.pdf'; // Set the file name for the download

      // Trigger the download by simulating a click
      link.click();

      // Clean up
      URL.revokeObjectURL(link.href);
    } catch (error) {
      notify({
        message: 'Não foi possível baixar o xml da nota fiscal',
        type: 'Error',
      });
    }
  };

  const loadServiceInvoices = useCallback(() => {
    if (loadingServiceInvoices) return;
    setLoadingServiceInvoices(true);
    APiFinancialServiceInvoiceReports.getCompanyServiceInvoices()
      .then((response) => {
        setServiceInvoices(response.data.serviceInvoices);
      })
      .finally(() => setLoadingServiceInvoices(false));
  }, [loadingServiceInvoices]);

  const onClickCancelButton = async (serviceInvoiceId: string) => {
    try {
      setLoadingInvoiceAction(true);
      const response =
        await APiFinancialServiceInvoiceReports.cancelServiceInvoice(
          serviceInvoiceId
        );
      if (typeof response === 'string') {
        notify({
          message: 'Não foi possível cancelar a nota fiscal',
          type: 'Error',
        });
        return;
      }
      notify({
        message: 'Nota fiscal cancelada com sucesso',
        type: 'Success',
      });
      loadServiceInvoices();
    } catch (error) {
      notify({
        message: 'Não foi possível cancelar a nota fiscal',
        type: 'Error',
      });
    } finally {
      setLoadingInvoiceAction(false);
    }
  };
  const columns = [
    {
      name: 'ID NFS',
      key: 'cpfCnpj',
      selector: (row: any) => row.id,
      cell: (row: any) => row.id,
    },
    {
      name: 'Nome do cliente',
      key: 'cpfCnpj',
      selector: (row: any) => row.borrower.borrower,
      cell: (row: any) => row.borrower.name,
    },
    {
      name: 'Valor da nota',
      key: 'cpfCnpj',
      selector: (row: any) => row.servicesAmount,
      cell: (row: any) => maskHelper.formatMoeda(row.servicesAmount),
    },
    {
      name: 'Data da emissão',
      key: 'cpfCnpj',
      selector: (row: any) => row.issuedOn,
      cell: (row: any) => new Date(row.issuedOn).toLocaleDateString(),
    },
    {
      name: 'Ações',
      key: 'cpfCnpj',
      // selector: (row: any) => row.issuedOn,
      cell: (row: any) => (
        <div className="flex gap-2 items-center">
          <button
            className="appearance-none bg-transparent border-none p-0 m-0"
            type="button"
            onClick={() => onClickXmLButton(row.id)}
            disabled={loadingInvoiceAction}
          >
            <BsFiletypeXml size={16} />
          </button>
          <button
            className="appearance-none bg-transparent border-none p-0 m-0"
            type="button"
            onClick={() => onClickPDFButton(row.id)}
          >
            <BsFiletypePdf size={16} />
          </button>
          <button
            className="appearance-none bg-transparent border-none p-0 m-0"
            type="button"
            title="Cancelar nota"
            disabled={loadingInvoiceAction}
            onClick={() => onClickCancelButton(row.id)}
          >
            <MdOutlineCancel size={16} />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    loadServiceInvoices();
  }, []);

  return (
    <MainPagePagination
      reportAndCreate={false}
      currentPageTable={currentPage}
      setCurrentPageTable={setCurrentPage}
      startDate={setStartData}
      totalRegisters={0}
      isLoading={loadingServiceInvoices}
      title="Relatório Nota fiscal de serviço"
      column={columns}
      filterTable={() => serviceInvoices}
      setRegisterPage={() => {}}
      FilterComponent={false}
      showRegisterAction={false}
      // objectReport={generateCsv}
      // filename="contas"
    />
  );
};

export default ReportsServiceInvoice;
