import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';
import { getItem } from '../helpers/storage.helper';
import { getTokenAccountInformation } from '../helpers/token.helper';
import { logiParams } from '../modules/login/api';
import { saveParamLocal } from '../modules/login/utils';

export const reloadNotification = atom(false);
export const reloadAccess = atom('');
export const reloadMenu = atom('');
export const reloadTheme = atom({ path: 'initial', colorHex: '' });
export const cookiesAccepted = atom(Boolean(getItem('cookies')));
export const reloadConnection = atom(false);
export const instancesInformation = atom<any>(undefined);
export const alertGalaxAccount = atom(false);
export const modalAccountNotActive = atom(false);

export function CompanieUpdate() {
  const [currentReloadTheme, setReloadTheme] = useAtom(reloadTheme);
  const TEN_MINUTES = 600000;

  useEffect(() => {
    const fetchCompanie = async (email: string) => {
      const login = await logiParams({
        email,
        password: 'IHthn4KY4jELxt9JPjOL',
      });
      if (login.token) {
        saveParamLocal(login);
      }
      if (login.companie) return login.companie;
      return null;
    };

    const interval = setInterval(async () => {
      const token = getTokenAccountInformation();
      if (token && token.email && token.companie) {
        const companie = await fetchCompanie(token.email);
        if (
          (currentReloadTheme.path === '' ||
            currentReloadTheme.path === 'initial') &&
          companie
        ) {
          setReloadTheme({
            path: companie.path_logo,
            colorHex: companie.color_hex,
          });
        }
      }
    }, TEN_MINUTES);

    return () => clearInterval(interval);
  }, []);
}
