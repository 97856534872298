import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiSearchForm } from '../api';
import { SearchFormProps } from '../types';
import FormClient from '../components/answerForm';
import { dictionaryError, setLayoutColor } from '../../../helpers/utils.helper';
import { isLoadingApplication } from '../../../components';
import Footer from '../../login/components/footer';
import { ToastNotify } from '../../../components/Toast/toast';

const AnswerForm: React.FC = () => {
  const { notify } = ToastNotify();
  const navigate = useNavigate();
  const { setState } = useContext(isLoadingApplication);
  const {
    companieId,
    searchFormId,
    opportunityId,
    contractId,
    financialId,
    activitieId,
    clientId,
  } = useParams();
  const [data, setData] = useState<null | SearchFormProps>(null);
  useEffect(() => {
    const fetchForm = async (companyId: number, formId: number) => {
      const res = await ApiSearchForm.getFormByID(companyId, formId);
      if (typeof res === 'string') {
        notify({ message: dictionaryError(res), type: 'Error' });
        setTimeout(() => {
          navigate('invalid-link');
        }, 6000);
      } else {
        setData(res);
      }
    };
    if (companieId && searchFormId) {
      fetchForm(Number(companieId), Number(searchFormId));
      setLayoutColor(Number(companieId), setState);
    }
  }, []);
  return (
    <div className="w-full min-h-screen justify-between bg-gray-200 flex flex-col">
      {data && (
        <FormClient
          defaultValue={data}
          opportunityId={opportunityId ? Number(opportunityId) : undefined}
          contractId={contractId ? Number(contractId) : undefined}
          clientId={clientId ? Number(clientId) : undefined}
          financialId={financialId ? Number(financialId) : undefined}
          activitieId={activitieId ? Number(activitieId) : undefined}
        />
      )}
      <Footer />
    </div>
  );
};

export default AnswerForm;
