import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { formPropsPage, generalData } from '../../../types';
import { useFetchMatriz } from '../../../hooks';
import { ApiCompany } from '../../../api';
import { Button, Input, SelectComponent } from '../../../../../../components';
import maskHelper from '../../../../../../helpers/mask.helper';
import { dictionaryError } from '../../../../../../helpers/utils.helper';
import { getTokenAccountInformation } from '../../../../../../helpers/token.helper';
import cpfValidation from '../../../../../../helpers/validations/cpf.validation';
import cnpjValidation from '../../../../../../helpers/validations/cnpj.validation';
import phoneValidate from '../../../../../../helpers/validations/phone.validate';

interface matrizOption {
  label: string;
  value: number;
}

interface generalProps extends formPropsPage {
  hasFranchisor: boolean;
}
const GeneralData: React.FC<generalProps> = ({
  nextPage,
  notify,
  defaultValue,
  hasFranchisor,
  setId,
  id,
}) => {
  const [companyId, setCompanyId] = useState<number | null>(null);
  const schema = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    name_fantasy: yup.string().required('Nome fantasia é obrigatório'),
    document: yup
      .string()
      .required('CPF/CNPJ é obrigatório')
      .min(11, 'Campo deve conter ao menos 11 dígitos')
      .test({
        message: 'CPF/CNPJ inválido',
        test: (value) =>
          value
            ? cpfValidation.isValid(maskHelper.number(value)) ||
              cnpjValidation.isValid(maskHelper.number(value))
            : false,
      }),
    phone: yup
      .string()
      .nullable()
      .min(10, 'Campo deve conter ao menos 10 dígitos')
      .max(11, 'Campo deve conter no máximo 11 dígitos')
      .test({
        message: 'Número inválido',
        test: (value) =>
          value ? phoneValidate.isValid(maskHelper.number(value)) : true,
      }),
    whatsapp: yup
      .string()
      .required('WhatsApp é obrigatório')
      .min(10, 'Campo deve conter entre 10 e 11 dígitos')
      .test({
        message: 'Número inválido',
        test: (value) =>
          value ? phoneValidate.isValid(maskHelper.number(value)) : false,
      }),
    email: yup
      .string()
      .email('E-mail inválido')
      .required('E-mail é obrigatório'),
    owner: yup.string().required('Proprietário é obrigatório'),
    is_franchise_matriz: yup.boolean().required(),
    is_franchisor: yup.boolean().required('Selecione uma opção'),
    is_franchise: yup.boolean().required('Selecione uma opção'),
  });
  const [generalDataValue, setGeralData] = useState<
    Omit<
      generalData,
      'is_wireframe' | 'is_generate_contract_opp' | 'is_vm' | 'token_vm'
    >
  >({
    document: defaultValue?.document ?? '',
    email: defaultValue?.email ?? '',
    is_franchise_matriz: defaultValue?.is_franchise_matriz ?? false,
    is_franchise: defaultValue?.is_franchise ?? false,
    is_franchisor: defaultValue?.is_franchise ?? false,
    name: defaultValue?.name ?? '',
    name_fantasy: defaultValue?.name_fantasy ?? '',
    owner: defaultValue?.owner ?? '',
    phone: defaultValue?.phone,
    whatsapp: defaultValue?.whatsapp ?? '',
    address: defaultValue?.address ?? '',
    admin_user_id: defaultValue?.admin_user_id ?? id,
    companie_id: defaultValue?.companie_id ?? companyId,
  });
  const [isMatriz, setIsMatriz] = useState(true);
  const { MatrizList } = useFetchMatriz('key');
  const matrizOptions: matrizOption[] = MatrizList.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const tokenData = getTokenAccountInformation();
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, setValue, formState } = useForm<generalData>({
    resolver: yupResolver(schema),
    defaultValues: {
      document: defaultValue?.document ?? '',
      email: defaultValue?.email ?? '',
      is_franchise_matriz: defaultValue?.is_franchise_matriz,
      is_franchise: defaultValue?.is_franchise,
      is_franchisor: defaultValue?.is_franchisor,
      name: defaultValue?.name ?? '',
      name_fantasy: defaultValue?.name_fantasy ?? '',
      owner: defaultValue?.owner ?? '',
      phone: defaultValue?.phone,
      whatsapp: defaultValue?.whatsapp ?? '',
    },
  });
  const Submit = async (values: generalData) => {
    const phone = values.phone === '' ? null : values.phone;
    if (defaultValue !== null) {
      setIsLoading(true);
      const response = await ApiCompany.updateGeneralData(
        { ...values, phone },
        defaultValue.id
      );
      if (response.id) {
        notify({
          message: 'Dados gerais atualizados com sucesso',
          type: 'Success',
        });
        nextPage(response);
      } else {
        notify({ message: dictionaryError(response), type: 'Error' });
        setIsLoading(false);
      }
    } else {
      setIsLoading(true);
      const response = await ApiCompany.createCompanyGeneralData({
        ...values,
        phone,
        admin_user_id: tokenData.id,
        companie_id: companyId,
      });
      if (response.id) {
        notify({ message: 'Dados gerais salvos com sucesso', type: 'Success' });
        setId({ ...response });
        nextPage();
      } else {
        notify({ message: dictionaryError(response), type: 'Error' });
        setIsLoading(false);
      }
    }
  };
  const typeCompany = [
    {
      label: 'Franqueador',
      value: 0,
      isdisabled: hasFranchisor,
    },
    { label: 'Franquia Matriz', value: 1, isdisabled: false },
    { label: 'Franquia Filial', value: 2, isdisabled: false },
  ];

  const configFlags = (profile: number) => {
    switch (profile) {
      case 0:
        setValue('is_franchisor', true);
        setValue('is_franchise', false);
        setValue('is_franchise_matriz', false);
        setIsMatriz(true);
        break;

      case 1:
        setValue('is_franchisor', false);
        setValue('is_franchise', true);
        setValue('is_franchise_matriz', true);
        setIsMatriz(true);
        break;

      case 2:
        setValue('is_franchisor', false);
        setValue('is_franchise', true);
        setValue('is_franchise_matriz', false);
        setIsMatriz(false);
        break;
    }
  };

  return (
    <form
      className="flex flex-col sm:grid sm:grid-cols-2 gap-4 mt-8"
      onSubmit={handleSubmit(Submit)}
    >
      {!defaultValue && (
        <>
          <SelectComponent
            title="Tipo de Empresa"
            closeMenuOnSelect
            isMulti={false}
            options={typeCompany}
            onChange={(e: any) => configFlags(e.value)}
            errorMensage={formState.errors.is_franchise?.message}
          />

          {!isMatriz && (
            <SelectComponent
              closeMenuOnSelect
              isMulti={false}
              options={matrizOptions}
              onChange={(e: any) => setCompanyId(e.value)}
              title="Franquia Matriz"
              errorMensage={formState.errors.is_franchise_matriz?.message}
            />
          )}
        </>
      )}
      <Input
        variant="outline-orange"
        title="Nome"
        value={generalDataValue.name}
        onChange={(e) => {
          setGeralData({ ...generalDataValue, name: e.target.value });
          setValue('name', e.target.value);
        }}
        errorMensage={formState.errors.name?.message}
      />

      <Input
        title="Proprietário"
        variant="outline-orange"
        value={generalDataValue.owner}
        onChange={(e) => {
          setGeralData({ ...generalDataValue, owner: e.target.value });
          setValue('owner', e.target.value);
        }}
        errorMensage={formState.errors.owner?.message}
      />

      <div
        className={
          defaultValue
            ? 'grid sm:grid-cols-3 gap-3'
            : 'grid sm:grid-cols-2 gap-3'
        }
      >
        {defaultValue && (
          <Input
            disabled
            value={defaultValue.id}
            title="Código da empresa"
            variant="outline-orange"
          />
        )}{' '}
        <Input
          variant="outline-orange"
          title="Apelido / Nome Fantasia"
          value={generalDataValue.name_fantasy}
          onChange={(e) => {
            setGeralData({ ...generalDataValue, name_fantasy: e.target.value });
            setValue('name_fantasy', e.target.value);
          }}
          errorMensage={formState.errors.name_fantasy?.message}
        />
        <Input
          title="CPF / CNPJ"
          variant="outline-orange"
          type="string"
          value={maskHelper.cnpjCpf(generalDataValue.document)}
          onChange={(e) => {
            e.target.value = maskHelper.cnpjCpf(e.target.value);
            setGeralData({ ...generalDataValue, document: e.target.value });
            setValue('document', maskHelper.numberCPFCNPJ(e.target.value));
          }}
          errorMensage={formState.errors.document?.message}
        />
      </div>

      <Input
        title="E-mail"
        variant="outline-orange"
        value={generalDataValue.email}
        onChange={(e) => {
          setGeralData({ ...generalDataValue, email: e.target.value });
          setValue('email', e.target.value);
        }}
        errorMensage={formState.errors.email?.message}
      />

      <Input
        title="Telefone"
        variant="outline-orange"
        value={maskHelper.phone(generalDataValue.phone ?? '')}
        onChange={(e) => {
          e.target.value = maskHelper.phone(e.target.value);
          setGeralData({ ...generalDataValue, phone: e.target.value });
          if (e.target.value === '') {
            setValue('phone', null);
          } else {
            setValue('phone', maskHelper.numberPhone(e.target.value));
          }
        }}
        errorMensage={formState.errors.phone?.message}
      />
      <Input
        title="WhatsApp"
        variant="outline-orange"
        value={maskHelper.phone(generalDataValue.whatsapp)}
        onChange={(e) => {
          e.target.value = maskHelper.phone(e.target.value);
          setGeralData({ ...generalDataValue, whatsapp: e.target.value });
          setValue('whatsapp', maskHelper.numberPhone(e.target.value));
        }}
        errorMensage={formState.errors.whatsapp?.message}
      />

      <div className="col-span-2 flex justify-center mt-6">
        <Button
          type="submit"
          actionType="button-loading"
          isLoading={isLoading}
          disabled={isLoading}
        >
          Salvar e continuar
        </Button>
      </div>
    </form>
  );
};

export default GeneralData;
