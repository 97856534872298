import React, { useState } from 'react';
import {
  Button,
  DeleteModal,
  SelectComponent,
  Spinner,
  Table,
} from '../../../../../components';
import IconDictionary from '../../../../../components/Icons/icons';
import { ToastNotify } from '../../../../../components/Toast/toast';
import maskHelper from '../../../../../helpers/mask.helper';
import {
  dictionaryError,
  getUrlPlatform,
} from '../../../../../helpers/utils.helper';
import { OfferProps } from '../../../../offer/types';
import RegisterOffer from '../../../../offer/components/register';
import { ApiOffer } from '../../../../offer/api';
import { useFetchOfferByOpportunity } from '../../../../offer/hooks';
import SendWhatsApp from './sendWhatsapp';
import SendEmailModal from './email';
import CreateContractModalAlert from './createContract';
import RegisterOfferWebtur from '../../../../offer/components/registerOfferWebtour';
import { offerTravelProps } from '../../../../offer/components/registerOfferWebtour/components/types';
import { useFetchProvider } from '../../../../providers/hooks';
import { getTokenAccountInformation } from '../../../../../helpers/token.helper';

/* eslint-disable no-unused-vars */
interface offersProps {
  opportunityId: number;
  currentOffer: OfferProps | null;
  setCurrentOffer: (e: OfferProps | null) => void;
  currentOfferTravel: offerTravelProps | null;
  setCurrentOfferTravel: (e: offerTravelProps | null) => void;
  setRegisterPage: (e: any) => void;
  registerPage: { title: string; page: number };
  setShowCreateUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  showCreateUpdate: boolean;
  reloadOpportunity: (e: string) => void;
  productsOptions: { label: string; value: number; price: number }[];
  personOpportunity: any[];
  setState: (e: boolean) => void;
}

const OpportunityOffers: React.FC<offersProps> = ({
  reloadOpportunity,
  setState,
  opportunityId,
  showCreateUpdate,
  currentOffer,
  registerPage,
  setCurrentOffer,
  setShowCreateUpdate,
  setRegisterPage,
  productsOptions,
  personOpportunity,
  currentOfferTravel,
  setCurrentOfferTravel,
}) => {
  const accountInformation = getTokenAccountInformation();
  const { notify } = ToastNotify();
  const [isLoadingPDF, setIsLoadingPDF] = useState(-1);
  const [isCreate, setIsCreate] = useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showCreateContract, setShowCreateContract] = useState(false);
  const [showModalEmail, setShowModalEmail] = useState(false);
  const [showModalWhatsApp, setShowModalWhatsApp] = useState(false);
  const [key, setKey] = useState('');
  const { OfferList, isLoadingOffers } = useFetchOfferByOpportunity(
    key,
    opportunityId
  );
  const accountProps = getTokenAccountInformation();

  const { ProviderList } = useFetchProvider('', accountProps.companie_id);
  const providersOptions = ProviderList.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const contactsIds = OfferList.data.map((item) => item.person_id);

  const statusOptions = [
    { label: 'Aberta', value: 'Aberta' },
    { label: 'Cancelada', value: 'Cancelada' },
    { label: 'Em Negociação', value: 'Em Negociação' },
    { label: 'Fechada', value: 'Fechada' },
    { label: 'Perdida', value: 'Perdida' },
  ];
  const statusColor = {
    Aberta: '#000000',
    Cancelada: '#dadada',
    'Em Negociação': '#FFD700',
    Fechada: '#33A70A',
    Perdida: '#DA3C3C',
  };
  async function downloadPdf(
    contractId: number,
    contractNumber: string,
    clientName: string
  ) {
    setIsLoadingPDF(contractId);
    const res = await ApiOffer.getPdfOffer(contractId);
    const downloadUrl = URL.createObjectURL(res);
    const download = document.createElement('a');
    download.href = downloadUrl;
    download.download = `${clientName}-${contractNumber}.pdf`;
    download.target = '_blank';
    document.body.appendChild(download);
    download.click();
    document.body.removeChild(download);
    URL.revokeObjectURL(downloadUrl);
    setIsLoadingPDF(-1);
  }

  const changeStatus = async (
    id: number,
    status: 'Em Negociação' | 'Aberta' | 'Cancelada' | 'Fechada' | 'Perdida'
  ) => {
    setIsLoadingStatus(true);
    const res = await ApiOffer.updateStatusOffer({ status }, id);
    if (res.id) {
      if (showCreateContract === true) return res;
      setKey(String(Math.random()));
      notify({ message: 'Status alterado com sucesso!', type: 'Success' });
    } else {
      notify({ message: dictionaryError(res), type: 'Error' });
    }
    setIsLoadingStatus(false);
    return false;
  };
  const columns = [
    {
      name: 'Número',
      key: 'number',
      minWidth: '70px',
      selector: (row: OfferProps) => row.number,
    },
    {
      name: 'Proprietário',
      minWidth: '110px',
      key: 'person_name',
      selector: (row: OfferProps) => row.person_name,
    },
    {
      name: 'Valor',
      minWidth: '70px',
      key: 'person_name',
      selector: (row: OfferProps) => maskHelper.formatMoeda(row.total),
    },
    {
      name: 'Criado em',
      key: 'created_at',

      selector: (row: OfferProps) => maskHelper.formatDateDMY(row.created_at),
    },
    {
      name: 'Alterado em',
      minWidth: '100px',
      key: 'updated_at',
      selector: (row: OfferProps) => maskHelper.formatDateDMY(row.updated_at),
    },
    {
      name: 'Status',
      key: 'updated_at',
      minWidth: '130px',
      cell: (row: OfferProps) => (
        <div className="overflow-visible py-1">
          <SelectComponent
            menuPosition="fixed"
            colorInputText={statusColor[row.status]}
            classNameDiv="mt-1.5"
            className="w-32"
            defaultValue={statusOptions.find(
              (item) => item.value === row.status
            )}
            options={statusOptions}
            onChange={(e: any) => {
              if (e.value === 'Fechada') {
                setCurrentOffer(row);
                setShowCreateContract(true);
              } else {
                changeStatus(row.id, e.value);
              }
            }}
          />
        </div>
      ),
    },
    {
      name: '',
      key: '',
      minWidth: '130px',
      cell: (row: OfferProps) => (
        <div className="flex w-full gap-2 justify-between">
          {isLoadingPDF === row.id ? (
            <Spinner size="w-[13px] h-[20px]" />
          ) : (
            <IconDictionary
              title="Salvar como PDF"
              name="PDF"
              size={20}
              className="cursor-pointer w-fit"
              onClick={() => downloadPdf(row.id, row.number, row.person_name)}
            />
          )}
          <IconDictionary
            title="Enviar por Whatsapp"
            name="Whatsapp"
            size={20}
            className="cursor-pointer w-fit"
            onClick={() => {
              setCurrentOffer(row);
              setShowModalWhatsApp(true);
            }}
          />
          <IconDictionary
            title="Enviar por e-mail"
            name="Email"
            size={20}
            className="cursor-pointer w￼Pagamento-fit"
            onClick={() => {
              setCurrentOffer(row);
              setShowModalEmail(true);
            }}
          />
          <IconDictionary
            title="Excluir Proposta"
            name="Excluir"
            size={20}
            className={
              row.contract.length === 0
                ? 'cursor-pointer w-fit text-red'
                : 'cursor-default text-gray-400'
            }
            onClick={() => {
              if (row.contract.length === 0) {
                setShowDelete(true);
                setCurrentOffer(row);
              }
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="w-full flex flex-col px-4">
      {currentOffer && (
        <>
          {showCreateContract && (
            <CreateContractModalAlert
              winRes={-1}
              opportunityId={opportunityId}
              personOpportunity={personOpportunity[0]}
              setState={() => setShowCreateContract(false)}
              closeOffer={(e: any) => changeStatus(currentOffer.id, e)}
              isLoading={isLoadingStatus}
              offer={currentOffer}
              setIsLoading={setIsLoadingStatus}
              setShow={setShowCreateContract}
              show={showCreateContract}
              updateOffer={setKey}
            />
          )}
          <SendWhatsApp
            messageText={`Olá ${
              currentOffer.person_name
            }, tudo bem? %0A%0A*Aqui está o link da proposta conforme combinado:* ${getUrlPlatform()}offer/pdf/${
              currentOffer.id
            } %0AQualquer dúvida estamos à disposição. %0A%0AObrigado.%0A*Atendimento LeadSim*`}
            number={currentOffer.person_whatsapp}
            setOpen={setShowModalWhatsApp}
            show={showModalWhatsApp}
          />
          <SendEmailModal
            offer={currentOffer}
            close={() => setShowModalEmail(false)}
            id={currentOffer.id}
            open={showModalEmail}
          />
        </>
      )}
      {accountInformation.companie_id !== 11 ? (
        <RegisterOffer
          personOpportunity={personOpportunity.filter(
            (item) => !contactsIds.includes(item.person_id)
          )}
          isCreate={isCreate}
          productsOptions={productsOptions}
          reloadTable={setKey}
          setRegisterPage={setRegisterPage}
          setOffer={setCurrentOffer}
          registerPage={registerPage}
          setShow={setShowCreateUpdate}
          show={showCreateUpdate}
          offer={currentOffer}
          opportunityId={opportunityId}
        />
      ) : (
        <RegisterOfferWebtur
          offerTravel={currentOfferTravel}
          providerOptions={providersOptions}
          isCreate={isCreate}
          reloadTable={setKey}
          setRegisterPage={setRegisterPage}
          setOffer={setCurrentOfferTravel}
          registerPage={registerPage}
          setShow={setShowCreateUpdate}
          show={showCreateUpdate}
          opportunityId={opportunityId}
        />
      )}

      <Button
        actionType="button-add"
        variant="primary-strong"
        className="w-52"
        onClick={() => {
          setRegisterPage({
            title: 'Dados Pessoais',
            page: 0,
          });
          setCurrentOffer(null);
          setShowCreateUpdate(true);
          setIsCreate(true);
        }}
      >
        Adicionar Proposta
      </Button>
      <Table
        isLoading={isLoadingOffers}
        columns={columns}
        data={OfferList.data}
        selectedRowId={-1}
        onRowClick={(e: OfferProps) => {
          setIsCreate(false);
          setRegisterPage({
            title: 'Dados Pessoais',
            page: 0,
          });
          setCurrentOffer(e);
          setShowCreateUpdate(true);
        }}
      />

      {currentOffer && (
        <DeleteModal
          apiFunction={ApiOffer.deleteOffer}
          notify={notify}
          type="Proposta"
          article="A"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowDelete(false)}
          id={currentOffer.id}
          name="essa proposta"
          open={showDelete}
        />
      )}
    </div>
  );
};

export default OpportunityOffers;
