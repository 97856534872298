import React from 'react';
import moment from 'moment';
import {
  Button,
  HeaderModal,
  Modal,
  SelectComponent,
} from '../../../../components';
import Package from './components/package';
import Inclusion from './components/inclusion';
import DetailsFlight from './components/detailsFlight';
import PaymentForm from './components/paymentForm';
import Summary from './components/summary';
import { mockupData } from './utils';
import { offerTravelProps } from './components/types';

interface page {
  title: string;
  page: number;
}

/* eslint-disable no-unused-vars */
interface registerModalProps {
  isCreate: boolean;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  offerTravel: offerTravelProps | null;
  setOffer: (e: offerTravelProps) => void;
  registerPage: page;
  setRegisterPage: React.Dispatch<React.SetStateAction<page>>;
  opportunityId: number;
  providerOptions: { label: string; value: number }[];
}

const header = [
  {
    id: 0,
    title: 'Pacote',
    page: 0,
  },
  {
    id: 1,
    title: 'Inclusão',
    page: 1,
  },
  {
    id: 2,
    title: 'Detalhamento de voo',
    page: 2,
  },
  {
    id: 3,
    title: 'Forma de pagamento',
    page: 3,
  },
  {
    id: 4,
    title: 'Resumo',
    page: 4,
  },
];

const RegisterOfferWebtur: React.FC<registerModalProps> = ({
  show,
  setShow,
  registerPage,
  offerTravel,
  setOffer,
  setRegisterPage,
  reloadTable,
  opportunityId,
  providerOptions,
  isCreate,
}) => {
  const typeOffer = [{ label: 'Pacote nacional', value: 'national' }];

  const nextPage = () => {
    if (registerPage.page < header.length - 1) {
      setRegisterPage({
        title: header[registerPage.page + 1].title,
        page: registerPage.page + 1,
      });
    } else {
      setShow(false);
    }
  };

  const backPage = () => {
    if (registerPage.page - 1 > -1) {
      setRegisterPage({
        title: header[registerPage.page - 1].title,
        page: registerPage.page - 1,
      });
    } else {
      setShow(false);
    }
  };

  return (
    <Modal
      title={offerTravel && !isCreate ? 'Atualizar proposta' : 'Criar proposta'}
      isOpen={show}
      setIsOpen={(e) => {
        setShow(e);
        reloadTable(`${Math.random()}`);
      }}
      size="6xlarge"
      minHeight="min-h-[900px]"
    >
      <div className="min-h-[800px] flex flex-col">
        <SelectComponent
          title="Tipo de proposta"
          options={typeOffer}
          className="w-full md:w-64"
        />
        <HeaderModal
          // disabled={!(offer && offer.id)}
          disabled={false}
          header={header}
          registerPage={registerPage}
          setRegisterPage={setRegisterPage}
        />
        {registerPage.page === 0 && (
          <Package providerOptions={providerOptions} defaultValue={null} />
        )}
        {registerPage.page === 1 && <Inclusion />}
        {registerPage.page === 2 && <DetailsFlight />}
        {registerPage.page === 3 && <PaymentForm />}
        {registerPage.page === 4 && (
          <Summary
            arrivalDate={moment(mockupData.arrivalDate).toDate()}
            companieFlight={mockupData.companieFlight}
            departureDate={moment(mockupData.departureDate).toDate()}
            destiny={mockupData.destiny}
            formPayment={mockupData.formPayment}
            hotels={mockupData.hotels}
            ida={mockupData.ida}
            orderNumber={mockupData.orderNumber}
            serviceObs={mockupData.serviceObs}
            services={mockupData.services}
            volta={mockupData.volta}
          />
        )}

        <div className="flex gap-2 mt-5 place-self-end mx-auto w-full lg:w-1/2">
          <Button
            onClick={backPage}
            actionType="button-full-width"
            variant="outline-primary"
          >
            Voltar
          </Button>
          <Button
            onClick={nextPage}
            actionType="button-full-width"
            variant="primary-strong"
          >
            {registerPage.page !== 4 ? 'Salvar e continuar' : 'Salvar'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RegisterOfferWebtur;
