import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Address, Attachments, Button, Modal } from '../../../components';
import { ToastNotify } from '../../../components/Toast/toast';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import GeneralData from './createUpdate/generalData';
import { contractProps } from '../types';
import { ApiContract } from '../api';
import AdicionalData from './createUpdate/adicionalProps';
import PromoterSales from './createUpdate/promoterSales';
import HistoryPayments from './createUpdate/history';
import { Plans } from '../../chartAccounts/types';
import { attachment } from '../../../components/Register/attachments';
import PaymentContract from './createUpdate/payment/index';
import { useFetchClientDropdown } from '../../clients/hooks';
import Dependents from './dependents/dependents';

interface page {
  title: string;
  page: number;
  isCreate: boolean;
}

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  setContract: (e: contractProps) => void;
  contract: contractProps | null;
  registerPage: page;
  setRegisterPage: React.Dispatch<React.SetStateAction<page>>;
  usersOptions: { label: string; value: number }[];
  PlanList: Plans[];
  productsOptions: { label: string; value: number; price: number }[];
  optionsSegment: { label: string; value: string }[];
  galaxyFormPayments: { id: string; displayName: string }[];
}

const header = [
  {
    id: 0,
    title: 'Dados Pessoais',
    page: 0,
  },
  {
    id: 1,
    title: 'Info. Adicional',
    page: 1,
  },
  {
    id: 2,
    title: 'Endereço',
    page: 2,
  },
  {
    id: 3,
    title: 'Pagamento',
    page: 3,
  },
  {
    id: 4,
    title: 'Captador',
    page: 4,
  },
  {
    id: 5,
    title: 'Anexos',
    page: 5,
  },
  {
    id: 6,
    title: 'Hist. Pagamentos',
    page: 6,
  },
  {
    id: 7,
    title: 'Dependentes',
    page: 7,
  },
];

const RegisterContract: React.FC<registerModalProps> = ({
  PlanList,
  show,
  setShow,
  registerPage,
  galaxyFormPayments,
  setRegisterPage,
  reloadTable,
  setContract,
  contract,
  usersOptions,
  productsOptions,
  optionsSegment,
}) => {
  const { notify } = ToastNotify();
  const [isLoading, setSetIsLoading] = useState(false);
  const [key, setKey] = useState('attachments');
  const [keyClient, setKeyClient] = useState('');
  const tokenCompanyId = getTokenAccountInformation().companie_id;
  const accountProps = getTokenAccountInformation();
  const [contractId, setContractId] = useState(-1);

  const [currentContractClient, setCurrentContractClient] =
    useState<contractProps | null>(contract ?? null);
  const [inputClient, setInputClient] = useState('');

  const { ClientsList, isLoadingClientsDropDown } = useFetchClientDropdown(
    keyClient,
    0,
    1000000000,
    inputClient
  );

  const [attachmentsList, setAttachmentsList] = useState<attachment[]>(
    contract ? contract.contract_attachment : []
  );

  const nextPage = () => {
    if (registerPage.page < header.length - 1) {
      setRegisterPage({
        title: header[registerPage.page + 1].title,
        page: registerPage.page + 1,
        isCreate: registerPage.isCreate,
      });
    } else {
      reloadTable(`${Math.random()}`);
      setShow(false);
    }
  };

  useEffect(() => {
    const FetchCompanyAttachments = async () => {
      setSetIsLoading(true);
      const res =
        (contract?.id ?? contractId) === -1
          ? []
          : await ApiContract.getContractAttachments(
              contract?.id ?? contractId
            );
      if (typeof res !== 'string') {
        setAttachmentsList(res);
      }
      setSetIsLoading(false);
    };
    if (key !== 'attachments') FetchCompanyAttachments();
  }, [key]);

  useEffect(() => {
    if (contract) {
      setInputClient(contract.client_name);

      setAttachmentsList(contract.contract_attachment);
      setCurrentContractClient(contract);
    }
  }, [show]);

  return (
    <Modal
      title={contract ? 'Atualizar Contrato' : 'Cadastrar Contrato'}
      isOpen={show}
      setIsOpen={(e) => {
        setShow(e);
        setCurrentContractClient(null);
        reloadTable(`${Math.random()}`);
      }}
      size="6xlarge"
      minHeight="min-h-[775px]"
    >
      <div className="flex flex-col">
        <div className="grid grid-cols-2 lg:flex gap-2 mt-9 ">
          {header.map((item) => (
            <Button
              key={item.id}
              variant={
                registerPage.page === item.page ? 'primary-strong' : 'gray'
              }
              actionType="button-full-width"
              className={classNames([
                'font-medium',
                'lg:w-32',
                contract !== null ? 'cursor-pointer' : 'cursor-default',
              ])}
              onClick={() =>
                contract !== null &&
                setRegisterPage({
                  title: item.title,
                  page: item.page,
                  isCreate: registerPage.isCreate,
                })
              }
            >
              {item.title}
            </Button>
          ))}
        </div>
        <hr className="w-full h-[1.5px] bg-primary mt-3" />

        {registerPage.page === 0 && (
          <GeneralData
            isLoadingClients={isLoadingClientsDropDown}
            setInputClient={setInputClient}
            setCurrentContract={setContract}
            setClient={setCurrentContractClient}
            isEditable={contract ? contract.status === 'Aberto' : true}
            reloadClientList={setKeyClient}
            clients={ClientsList.data}
            setId={setContractId}
            notify={notify}
            nextPage={nextPage}
            defaultValue={contract}
            id={contract?.id ?? contractId}
          />
        )}
        {registerPage.page === 1 && (
          <AdicionalData
            optionsSegment={optionsSegment}
            setCurrentContract={setContract}
            isEditable
            setId={setContractId}
            notify={notify}
            nextPage={nextPage}
            defaultValue={contract}
            id={contract?.id ?? contractId}
          />
        )}
        {registerPage.page === 2 && (
          <Address
            isAllFieldsDisabled={
              !(contract ? contract.status === 'Aberto' : true)
            }
            hasNumber
            apiFunction={async (values, id) => {
              const res = await ApiContract.updateAdressContract(
                {
                  client_address: values.address,
                  client_address_city: values.city,
                  client_address_complement: values.complement ?? '',
                  client_address_number: values.number,
                  client_address_province: values.province,
                  client_address_state: values.state,
                  client_address_zipcode: values.zipcode,
                },
                id
              );
              if (res.client_address) {
                setCurrentContractClient(res);
                if (contract) {
                  if (contract.is_financial_responsible_client === true) {
                    const resPay = await ApiContract.updatePaymentResContract(
                      {
                        is_financial_responsible_client: true,
                        resp_financial_type: 'Física',
                        resp_financial_name: contract.client_name,
                        resp_financial_document: contract.client_document,
                        resp_financial_email: contract.client_email,
                        resp_financial_phone: contract.resp_financial_phone,
                        resp_financial_whatsapp: contract.client_whatsapp,
                        resp_financial_address: res.client_address,
                        resp_financial_number: res.client_address_number,
                        resp_financial_complement:
                          res.client_address_complement,
                        resp_financial_province: res.client_address_province,
                        resp_financial_city: res.client_address_city,
                        resp_financial_state: res.client_address_state,
                        resp_financial_zipcode: res.client_address_zipcode,
                      },
                      contract.id
                    );
                    if (resPay.res_financial_name) {
                      setContract({
                        ...contract,
                        is_financial_responsible_client: true,
                        resp_financial_type: 'Física',
                        resp_financial_name: contract.client_name,
                        resp_financial_document: contract.client_document,
                        resp_financial_email: contract.client_email,
                        resp_financial_phone: contract.resp_financial_phone,
                        resp_financial_whatsapp: contract.client_whatsapp,
                        resp_financial_address: res.client_address,
                        resp_financial_number: res.client_address_number,
                        resp_financial_complement:
                          res.client_address_complement,
                        resp_financial_province: res.client_address_province,
                        resp_financial_city: res.client_address_city,
                        resp_financial_state: res.client_address_state,
                        resp_financial_zipcode: res.client_address_zipcode,
                      });
                    }
                    setContract({
                      ...contract,
                      client_address: values.address,
                      client_address_city: values.city,
                      client_address_complement: values.complement ?? '',
                      client_address_number: values.number,
                      client_address_province: values.province,
                      client_address_state: values.state,
                      client_address_zipcode: values.zipcode,
                    });
                  }
                }
              }
              return { id: contract ? contract.id : contractId, ...res };
            }}
            defaultValue={
              contract?.client_address_number
                ? {
                    address: contract.client_address,
                    city: contract.client_address_city,
                    complement: contract.client_address_complement,
                    province: contract.client_address_province,
                    state: contract.client_address_state,
                    zipcode: contract.client_address_zipcode,
                    number: contract.client_address_number,
                  }
                : currentContractClient && {
                    address: currentContractClient.client_address ?? '',
                    city: currentContractClient.client_address_city ?? '',
                    complement: currentContractClient.client_address_complement,
                    province:
                      currentContractClient.client_address_province ?? '',
                    state: currentContractClient.client_address_state ?? '',
                    zipcode: currentContractClient.client_address_zipcode ?? '',
                    number: currentContractClient.client_address_number,
                  }
            }
            id={contract?.id ?? contractId}
            nextPage={nextPage}
          />
        )}
        {registerPage.page === 3 && (
          <PaymentContract
            setCurrentContract={setContract}
            galaxyFormPayments={galaxyFormPayments}
            productsOptions={productsOptions}
            clientDefault={currentContractClient}
            isCreate={registerPage.isCreate}
            isEditable={contract ? contract.status === 'Aberto' : true}
            setId={setContractId}
            notify={notify}
            nextPage={nextPage}
            defaultValue={contract}
            id={contract?.id ?? contractId}
          />
        )}
        {registerPage.page === 4 && (
          <PromoterSales
            setCurrentContract={setContract}
            userOptions={usersOptions}
            isEditable
            setId={setContractId}
            notify={notify}
            nextPage={nextPage}
            defaultValue={contract}
            id={contract?.id ?? contractId}
          />
        )}

        {registerPage.page === 5 && (
          <Attachments
            AttachmentsList={attachmentsList}
            apiFunction={ApiContract.createContractAttachments}
            deleteApiFunction={ApiContract.deleteAttachments}
            id={contract?.id ?? contractId}
            keyFile="contract_id"
            nextPage={nextPage}
            updateTable={setKey}
            isLoadingTable={isLoading}
          />
        )}

        {registerPage.page === 6 && (
          <HistoryPayments
            PlanList={PlanList}
            isEditable
            setId={setContractId}
            notify={notify}
            nextPage={nextPage}
            defaultValue={contract}
            id={contract?.id ?? contractId}
          />
        )}

        {registerPage.page === 7 && (
          <Dependents contractId={contract?.id ?? contractId} />
        )}

        <div className="flex w-full gap-2 justify-center mt-9">
          {header.map((item) =>
            item.page === registerPage.page ? (
              <div className="h-3 w-3 rounded-full bg-primary" key={item.id}>
                {' '}
              </div>
            ) : (
              <div className="h-3 w-3 rounded-full bg-gray-400" key={item.id}>
                {' '}
              </div>
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RegisterContract;
