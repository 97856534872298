import { SelectComponent } from '../../../../../../components';
import { companieFlightsOption } from '../../utils';

const SelectCompanie = () => (
  <SelectComponent
    iconClass="text-primary"
    icon="Ticket Avião"
    classNameDiv="sm:col-span-2 lg:col-span-4 w-full"
    options={companieFlightsOption}
    title="Companhia aérea"
  />
);

export default SelectCompanie;
