import React from 'react';
import classNames from 'classnames';
import Button from '../Button/button';

type Page = {
  id: number;
  page: number;
  title: string;
};

interface props {
  disabled: boolean;
  setRegisterPage: (e: { title: string; page: number }) => void;
  registerPage: { title: string; page: number };
  header: Page[];
}

const HeaderModal: React.FC<props> = ({
  header,
  registerPage,
  disabled,
  setRegisterPage,
}) => (
  <div className="grid grid-cols-2 sm:flex gap-2 mt-9 w-full">
    {header.map((item) => (
      <Button
        key={item.id}
        variant={registerPage.page === item.page ? 'primary-strong' : 'gray'}
        actionType="button-full-width"
        className={classNames([
          'font-medium',
          !disabled ? 'cursor-pointer' : 'cursor-default',
        ])}
        onClick={() => !disabled && setRegisterPage(item)}
      >
        {item.title}
      </Button>
    ))}
  </div>
);

export default HeaderModal;
