import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetchWhatsappCampaignPerson } from '../../hooks';
import { PersonStatusByCampaign, whatsappCampaignProps } from '../../types';
import TableWithPaginationBack from '../../../../components/Table/tableWithPaginationBack';
import { CampaignUtils } from '../../utils';
import { Button } from '../../../../components';
import { ApiWhatsappCampaign } from '../../api';

/* eslint-disable no-unused-vars */
interface contactList {
  whatsAppMessages: any[];
  campaign: whatsappCampaignProps;
  prevPage: () => void;
  notify: any;
  setCampaign: (campaign: whatsappCampaignProps) => void;
  instance: any;
}

const CreateMessages: React.FC<contactList> = ({
  campaign,
  whatsAppMessages,
  instance,
  prevPage,
  setCampaign,
  notify,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const columns = [
    {
      name: 'Contato',
      key: 'nome',
      selector: (row: PersonStatusByCampaign) => row.person.name,
    },
    {
      name: 'WhatsApp',
      key: 'organization',
      selector: (row: PersonStatusByCampaign) => row.person.whatsapp,
    },

    {
      name: 'Status',
      key: 'status',
      selector: (row: PersonStatusByCampaign) =>
        CampaignUtils.getTextByStatusMessage(row.status),
    },
    {
      name: 'Descrição',
      key: 'description',
      selector: (row: PersonStatusByCampaign) => {
        if (row.status === 'Enviado') {
          return 'Mensagem enviada com sucesso.';
        }
        const json = row.campaign_person_sent[0];

        if (row.status === 'Não enviado') {
          if (
            json.json.failedReason.includes('exists') &&
            json.json.failedReason.includes('false')
          ) {
            return `O número do contato não existe no WhatsApp.`;
          }
        }

        if (json?.json.failedReason.includes('AxiosError')) {
          return 'Ocorreu um problema ao enviar o arquivo da mensagem.';
        }
        return '---';
      },
    },
  ];

  const [skip, setSkip] = useState(0);
  const [Key, setKey] = useState('');
  const take = 10;
  const { PersonWhatsappCampaignList, isLoadingWhatsapp } =
    useFetchWhatsappCampaignPerson(Key, campaign.id, skip, take);

  const startCampain = async () => {
    if (CampaignUtils.verifyStatusInstance(instance)) {
      notify({
        message: 'Instância em disparo por outra campanha',
        type: 'Warning',
      });
      return;
    }
    ApiWhatsappCampaign.updateWhatsappCampaignStatus(
      { status: 'shooting' },
      campaign.id
    );
    setCampaign({ ...campaign, status: 'Em disparo' });
    setKey(String(Math.random()));

    setTimeout(() => {
      navigate('/bulk-shipping');
    }, 1000);
  };

  return (
    <div className="w-full flex flex-col px-4 mt-5">
      <div className="flex gap-2 text-sm mb-2">
        <strong>Status:</strong>
        {CampaignUtils.getTextByStatus(campaign.status)}
      </div>
      <div className="w-fit text-sm mt-2 rounded px-2 border border-dashed border-primary py-3 bg-primary/50 ">
        {campaign.companie_whatsapp_messages_default.text}
      </div>
      <div className="lg:place-self-end flex justify-center gap-3 w-full lg:w-72 mt-5">
        <Button
          actionType="button-loading"
          variant="outline-primary"
          onClick={prevPage}
        >
          Voltar
        </Button>
        {(campaign.status === 'Não iniciada' ||
          campaign.status === 'Pausada') && (
          <Button
            actionType="button-loading"
            variant="primary-strong"
            onClick={startCampain}
          >
            Iniciar disparo
          </Button>
        )}
        {campaign.status === 'Em disparo' && (
          <Button actionType="button-loading" disabled>
            Processando...
          </Button>
        )}
      </div>

      <div className="min-h-[40vh] my-5">
        <TableWithPaginationBack
          fixedHeader
          fixedHeaderScrollHeight="300px"
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          isLoading={isLoadingWhatsapp}
          columns={columns}
          setStartDate={setSkip}
          totalRegisters={PersonWhatsappCampaignList.total}
          data={PersonWhatsappCampaignList.data}
          totalPerPage={10}
          selectedRowId={-1}
        />
      </div>
    </div>
  );
};

export default CreateMessages;
