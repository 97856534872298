/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  contractProps,
  productsContractProps,
  updateProductContractProps,
} from '../../../types';
import maskHelper from '../../../../../helpers/mask.helper';
import { ApiContract } from '../../../api';
import IconDictionary from '../../../../../components/Icons/icons';
import { SelectComponent, Spinner } from '../../../../../components';

interface inputTableProps {
  id?: string;
  value?: string | number;
  defaultValue?: string | number;
  size?: string;
  spanColumn?: boolean;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  errorMen?: string;
}

const InputCustomTable = ({
  value,
  defaultValue,
  disabled = false,
  spanColumn = true,
  id,
  size = 'w-44',
  errorMen,
  onBlur,
  onChange,
}: inputTableProps) => (
  <div
    className={`flex items-center px-2 py-5 ${size} ${spanColumn ? '' : ''}`}
  >
    <input
      defaultValue={defaultValue}
      id={id}
      className="disabled:bg-[#f2f2f2] disabled:text-gray-600 my-auto w-full text-xs border-solid border border-[#ddd] rounded-lg p-3 font-normal"
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      disabled={disabled}
    />
    <p className="absolute bottom-0.5 text-[11px] text-red">{errorMen}</p>
  </div>
);

interface props {
  defaultValue: contractProps;
  productsOptions: { label: string; value: number; price: number }[];
  isEditable: boolean;
  isLoadingTable: boolean;
  productServiceList: productsContractProps[];
  setProductServerList: (e: productsContractProps[]) => void;
  setIsLoadingTable: (e: boolean) => void;
  setKey: (e: string) => void;
  galaxyFormPayments: { id: string; displayName: string }[];
  formOfPayment: string;
  setFormOfPayment: React.Dispatch<React.SetStateAction<string>>;
}

const PaymentProductsTable: React.FC<props> = ({
  defaultValue,
  productsOptions,
  isEditable,
  isLoadingTable,
  productServiceList,
  galaxyFormPayments,
  setIsLoadingTable,
  setProductServerList,
  setKey,
  formOfPayment,
  setFormOfPayment,
}) => {
  const [[indexMen, error], setError] = useState([-1, '']);

  const paymentForm = [
    { label: 'Cartão de Crédito', value: 'creditcard' },
    { label: 'Boleto', value: 'boleto' },
    { label: 'Pix', value: 'pix' },
  ];
  const paymentFormFilter = paymentForm.filter((option) =>
    galaxyFormPayments.some((serverOption) => serverOption.id === option.value)
  );

  const paymentMethod = [
    { label: 'À vista', value: 'in_sight' },
    { label: 'Parcelado', value: 'installments' },
    { label: 'Recorrência', value: 'recurrence' },
  ];

  const addProductSelect = null;

  const numberOfInstallments = (total: string) => {
    const installments = [];
    for (let n = 1; n <= 12; n++) {
      installments.push({
        label: `${n}x ${maskHelper.formatMoeda(String(Number(total) / n))}`,
        value: n,
      });
    }
    return installments;
  };

  const CreateProduct = async (
    e: { label: string; price: number; value: number },
    idContract: number
  ) => {
    setIsLoadingTable(true);
    const response = await ApiContract.createProductServiceContract({
      contract_id: idContract,
      product_id: e.value,
      product_name: e.label,
      product_value: e.price,
      qtd: 1,
      form_of_payment: formOfPayment,
      method: 'in_sight',
      qtd_installments: 1,
      total: Number(e.price),
      value: Number(e.price),
    });
    if (response.id) {
      setKey(String(Math.random()));
    } else {
      setIsLoadingTable(false);
    }
  };

  const updateProduct = async (
    e: updateProductContractProps,
    idItem: number,
    index: number
  ) => {
    setIsLoadingTable(true);
    await ApiContract.updateProductContract(e, idItem);
    setKey(`${Math.random()}`);
  };

  const deleteProduct = async (idProd: number) => {
    setIsLoadingTable(true);
    const res = await ApiContract.deleteProductContract(idProd);
    if (res === true) setKey(String(Math.random()));
    else setIsLoadingTable(false);
  };

  useEffect(() => {
    const updateFormPayment = () => {
      productServiceList.forEach((item, index) => {
        ApiContract.updateProductContract(
          {
            form_of_payment: formOfPayment,
            method: item.method,
            product_value: Number(item.product_value),
            qtd: Number(item.qtd),
            qtd_installments: item.qtd_installments,
            total: Number(item.total),
            value: Number(item.value),
          },
          item.id
        );
        item.form_of_payment = formOfPayment;
      });
      setProductServerList([...productServiceList]);
    };
    if (
      productServiceList[0]?.form_of_payment !== formOfPayment &&
      formOfPayment !== ''
    )
      updateFormPayment();
  }, [formOfPayment]);

  useEffect(() => {
    if (productServiceList.length > 0)
      setFormOfPayment(productServiceList[0].form_of_payment);
    else setFormOfPayment('creditcard');
  }, [productServiceList]);

  return (
    <div className="min-h-[300px] w-full flex flex-col col-span-2 gap-3 max-w-full overflow-x-auto overflow-y-visible xl:overflow-visible">
      <p className="text-sm font-medium col-span-2 mt-2">Itens do contrato</p>
      <hr className="w-full h-1 -mt-2 rounded-md mb-1 bg-primary/80 col-span-2 min-w-[980px]" />
      <SelectComponent
        value={addProductSelect}
        isDisabled={!isEditable}
        isClearable
        options={productsOptions.filter(
          (item) =>
            productServiceList.find(
              (prod) => prod.product_id === item.value
            ) === undefined
        )}
        closeMenuOnSelect
        classNameDiv="mx-0.5"
        placeholder="Adicionar Produto/Serviço"
        onChange={(e: any) => {
          if (e !== null && defaultValue) {
            CreateProduct(e, defaultValue.id);
          }
        }}
      />
      {productServiceList.length > 0 ? (
        <div className="flex flex-col col-span-2 min-w-[980px] w-full relative">
          {isLoadingTable && (
            <div className="absolute w-full h-full bg-gray-400/30 z-10 flex items-center justify-center">
              <Spinner />
            </div>
          )}
          <div className="grid grid-cols-6 w-full pb-3">
            <div className="flex justify-between col-span-3">
              <p className="text-xs font-semibold w-36">Item do contrato</p>
              <p className="text-xs font-semibold w-20 text-center">
                Quantidade
              </p>
              <p className="text-xs font-semibold w-40 text-center">Valor</p>
              <p className="text-xs font-semibold w-40 text-center">Total</p>
            </div>

            <p className="text-xs font-semibold mx-auto">Forma de pagamento</p>
            <div className="flex justify-between col-span-2">
              <p className="text-xs font-semibold text-center w-36 mx-2">
                Método
              </p>
              <p className="text-xs font-semibold text-center w-36 mx-2">
                Parcelas
              </p>
              <p className="w-5" />
            </div>
          </div>
          <div className="grid grid-cols-6 max-w-full border-t border-solid border-gray-400 divide-x divide-solid divide-gray-400 h-full">
            <div className="flex flex-col col-span-3">
              {productServiceList.map((item, index) => (
                <div
                  key={item.total}
                  className={`flex justify-between ${
                    index === 0 ? 'border-0' : 'border-t'
                  } border-solid border-gray-400 h-fit divide-x divide-solid divide-gray-400`}
                >
                  <div className="text-xs flex h-full items-center w-36">
                    {item.product_name}
                  </div>
                  <InputCustomTable
                    key={`qtd_contract_products_${item.id}`}
                    disabled={!isEditable}
                    errorMen={indexMen === index ? error : ''}
                    defaultValue={Number(item.qtd).toFixed(3).replace('.', ',')}
                    onChange={(e) => {
                      e.target.value = maskHelper.formatDecimalQty(
                        e.target.value
                      );

                      const qtd: any = Number(
                        (
                          Number(maskHelper.number(e.target.value)) / 1000
                        ).toFixed(3)
                      );

                      const total: any = document.getElementById(
                        `total_${item.id}`
                      );

                      if (total)
                        total.value = maskHelper.formatMoeda(
                          parseFloat(item.value) * qtd
                        );
                    }}
                    onBlur={(e) => {
                      const qtd = Number(
                        maskHelper.numberCurrencyDecimal(e.target.value)
                      );

                      if (qtd > 99.999) {
                        setError([index, 'Valor máximo 99,999']);
                        return;
                      }

                      if (defaultValue)
                        updateProduct(
                          {
                            form_of_payment: formOfPayment,
                            method: item.method,
                            product_value: Number(item.product_value),
                            qtd,
                            qtd_installments: item.qtd_installments,
                            total: Number(
                              (qtd * Number(item.value)).toFixed(2)
                            ),
                            value: Number(item.value),
                          },
                          item.id,
                          index
                        );

                      setError([-1, '']);
                    }}
                    spanColumn={false}
                  />
                  <InputCustomTable
                    key={`value_contract_products_${item.total}`}
                    disabled={!isEditable}
                    value={
                      item.value === '0'
                        ? maskHelper.formatMoeda(item.product_value)
                        : maskHelper.formatMoeda(item.value)
                    }
                    onChange={(e) => {
                      e.target.value = maskHelper.formatCurrencyInput(
                        e.target.value
                      );
                      productServiceList[index] = {
                        ...item,
                        value: maskHelper.numberCurrencyDecimal(e.target.value),
                      };
                      setProductServerList([...productServiceList]);
                      const total: any = document.getElementById(
                        `total_${item.id}`
                      );
                      if (total)
                        total.value = maskHelper.formatMoeda(
                          Number(
                            maskHelper.numberCurrencyDecimal(e.target.value)
                          ) * item.qtd
                        );
                    }}
                    onBlur={(e) => {
                      updateProduct(
                        {
                          form_of_payment: formOfPayment,
                          method: item.method,
                          product_value: Number(item.product_value),
                          qtd: item.qtd,
                          qtd_installments: item.qtd_installments,
                          total:
                            Number(
                              maskHelper.numberCurrencyDecimal(e.target.value)
                            ) * item.qtd,
                          value: Number(
                            maskHelper.numberCurrencyDecimal(e.target.value)
                          ),
                        },
                        item.id,
                        index
                      );
                    }}
                  />
                  <InputCustomTable
                    key={`total_${item.id}`}
                    id={`total_${item.id}`}
                    defaultValue={
                      Number(item.total) > 0
                        ? maskHelper.formatMoeda(item.total)
                        : maskHelper.formatMoeda(item.product_value)
                    }
                    disabled
                  />
                </div>
              ))}
            </div>
            <div className="grid w-full h-full px-2">
              <SelectComponent
                isDisabled={!isEditable}
                closeMenuOnSelect
                options={paymentFormFilter}
                className="w-full my-auto"
                value={paymentFormFilter.find(
                  (item) => item.value === formOfPayment
                )}
                onChange={(e: any) => {
                  setFormOfPayment(e.value);
                }}
              />
            </div>

            <div className="flex flex-col col-span-2 h-full">
              {productServiceList.map((item, index) => (
                <div
                  className={`flex items-center justify-between h-full border-solid ${
                    index !== 0 ? 'border-t' : ''
                  } border-gray-400`}
                >
                  <SelectComponent
                    isDisabled={!isEditable}
                    value={paymentMethod.find(
                      (elem) => elem.value === item.method
                    )}
                    closeMenuOnSelect
                    options={paymentMethod}
                    className="mx-2 my-2 w-36"
                    onChange={(e: any) => {
                      productServiceList[index] = {
                        ...item,
                        method: e.value,
                        qtd_installments:
                          e.value === 'recurrence'
                            ? null
                            : item.qtd_installments === null
                            ? 1
                            : item.qtd_installments,
                      };

                      setProductServerList([...productServiceList]);

                      updateProduct(
                        {
                          form_of_payment: formOfPayment,
                          method: e.value,
                          product_value: Number(item.product_value),
                          qtd: item.qtd,
                          qtd_installments:
                            e.value === 'recurrence'
                              ? null
                              : item.qtd_installments === null
                              ? 1
                              : item.qtd_installments,
                          total: Number(item.total),
                          value: Number(item.value),
                        },
                        item.id,
                        index
                      );
                    }}
                  />

                  <SelectComponent
                    value={
                      item.method === 'recurrence'
                        ? { label: 'n', value: null }
                        : item.method === 'in_sight'
                        ? {
                            label: `1x ${maskHelper.formatMoeda(item.total)}`,
                            value: 1,
                          }
                        : numberOfInstallments(item.total).find(
                            (elem) => elem.value === item.qtd_installments
                          )
                    }
                    options={numberOfInstallments(item.total)}
                    closeMenuOnSelect
                    maxOptionsHeight="170px"
                    isDisabled={
                      item.method === 'in_sight' ||
                      item.method === 'recurrence' ||
                      !isEditable
                    }
                    className="my-auto w-36 mr-2"
                    onChange={(e: any) => {
                      productServiceList[index].qtd_installments = e.value;
                      setProductServerList([...productServiceList]);
                      updateProduct(
                        {
                          form_of_payment: formOfPayment,
                          method: item.method,
                          product_value: Number(item.product_value),
                          qtd: item.qtd,
                          qtd_installments: e.value,
                          total: Number(item.total),
                          value: Number(item.value),
                        },
                        item.id,
                        index
                      );
                    }}
                  />
                  <IconDictionary
                    name="Excluir"
                    size={20}
                    onClick={() => isEditable && deleteProduct(item.id)}
                    className={
                      isEditable
                        ? 'min-w-[20px] text-red cursor-pointer'
                        : 'min-w-[20px] text-gray-400'
                    }
                  />
                </div>
              ))}
            </div>
          </div>
          <hr className="w-full h-px bg-[#dadada]" />
        </div>
      ) : (
        <p className="text-center text-xs">Nenhum Produto/Serviço encontrado</p>
      )}
    </div>
  );
};

export default PaymentProductsTable;
