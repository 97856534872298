import React from 'react';
import {
  Input,
  InputDate,
  SelectComponent,
  InputCity,
  TextArea,
} from '../../../../../../components';
import IconDictionary from '../../../../../../components/Icons/icons';
import { companieFlightsOption } from '../../utils';
import SelectCompanie from '../select/companie';

const FieldsFlights = ({ title }: { title: string }) => (
  <div className="flex flex-col">
    <div className="flex gap-2 items-center mb-2">
      <strong className="text-sm text-nowrap font-semibold">{title}</strong>
      <hr className="w-full h-px bg-gray-400" />
    </div>
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-2">
      <InputCity
        options={[]}
        title="Origem (cidade)"
        titleIcon={
          <IconDictionary name="Avião Decolar" className="text-primary ml-1" />
        }
      />
      <InputCity
        options={[]}
        title="Destino (cidade)"
        titleIcon={
          <IconDictionary
            name="Avião Aterrizar"
            className="text-primary ml-1"
          />
        }
      />
      <InputDate title="Data" />
      <Input variant="outline-orange" title="Hora saída" type="time" />
      <InputDate title="Data" />
      <Input variant="outline-orange" title="Hora chegada" type="time" />
    </div>
  </div>
);

const DetailsFlight: React.FC = () => (
  <div className="w-full flex flex-col text-sm gap-3 mt-5">
    <SelectCompanie />
    <FieldsFlights title="Ida" />
    <FieldsFlights title="Volta" />

    <TextArea title="Observação" />

    <TextArea title="Espelho do voo" />
    <hr className="w-full h-px bg-primary my-2" />
  </div>
);

export default DetailsFlight;
