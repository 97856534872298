import { useState } from 'react';

import IconDictionary from '../../../components/Icons/icons';

import MainPagePagination from '../../main/components/mainPage/mainPageWithPagination';

import { useWppContext } from '../context';
import { InstanceCard } from '../components';
import { CompanieWhatsappInstancesProps } from '../types';
import { normalizeColors } from '../utils';
import { ApiWhatsInstances } from '../api';
import { notify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';

const Whatsapp = () => {
  const { instances, setInstances, isLoading, setModal, whatsappPlans } =
    useWppContext();

  const [currentPage, setCurrentPage] = useState(1);

  const onHandleAction = async (instance: any) => {
    if (instance.status !== 'Conectada') {
      setModal({ show: 'connect', data: { id: instance.id } });
      return;
    }

    const res = await ApiWhatsInstances.updateCompanieWhatsappInstancesStatus(
      {
        action: instance.status === 'Conectada' ? 'logout' : 'connect',
      },
      instance.id
    );
    if (typeof res === 'string') {
      notify({ message: dictionaryError(res), type: 'Error' });
      return;
    }

    notify({
      message: 'Status da instância atualizado com sucesso!',
      type: 'Success',
    });
    const update = instances.map((obj: any) =>
      obj.name === instance.name ? { ...obj, status: 'Desconectada' } : obj
    );
    setInstances(update);
  };

  const onHandleModal = () => {
    if (whatsappPlans.qty === instances.length) {
      return setModal({ show: 'limit' });
    }
    return setModal({ show: 'register', data: whatsappPlans });
  };

  const columns = [
    {
      key: 'namePlans',
      name: 'Proprietário',
      selector: (row: CompanieWhatsappInstancesProps) => (
        <p>
          {
            row
              .companie_user_companie_userTocompanie_whatsapp_instances_companie_user_id_owner
              .name
          }
        </p>
      ),
    },
    {
      key: 'statusPlans',
      name: 'Funil',
      selector: (row: CompanieWhatsappInstancesProps) => (
        <div className="flex items-center justify-center">
          <p>{row.funnel_step?.funnel.title}</p>
        </div>
      ),
    },
    {
      key: 'statusPlans',
      name: 'Etapa',
      selector: (row: CompanieWhatsappInstancesProps) => (
        <div className="flex items-center justify-center">
          <p>{row.funnel_step.title}</p>
        </div>
      ),
    },
    {
      key: 'instanceName',
      name: 'Instância',
      selector: (row: CompanieWhatsappInstancesProps) => (
        <p>{row.json.instance?.instance?.instanceName}</p>
      ),
    },
    {
      key: 'status',
      name: 'Status da Instância',
      maxWidth: '240px',
      selector: (row: CompanieWhatsappInstancesProps) => (
        <p className={row.status === 'Conectada' ? 'text-green' : ''}>
          {row.status}
        </p>
      ),
    },
    {
      key: 'actions',
      name: (
        <div className="flex justify-center w-[160px]">
          <p>Ações</p>
        </div>
      ),
      selector: (row: CompanieWhatsappInstancesProps) =>
        row.status !== 'Excluida' && (
          <div className="flex">
            <button
              type="button"
              onClick={() => setModal({ show: 'update', data: row })}
              className="w-16 text-white font-semibold flex items-center justify-center py-1 rounded-sm"
            >
              <IconDictionary
                title="Editar as configurações da instância"
                size={18}
                name="Editar"
                color="#252525"
                className="mr-0"
              />
            </button>
            &nbsp;
            <button
              type="button"
              disabled={row.status === 'Inativa'}
              onClick={() => onHandleAction(row)}
              className="w-16 text-white font-semibold flex items-center justify-center py-1 rounded-sm"
            >
              <IconDictionary
                title={
                  row.status === 'Conectada'
                    ? 'Desconectar a instância'
                    : 'Conectar a instância'
                }
                size={18}
                name={row.status === 'Conectada' ? 'StopCircle' : 'QrCode'}
                color={normalizeColors(row.status, 'update')}
                className="mr-0"
              />
            </button>
            &nbsp;
            {row.is_sending_message_automatic === false && (
              <button
                title="Excluir a instância"
                type="button"
                disabled={row.status === 'Inativa'}
                onClick={() =>
                  setModal({
                    show: 'cancel',
                    data: { id: row.id, instanceName: row.name },
                  })
                }
                className="w-16 text-white font-semibold flex items-center justify-center py-1 rounded-sm"
              >
                <IconDictionary
                  name="TrashSolid"
                  size={18}
                  color={normalizeColors(row.status, 'delete')}
                  className="mr-0"
                />
              </button>
            )}
          </div>
        ),
    },
  ];

  return (
    <MainPagePagination
      isLoading={isLoading}
      currentPageTable={currentPage}
      setCurrentPageTable={setCurrentPage}
      startDate={() => {}}
      setModalCreateUpdate={onHandleModal}
      onRowClick={(e) => {
        if (e.status === 'Inativa' || e.status === 'Excluida') return null;
        return setModal({ show: 'update', data: { ...e } });
      }}
      totalRegisters={instances.length}
      title="Instâncias WhatsApp"
      column={columns}
      filterTable={() => instances}
      setRegisterPage={() => {}}
      PlansCustomCard={
        <InstanceCard setModalPurchaseCancel={(e) => setModal({ show: e })} />
      }
    />
  );
};

export default Whatsapp;
