import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';
import { useAtom } from 'jotai';
import AppRoutes from './routes';
import 'moment/locale/pt-br';
import DisableReactDevTools from './hooks/DisableReactDevTools';
import './styles/Calendar.css';
import ApiIsLoadingScreen from './components/Spinner/spinnerScreen';
import { NotificationToast } from './components/Toast/toast';
import { ActivitieAlert } from './modules/activitie/components/ActivitieAlert';
import { useNotificationSchedule } from './modules/activitie/hooks';
import { reloadConnection, CompanieUpdate } from './hooks/globalAtom';
import OutConnection from './components/OutConnection/outConnection';
import AlertCreateGalax from './components/Alert/alertCreateGalax';
import AlertTrial from './components/Alert/alertTrial';
import AlertAccountNotActive from './components/Alert/alertAccountNotActive';

moment().locale('pt-br');

function App() {
  const { notifications, setNotifications } = useNotificationSchedule();
  const [lostConnection, setLostConnection] = useAtom(reloadConnection);
  CompanieUpdate();

  return (
    <BrowserRouter>
      <DisableReactDevTools />

      <ApiIsLoadingScreen>
        <AlertTrial />
        <AlertAccountNotActive />
        <AlertCreateGalax />
        <NotificationToast />
        {notifications.map((notification) => (
          <ActivitieAlert
            key={notification.activitie_id}
            setLostConnection={setLostConnection}
            personName={notification.person?.name}
            id={notification.id}
            title={notification.activitie_type.title}
            opportunityTitle={notification.opportunity?.title || ''}
            description={notification.description}
            time={notification.hour}
            setNotifications={setNotifications}
          />
        ))}
        <AppRoutes />
      </ApiIsLoadingScreen>
      {lostConnection === true && <OutConnection />}
    </BrowserRouter>
  );
}

export default App;
