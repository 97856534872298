import React from 'react';

import { Modal, Button } from '../../../../components';
import { ApiWhatsInstances, companieWhatsappPlansCancel } from '../../api';
import { ToastNotify } from '../../../../components/Toast/toast';
import { dictionaryError } from '../../../../helpers/utils.helper';

import { useWppContext } from '../../context';

export const CancelModal: React.FC = () => {
  const { notify } = ToastNotify();

  const {
    modal,
    setModal,
    whatsappPlans,
    setReloadUserPlans,
    setReload,
    setInstances,
    instances,
  } = useWppContext();

  const [loading, setLoading] = React.useState(false);

  const constructor = {
    title: modal.data?.id ? 'Remover Instância' : 'Cancelar Plano',
  };

  const onConfirm = async () => {
    setLoading(true);
    if (modal.data?.id) {
      const res = await ApiWhatsInstances.deleteCompanieWhatsappInstances(
        modal.data.id
      );
      if (res !== true && !res.id) {
        notify({ message: dictionaryError(res), type: 'Error' });
        setLoading(false);
        return;
      }
      notify({
        message: 'Instância removida com sucesso',
        type: 'Success',
      });
      const data = instances.filter(
        (inst) => inst.name !== modal.data.instanceName
      );
      setModal({ ...modal, show: 'undefined' });
      setInstances(data);
      setLoading(false);
      return;
    }

    const res = await companieWhatsappPlansCancel(whatsappPlans.id);
    if (!res || typeof res === 'string') {
      notify({ message: dictionaryError(res), type: 'Error' });
      setLoading(false);
      return;
    }

    setReloadUserPlans(`${Math.random()}_reload`);
    setReload(`${Math.random()}_reload`);
    setModal({ show: 'undefined' });
    notify({
      message: 'Plano cancelado com sucesso',
      type: 'Success',
    });

    setLoading(false);
  };

  return (
    <Modal
      title={constructor.title}
      isOpen={modal.show === 'cancel'}
      setIsOpen={() => setModal({ show: 'undefined' })}
      size="medium"
      minHeight="min-h-[150px]"
    >
      <div className="flex items-center justify-center">
        <div className="flex-col mb-5">
          {modal.data?.id ? (
            <p className="text-sm mb-2">
              Deseja realmente remover a instância {modal.data.instanceName} ?
            </p>
          ) : (
            <>
              <p className="text-sm mb-2">
                Hoje sua empresa possui o plano{' '}
                {whatsappPlans?.whatsapp_plans?.name}.
              </p>
              <p className="text-sm">
                Deseja realmente cancelar o plano de whatsapp?
              </p>
            </>
          )}
        </div>
      </div>
      <div className="flex items-center gap-3">
        <Button
          type="submit"
          disabled={loading}
          actionType="button-loading"
          className="bg-primary my-1"
          onClick={() => setModal({ show: 'undefined' })}
        >
          <strong className="text-sm font-medium">Não</strong>
        </Button>
        <Button
          isLoading={loading}
          type="submit"
          disabled={loading}
          variant="outline-primary"
          actionType="button-loading"
          onClick={onConfirm}
        >
          <strong className="text-sm font-medium">Sim</strong>
        </Button>
      </div>
    </Modal>
  );
};
