import { Person } from '@styled-icons/fluentui-system-regular/Person';
import { useAtom } from 'jotai';
import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import IconDictionary from '../../components/Icons/icons';
import ModalPopover from '../../components/Modal/Popover';
import { getItem } from '../../helpers/storage.helper';
import { getTokenAccountInformation } from '../../helpers/token.helper';
import { instancesInformation } from '../../hooks/globalAtom';
import { useDigitalSignaturedCreditContext } from '../DigitalSignatureCredit/dashboard/context';
import ExplanatoryVideos from './components/explanatoryVideos';
import LoginOtherCompanie from './components/loginOtherCompanie';
import { HeaderMobile, Menu } from './components/menu';
import Notification from './components/notification';
import { useFetchCurrentAccess, useTheme } from './hooks';
import AlertPopUpWithTimer from '../../components/Alert/PopUp';
import { Button, Modal } from '../../components';
import maskHelper from '../../helpers/mask.helper';
import { CampaignUtils } from '../whatsappCampaign/utils';
import { ModalConnectInstance } from '../../components/Modal/ModalConnectInstance';
import AlertCard from '../../components/Card/alertCard';

const DIGITAL_SIGNATURE_CREDIT_ROUTE = 'digital-signature-credit';

const INSTANCE_ROUTE = 'instances';

const Main: React.FC<{ children: ReactNode }> = ({ children }) => {
  const menus = (getItem('routes_user') ?? []) as string[];
  const signatureCreditContext = useDigitalSignaturedCreditContext();

  const [instancesInfo] = useAtom(instancesInformation);

  const hasAccessToDigitalSignatureCredit = menus.includes(
    DIGITAL_SIGNATURE_CREDIT_ROUTE
  );

  const hasAccessToInstances = menus.includes(INSTANCE_ROUTE);

  const [openMenu, setOpenMenu] = useState(false);
  const [openMenuMobile, setOpenMenuMobile] = useState(true);
  const [openInstancesModal, setopenInstancesModal] = useState(false);
  const [InstanceConnectedCloseAll, setInstanceConnectedCloseAll] =
    useState(false);
  const [openInstancesModalConnect, setopenInstancesModalConnect] = useState<{
    show: boolean;
    data: any;
  }>({ show: false, data: {} });
  const [showSessionChange, setShowSessionChange] = useState(false);

  const pathName = window.location.pathname;
  const userInformation = getTokenAccountInformation();
  const { AccessListUser, isLoading } = useFetchCurrentAccess();
  const { theme } = useTheme(userInformation.companie?.id);

  const hasInstancesConnected = Boolean(
    instancesInfo?.companie_whatsapp_instances.find(
      (item: any) => item.status === 'Conectada'
    )
  );

  return (
    <div className="relative min-h-screen flex bg-gray-200 max-w-full">
      {instancesInfo?.status === 'Ativo' &&
        !hasInstancesConnected &&
        userInformation.profile_id === 2 && (
          <>
            <Modal
              isOpen={openInstancesModal}
              setIsOpen={setopenInstancesModal}
              title="Instâncias"
              size="4xlarge"
              minHeight="min-h-[200px]"
            >
              {instancesInfo?.companie_whatsapp_instances.length > 0 ? (
                <div className="w-full flex gap-3 flex-wrap justify-center my-5">
                  {instancesInfo?.companie_whatsapp_instances.map(
                    (item: any) => (
                      <div
                        title="Instância"
                        className="w-72 hover:border-primary cursor-pointer border-gray-400 border border-solid  relative text-sm rounded bg-gray-200 flex flex-col py-4 px-1 gap-3 items-center"
                      >
                        <div className="absolute left-5 p-2 rounded bg-orange-500 flex item-center justify-center">
                          <IconDictionary
                            name="Whatsapp"
                            color="#fff"
                            size={18}
                          />
                        </div>
                        <strong>Número WhatsApp</strong>
                        {maskHelper.phone(
                          item.whatsapp_number.replace('55', '')
                        )}
                        {CampaignUtils.getStatusInstance(item.status)}
                        <Button
                          className="hover:scale-105 transition-all duration-300 ease-in-out border hover:border-solid hover:border-gray-400"
                          actionType="button-reconnect"
                          variant="primary-strong"
                          onClick={() =>
                            setopenInstancesModalConnect({
                              data: item,
                              show: true,
                            })
                          }
                        >
                          Selecionar
                        </Button>
                      </div>
                    )
                  )}
                </div>
              ) : (
                <div className="flex flex-col text-xs">
                  {menus.includes('instances') ? (
                    <>
                      <p className="text-center mt-5">
                        Nenhuma instância encontrada
                      </p>{' '}
                      <Button
                        onClick={() => {
                          window.location.href = '/instances';
                        }}
                        className="w-full lg:w-64 mt-10 mx-auto"
                        variant="primary-strong"
                      >
                        Cadastrar Instância
                      </Button>
                    </>
                  ) : (
                    <>
                      <AlertCard
                        message="Você não tem permissão para cadastrar uma instância. Por
                      favor, entre em contato com o gestor ou responsável para
                      realizar o cadastro de uma nova instância."
                      />
                      <p className="text-center my-5">
                        Nenhuma instância encontrada
                      </p>
                    </>
                  )}
                </div>
              )}
            </Modal>
            <ModalConnectInstance
              closeOnConnect={() => {
                setInstanceConnectedCloseAll(true);
                setopenInstancesModal(false);
              }}
              instance={openInstancesModalConnect.data}
              show={openInstancesModalConnect.show}
              close={() => {
                setopenInstancesModalConnect({ data: {}, show: false });
              }}
            />
            <AlertPopUpWithTimer
              isDisabled={openInstancesModal || InstanceConnectedCloseAll}
              title="Instâncias Desconectadas"
              description="Você não possui instâncias conectadas. Isso pode comprometer o funcionamento das funcionalidades do WhatsApp."
              actionButton={() => {
                setopenInstancesModal(true);
              }}
              timeDisplay={600000}
              allowLeave
              titleButton2="Ok, entendi"
              titleButton="Quero Conectar"
            />
          </>
        )}
      {showSessionChange && (
        <button
          type="button"
          onClick={() => setShowSessionChange(false)}
          className="absolute z-50 top-0 left-0 w-full h-full cursor-default"
        >
          {' '}
        </button>
      )}

      <div className="max-w-[260px]">
        <Menu
          isMenuOpen={openMenu}
          openMenu={setOpenMenu}
          page={pathName.replace(/[^A-Za-z-]/g, '')}
          theme={theme}
        />
      </div>
      <div className="absolute right-0 w-full lg:w-[calc(100%_-_90px)]">
        <div className="hidden lg:flex items-center gap-3 absolute right-4 top-5 mt-0.5">
          {userInformation.companie?.id && (
            <>
              {hasAccessToInstances && (
                <Link
                  to={`/${INSTANCE_ROUTE}`}
                  className="cursor-pointer relative"
                >
                  <div className="bg-primary rounded-full flex items-center justify-center w-4 h-4 text-xs text-white -right-2 absolute -top-1.5 z-10">
                    {instancesInfo?.qty}
                  </div>
                  <IconDictionary
                    size={24}
                    name="Whatsapp"
                    color="text-primary"
                    className="hover:scale-110"
                  />
                </Link>
              )}
              {hasAccessToDigitalSignatureCredit && (
                <Link
                  to={`/${DIGITAL_SIGNATURE_CREDIT_ROUTE}`}
                  title="Ir para assinatura digital de contratos"
                  className="cursor-pointer relative mx-3"
                >
                  <div className="bg-primary rounded-full flex items-center justify-center w-4 h-4 text-xs text-white -right-2 absolute -top-1.5">
                    {signatureCreditContext.signatureCreditBalance}
                  </div>
                  <IconDictionary
                    size={28}
                    name="Assinar contrato"
                    className="hover:scale-110"
                  />
                </Link>
              )}
              <Notification />
            </>
          )}
          <div className="rounded-full w-12 h-12 bg-gray flex justify-center items-center">
            <Person size={24} />
          </div>
          <div className="flex flex-col">
            <div>
              <p className="font-medium text-medium">{userInformation.name}</p>
              {userInformation.companie?.name && (
                <ModalPopover
                  TriggerButton={
                    <p className="text-gray-600 text-xs">
                      {userInformation.companie?.name} - ID:{' '}
                      {userInformation.companie_id}
                    </p>
                  }
                  Content={() => (
                    <LoginOtherCompanie
                      AccessListUser={AccessListUser}
                      isLoading={isLoading}
                    />
                  )}
                />
              )}
            </div>
          </div>
        </div>
        <div className="px-4 pt-16 lg:pt-8 bg-gray-200 min-h-screen">
          <HeaderMobile
            active={openMenuMobile}
            openMenu={setOpenMenuMobile}
            theme={theme}
          />
          {children}
        </div>
      </div>
      <ExplanatoryVideos />
    </div>
  );
};

export default Main;
