import React, { useEffect, useState } from 'react';
import { Button, Modal } from '../../../../components';
import IconDictionary from '../../../../components/Icons/icons';
import { whatsappCampaignProps } from '../../types';
import TableWithPaginationBack from '../../../../components/Table/tableWithPaginationBack';
import { useFetchWhatsappTriggeredCampaigns } from '../../hooks';
import maskHelper from '../../../../helpers/mask.helper';
import { ApiWhatsappCampaign } from '../../api';
import { dictionaryError } from '../../../../helpers/utils.helper';
import { CampaignUtils } from '../../utils';

interface props {
  show: boolean;
  // eslint-disable-next-line no-unused-vars
  reloadTable: (e: string) => void;
  // eslint-disable-next-line no-unused-vars
  setShow: (e: boolean) => void;
  notify: any;
}
const ModalCampaignOutgoing: React.FC<props> = ({
  setShow,
  reloadTable,
  show,
  notify,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const take = 20;
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [idCampaign, setIdCampaign] = useState(-1);
  const [key, setKey] = useState('');
  const [statusChange, setStatusChange] = useState('');

  const { WhatsappCampaignList, isLoadingWhatsappList, setIsLoading } =
    useFetchWhatsappTriggeredCampaigns(key, skip, take);

  const onUpdateStatus = async (id: number, status: string) => {
    setIsLoading(true);
    const res = await ApiWhatsappCampaign.updateWhatsappCampaignStatus(
      {
        status,
      },
      id
    );
    if (typeof res === 'string') {
      notify({
        message: dictionaryError(res),
        type: 'Error',
      });
    } else {
      notify({
        message: 'Status da campanha alterado com sucesso!',
        type: 'Success',
      });
      setKey(String(Math.random()));
      setShowModalDelete(false);
      reloadTable(String(Math.random()));
    }
  };

  const columns = [
    {
      name: 'Mensagem',
      key: 'text',
      selector: (row: whatsappCampaignProps) => row.title,
    },
    {
      name: 'Data de envio',
      key: 'send_date',
      selector: (row: whatsappCampaignProps) =>
        maskHelper.formatDateDMY(row.send_date ?? ''),
    },
    {
      name: 'Quantidade de mensagens',
      key: 'send_success',
      selector: (row: whatsappCampaignProps) =>
        row.send_success + row.send_error,
    },
    {
      name: 'Mensagens Enviadas',
      key: 'send_success',
      selector: (row: whatsappCampaignProps) => row.send_success,
    },
    {
      name: 'Mensagens com Erro',
      key: 'send_error',
      selector: (row: whatsappCampaignProps) => row.send_error,
    },
    {
      name: 'Status',
      key: 'status',
      selector: (row: whatsappCampaignProps) => row.status,
    },
    {
      name: 'Instância',
      key: 'json_connection_update',
      selector: (row: whatsappCampaignProps) =>
        row.companie_whatsapp_instances.name,
    },
    {
      name: 'Status Instância',
      key: 'companie_whatsapp_instances.whatsapp_number',
      selector: (row: whatsappCampaignProps) =>
        CampaignUtils.getStatusInstance(row.companie_whatsapp_instances.status),
    },
    {
      key: 'actions',
      name: 'Ações',
      width: '70px',
      selector: (row: whatsappCampaignProps) => (
        <div className="flex gap-1">
          <button
            type="button"
            onClick={() => {
              setStatusChange('paused');
              setIdCampaign(row.id);
              setShowModalDelete(true);
            }}
            className="w-16 text-yellow  font-semibold flex items-center justify-center py-1 rounded-sm hover:scale-105"
          >
            <IconDictionary size={18} name="Pausar" className="mr-0" />
          </button>
          <button
            type="button"
            onClick={() => {
              setStatusChange('canceled');
              setIdCampaign(row.id);
              setShowModalDelete(true);
            }}
            className="w-16 text-red font-semibold flex items-center justify-center py-1 rounded-sm hover:scale-105"
          >
            <IconDictionary size={18} name="Parar" className="mr-0" />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (show) setKey(String(Math.random()));
  }, [show]);

  return (
    <Modal
      setIsOpen={setShow}
      isOpen={show}
      title={`Campanhas em disparo - (${WhatsappCampaignList.total})`}
      size="7xlarge"
      minHeight="min-h-[500px]"
    >
      <TableWithPaginationBack
        fixedHeader
        fixedHeaderScrollHeight="350px"
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        isLoading={isLoadingWhatsappList}
        columns={columns}
        data={WhatsappCampaignList.data}
        totalRegisters={WhatsappCampaignList.total}
        setStartDate={setSkip}
        totalPerPage={20}
        selectedRowId={-1}
      />
      <Modal
        isOpen={showModalDelete}
        setIsOpen={setShowModalDelete}
        title={`Tem certeza de que deseja ${
          statusChange === 'canceled' ? 'cancelar' : 'pausar'
        } a campanha?`}
        size="medium"
        minHeight="min-h-[100px]"
      >
        <p className="text-sm">
          <IconDictionary
            name="AlertCircle"
            className="text-yellow mb-1 mr-1"
          />
          Esta ação é irreversível e resultará na interrupção imediata do envio
          de mensagens para todos os contatos associados a esta campanha.
        </p>
        <div className="flex gap-2 w-full mt-5">
          <Button
            disabled={isLoadingWhatsappList}
            variant="outline-primary"
            actionType="button-loading"
            className="w-full bg-white my-1"
            onClick={() => setShowModalDelete(false)}
          >
            <strong className="text-sm font-medium">Voltar</strong>
          </Button>
          <Button
            disabled={isLoadingWhatsappList}
            isLoading={isLoadingWhatsappList}
            actionType="button-loading"
            className="w-full bg-primary my-1"
            onClick={() => onUpdateStatus(idCampaign, statusChange)}
          >
            <strong className="text-sm font-medium">Confirmar</strong>
          </Button>
        </div>
      </Modal>
    </Modal>
  );
};

export default ModalCampaignOutgoing;
