import { useEffect, useState } from 'react';
import { ApiInvoice } from '../api';
import { InvoiceProps } from '../types';

export function useFetchInvoice(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<InvoiceProps[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const companies = await ApiInvoice.get();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { InvoiceList: data, isLoadingInvoice: isLoading };
}
