import { convertKeysErros } from '../helpers/galax';
import { getToken } from '../helpers/token.helper';

interface props {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: any;
  url: string;
  file?: boolean;
  responseFile?: boolean;
}

export async function baseApi({ body, method, url }: props) {
  const response = await fetch(`${process.env.REACT_APP_API}${url}`, {
    method,
    body: JSON.stringify(body),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'ngrok-skip-browser-warning': '151920240219',
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }

      if (res.status === 409 || res.status === 400) {
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          return res.json().then((e: any) => {
            if (e.message) {
              return e.message;
            }
            return JSON.stringify(e);
          });
        }
        return res.text().then((text: any) => {
          throw new Error(text);
        });
      }

      return res.text().then((text) => {
        throw new Error(text);
      });
    })
    .catch((err) => {
      if (String(err) === 'TypeError: Failed to fetch') {
        if (window.location.pathname === '/session/login') {
          return 'Error: Internet ou API fora do ar';
        }
        return 'Falha na conexão';
        // logout();
        // window.location.href = '/';
      }
      return String(err);
    });

  return response;
}

export async function baseApiGalax({ body, method, url }: props) {
  const response = await fetch(`${process.env.REACT_APP_GALAXPAY_API}${url}`, {
    method,
    body: JSON.stringify(body),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'ngrok-skip-browser-warning': '151920240219',
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }

      if (res.status === 409 || res.status === 400) {
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          return res.json().then((e: any) => {
            if (e.message === 'Erros de validação.' && e.details) {
              let men = 'Galax: ';
              const keys = Object.keys(e.details);
              Object.values(e.details).forEach((item: any, index) => {
                men = men
                  .concat(`${convertKeysErros(String(keys[index]))} ${item}`)
                  .concat('\n');
              });
              return men;
            }
            if (e.message) {
              return e.message;
            }
            return JSON.stringify(e);
          });
        }
        return res.text().then((text: any) => {
          throw new Error(text);
        });
      }
      return res.text().then((text) => {
        throw new Error(text);
      });
    })
    .catch((err) => {
      if (err instanceof TypeError && err.message === 'Failed to fetch') {
        // logout();
        // window.location.href = '/';
        return 'Falha na conexão';
      }
      if (err.message === 'Request timeout') {
        // logout();
        // window.location.href = '/';
        return 'Tempo limite de requisição atingido';
      }
      return String(err);
    });

  return response;
}
const TIMEOUT_MS = 400000;
export async function baseApiPrivate({
  body,
  method,
  url,
  file = false,
  responseFile = false,
}: props) {
  if (!navigator.onLine) {
    return 'Sem conexão';
  }
  const token = getToken();
  const response = await Promise.race([
    fetch(`${process.env.REACT_APP_API}${url}`, {
      method,
      body: file ? body : JSON.stringify(body),
      headers: file
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {
            'Content-Type': 'application/json',
            'ngrok-skip-browser-warning': '151920240219',
            Authorization: `Bearer ${token}`,
          },
    }),
    new Promise((_, reject) => {
      setTimeout(() => reject(new Error('Request timeout')), TIMEOUT_MS);
    }),
  ])
    .then((res: any) => {
      if (res.status === 503) {
        // logout();
        // window.location.href = '/';
        return 'Serviço temporariamente indisponível';
      }
      if (res.ok) {
        if (responseFile) {
          return res.blob();
        }

        return res.json();
      }
      if (res.status === 409) {
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          return res.json().then((e: any) => {
            if (e.message) {
              if (typeof e.message === 'string') {
                return e.message;
              }
              const campos = Object.values(e.details).map((item: any) => item);
              return String(
                `Dados inválidos!\n${campos.map((item) => `${item}\n`)}`
              );
            }
            return JSON.stringify(e);
          });
        }
        return res.text().then((text: any) => {
          throw new Error(text);
        });
      }

      if (res.status === 400) {
        return res.json().then((e: any) => {
          if (e.validation) {
            return JSON.stringify(`validation ${e.validation.body.message}`);
          }
          return res.text().then((text: any) => {
            throw new Error(text);
          });
        });
      }

      return res.text().then((text: any) => {
        throw new Error(text);
      });
    })
    .catch((err) => {
      if (err instanceof TypeError && err.message === 'Failed to fetch') {
        // logout();
        // window.location.href = '/';
        return 'Falha na conexão';
      }
      if (err.message === 'Request timeout') {
        // logout();
        // window.location.href = '/';
        return 'Tempo limite de requisição atingido';
      }
      return String(err);
    });

  return response;
}

export async function baseApiIBGE({ body, method, url }: props) {
  const response = await fetch(
    `https://servicodados.ibge.gov.br/api/v1/${url}`,
    {
      method,
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'ngrok-skip-browser-warning': '151920240219',
      },
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }

      if (res.status === 409 || res.status === 400) {
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          return res.json().then((e: any) => {
            if (e.message) {
              return e.message;
            }
            return JSON.stringify(e);
          });
        }
        return res.text().then((text: any) => {
          throw new Error(text);
        });
      }
      return res.text().then((text) => {
        throw new Error(text);
      });
    })
    .catch((err) => {
      if (err instanceof TypeError && err.message === 'Failed to fetch') {
        return 'Falha na conexão';
      }
      if (err.message === 'Request timeout') {
        return 'Tempo limite de requisição atingido';
      }
      return String(err);
    });

  return response;
}
