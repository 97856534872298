import React from 'react';
import IconDictionary from '../../../../../../components/Icons/icons';
import { Input, InputDate } from '../../../../../../components';
import TotalTable from '../Table/totalTable';
import { changeOpacityRootColor } from '../../../../../../helpers/colors.helper';
import {
  formatTimeStampTodateHour,
  formatTimeStampToDateHourDay,
} from '../../../../../../helpers/utils.helper';
import SelectCompanie from '../select/companie';

interface props {
  orderNumber: string;
  hotels: { name: string; obs: string }[];
  formPayment: string;
  destiny: string;
  departureDate: Date;
  arrivalDate: Date;
  companieFlight: string;
  services: string;
  serviceObs: string;
  ida: {
    date: string;
    flights: {
      departureCity: string;
      departureDateTime: string;
      arrivalCity: string;
      arrivalDateTime: string;
    }[];
  };
  volta: {
    date: string;
    flights: {
      departureCity: string;
      departureDateTime: string;
      arrivalCity: string;
      arrivalDateTime: string;
    }[];
  };
}

const Summary: React.FC<props> = ({
  orderNumber,
  hotels,
  ida,
  volta,
  arrivalDate,
  companieFlight,
  departureDate,
  destiny,
  formPayment,
  serviceObs,
  services,
}) => (
  <div className="flex flex-col gap-2 text-sm">
    <div className="flex gap-2 items-center mt-3">
      <p className="text-primary font-medium text-nowrap">
        Orçamento N. {orderNumber}
      </p>
      <hr className="w-full h-px bg-gray-400" />
    </div>
    <p className="font-semibold">Resumo da viagem</p>
    <hr className="w-full h-px bg-primary" />
    <div className="flex gap-1 text-xs font-semibold items-center">
      <IconDictionary name="Cama" className="text-primary" />
      <p className="mr-5">Hospedagem</p>

      <IconDictionary name="Ticket" className="text-primary" />
      <p className="mr-5">Serviços</p>

      <IconDictionary name="Avião" className="text-primary" />
      <p>Aéreo</p>
    </div>
    <div className="flex mt-4 font-semibold items-center">
      Pacote para {destiny}{' '}
      <p className="text-xs font-normal ml-1">(Destino)</p>
    </div>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 w-full gap-2 mt-2">
      <InputDate title="Data de ida" defaultValue={departureDate} />
      <InputDate title="Data de volta" defaultValue={arrivalDate} />
      <SelectCompanie />
    </div>
    {hotels.map((item, index) => (
      <>
        <div className="grid grid-cols-1 md:grid-cols-4 w-full gap-2">
          <Input
            variant="outline-orange"
            title={`Hotel ${(index + 1).toString().padStart(2, '0')}`}
            value={item.name}
          />
          <Input
            variant="outline-orange"
            classNameDiv="md:col-span-3 w-full"
            title="Observação"
            value={item.obs}
          />
        </div>
        <TotalTable type="total" fix={0} tax={0} value={0} variable={0} />
        <br />
      </>
    ))}
    <div className="flex mt-2 font-semibold items-center">
      Serviços <p className="text-xs font-normal ml-1">(Inclusões)</p>
    </div>
    <Input
      variant="outline-orange"
      className="col-span-4"
      title="Serviço"
      value={services}
    />
    <Input
      value={serviceObs}
      variant="outline-orange"
      className="col-span-4"
      title="Observação"
    />
    <p className="mt-4 font-semibold">Aéreo</p>
    <p className="text-xs font-normal ml-1 text-gray-600">
      Detalhamento de voo
    </p>
    <div
      className="w-full px-3 text-xs font-semibold py-2 flex items-center justify-between rounded"
      style={{
        backgroundColor: changeOpacityRootColor(-200),
      }}
    >
      <div className="flex gap-3">
        <IconDictionary name="Avião" />
        <p>Ida</p>
      </div>
      <p>{formatTimeStampToDateHourDay(ida.date)}</p>
    </div>
    {ida.flights.map((elem, index) => (
      <div
        className={`px-1 gap-2 md:px-3 pb-2 grid grid-cols-4 md:grid-cols-5 items-center ${
          index + 1 !== ida.flights.length
            ? 'border-b border-solid border-gray-400'
            : ''
        } text-xs`}
      >
        <div className="flex flex-col gap-1.5">
          <p>{elem.departureCity}</p>
          <p className="font-semibold">
            {formatTimeStampTodateHour(elem.departureDateTime)}
          </p>
        </div>
        <IconDictionary
          name="Seta voltar"
          className="rotate-180 text-primary mx-auto"
          size={24}
        />
        <div className="flex flex-col gap-1.5">
          <p>{elem.departureCity}</p>
          <p className="font-semibold">
            {formatTimeStampTodateHour(elem.departureDateTime)}
          </p>
        </div>
        <div className="flex flex-col gap-1.5">
          <p>{companieFlight}</p>
          <p>Companhia aérea</p>
        </div>
      </div>
    ))}
    <div
      className="w-full px-3 text-xs font-semibold py-2 flex items-center justify-between rounded"
      style={{
        backgroundColor: changeOpacityRootColor(-200),
      }}
    >
      <div className="flex gap-3">
        <IconDictionary name="Avião" />
        <strong>Volta</strong>
      </div>
      <p>{formatTimeStampToDateHourDay(ida.date)}</p>
    </div>
    {volta.flights.map((elem) => (
      <div className="px-1 gap-2 md:px-3 py-2 grid grid-cols-4 md:grid-cols-5 items-center border-b border-solid border-gray-400 text-xs">
        <div className="flex flex-col gap-1.5">
          <p>{elem.departureCity}</p>
          <p className="font-semibold">
            {formatTimeStampTodateHour(elem.departureDateTime)}
          </p>
        </div>
        <IconDictionary
          name="Seta voltar"
          className="rotate-180 text-primary mx-auto"
          size={24}
        />
        <div className="flex flex-col gap-1.5">
          <p>{elem.departureCity}</p>
          <p className="font-semibold">
            {formatTimeStampTodateHour(elem.departureDateTime)}
          </p>
        </div>
        <div className="flex flex-col gap-1.5">
          <p>{companieFlight}</p>
          <p>Companhia aérea</p>
        </div>
      </div>
    ))}

    <div className="flex text-sm gap-1.5 my-2">
      <p className="text-gray-600 font-medium">
        Forma de pagamento selecionada:
      </p>
      <p className="font-semibold">{formPayment}</p>
    </div>
    <hr className="w-full h-px bg-primary" />
  </div>
);

export default Summary;
